import { SubscriptionType } from '../typing/enums'
import { ISubscribeHistoryItem } from '../typing/interface'

export const premium3Month = [
    'conflictsWorldwide',
    'socialIssues',
    'healthLabel',
    'climateLabel',
    'monthlyDataUpdates',
    'emergencyPush',
    'tailoredAI',
    'sightsAI',
    'hotelTicketsAI',
    'personalizedVisa',
]

export const basic3Month = [
    'conflictsWorldwide',
    'socialIssues',
    'healthLabel',
    'climateLabel',
    'monthlyDataUpdates',
    'visaEntry',
    'emergencyPush',
]

export const premium = [
    'conflictsWorldwide',
    'socialIssues',
    'healthLabel',
    'climateLabel',
    'monthlyDataUpdates',
    'emergencyPush',
    'tailoredAI',
    'sightsAI',
    'hotelTicketsAI',
    'personalizedVisa',
]

export const base = [
    'conflictsWorldwide',
    'socialIssues',
    'healthLabel',
    'climateLabel',
    'monthlyDataUpdates',
    'visaEntry',
    'emergencyPush',
]

export const subscribeLabel = {
    [SubscriptionType.Basic]: 'Basic',
    [SubscriptionType.Premium]: 'Premium',
    [SubscriptionType.Basic_3m]: 'Basic 3 month',
    [SubscriptionType.Premium_3m]: 'Premium 3 month',
}

export const historySubscribeMock: ISubscribeHistoryItem[] = [
    { date: '22.10.2023', price: 7.99, type: SubscriptionType.Basic },
    { date: '22.11.2023', price: 10.99, type: SubscriptionType.Premium },
    { date: '22.12.2023', price: 7.99, type: SubscriptionType.Basic },
    { date: '22.01.2024', price: 7.99, type: SubscriptionType.Basic },
    { date: '22.02.2024', price: 7.99, type: SubscriptionType.Basic },
    { date: '22.03.2024', price: 7.99, type: SubscriptionType.Basic },
    { date: '22.04.2024', price: 7.99, type: SubscriptionType.Basic },
    { date: '22.05.2024', price: 7.99, type: SubscriptionType.Basic },
    { date: '22.06.2024', price: 7.99, type: SubscriptionType.Basic },
    { date: '22.07.2024', price: 10.99, type: SubscriptionType.Premium },
    { date: '22.08.2024', price: 10.99, type: SubscriptionType.Premium },
    { date: '22.09.2024', price: 10.99, type: SubscriptionType.Premium },
]
