import { CSSProperties, useMemo } from 'react'
import styles from './style.module.css'
interface Props {
    onClick: () => void
    label: string
    disabled?: boolean
    isLoading?: boolean
    leftIcon?: JSX.Element | null
    style?: CSSProperties
    labelStyle?: CSSProperties
    className?: string
    id?: string
}
export const PrimaryButton = ({
    onClick,
    label,
    style,
    labelStyle,
    disabled,
    isLoading,
    leftIcon = null,
    className,
    id
}: Props) => {
    const getButtonClass = () => {
        switch (true) {
            case disabled:
                return `${styles.disabled} ${styles.button_container}`
            default:
                return styles.button_container
        }
    }
    const content = useMemo(() => {
        if (isLoading) {
            return <div className={styles.loader}></div>
        } else {
            return (
                <div className={styles.content}>
                    <>{leftIcon}</>
                    <p
                        className={
                            disabled ? styles.white_label : styles.button_label
                        }
                        style={labelStyle}>
                        {label}
                    </p>
                </div>
            )
        }
    }, [isLoading, label, labelStyle, disabled])
    return (
        <button
            disabled={disabled}
            onClick={onClick}
            style={style}
            id={id}
            className={`${getButtonClass()} ${className}`}>
            {content}
        </button>
    )
}
