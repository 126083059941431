import { Languages } from '@/core/typing'

export const termsTranslates: any = {
    [Languages.DE]: (
        <>
            <p className='p1'>
                <strong>Bedingungen und Konditionen von SafeTravy</strong>
            </p>
            <p className='p3'>
                Diese Allgemeinen Gesch&auml;ftsbedingungen (die "Bedingungen"
                oder diese "Vereinbarung") regeln die Nutzung der elektronischen
                Plattform, einschlie&szlig;lich jeglicher Website oder mobiler
                Anwendung (die "App", zusammen mit der Website die "Website")
                f&uuml;r den Zugriff auf die Plattform und jegliche &uuml;ber
                die Plattform bereitgestellten Dienste (zusammen die
                "Plattform"), die von SafeTravy Limited (das "Unternehmen",
                "wir", "uns" oder "unser") bereitgestellt werden.
                <span className='Apple-converted-space'>&nbsp; </span>Die
                Bedingungen bilden eine verbindliche Vereinbarung zwischen dem
                Unternehmen und Ihnen als individuellem Nutzer ("Sie", "Ihr"
                oder "Nutzer") f&uuml;r Ihre individuelle Nutzung der App und
                der Plattform. Indem Sie sich f&uuml;r die App registrieren und
                diese herunterladen und die Plattform nutzen, best&auml;tigen
                Sie, dass Sie diese Vereinbarung und unsere zugeh&ouml;rige
                Datenschutzrichtlinie akzeptieren.
                <span className='Apple-converted-space'>&nbsp; </span>Wenn Sie
                mit diesen Bedingungen nicht einverstanden sind, m&uuml;ssen Sie
                die App sofort deinstallieren und die Nutzung der App und der
                Plattform einstellen.
            </p>
            <p className='p3'>
                Haftungsausschluss f&uuml;r Wertpapiere: Kein Material und keine
                anderen Informationen, die auf der Website oder der Plattform
                zur Verf&uuml;gung gestellt werden, stellen eine Empfehlung,
                Bef&uuml;rwortung, ein Angebot, eine Einladung oder eine
                Aufforderung an Sie dar, eine Entscheidung zu treffen; alle
                Entscheidungen treffen Sie aus eigenem Willen und eigener
                Erfahrung. Sie nehmen ferner zur Kenntnis, dass keiner der
                Informationsanbieter, einschlie&szlig;lich der Drittanbieter
                (wie unten definiert), Sie pers&ouml;nlich &uuml;ber die Art,
                das Potenzial, den Wert oder die Eignung bestimmter
                Sicherheitsfragen in Bezug auf Ihre Reisen ber&auml;t. Bitte
                beachten Sie, dass sich die Situation in der Welt schnell
                &auml;ndert, so dass alle Informationen, Inhalte, Inhalte von
                Drittanbietern (wie unten definiert) oder andere Materialien,
                die auf oder &uuml;ber die Website oder Plattform bereitgestellt
                werden, m&ouml;glicherweise nicht vollst&auml;ndig oder aktuell
                sind oder durch aktuellere Informationen ersetzt werden
                k&ouml;nnen. Sie verlassen sich auf diese Informationen auf Ihr
                eigenes Risiko.
            </p>
            <p className='p3'>
                Unsere Website und Plattform sind nicht dazu gedacht, Rechts-,
                Versicherungs- oder Anlageberatung zu leisten, und nichts auf
                der Website oder Plattform sollte als Angebot an Sie zur
                Organisation Ihrer Reise ausgelegt werden. Alle diese
                Informationen sind lediglich personalisierte Empfehlungen.
            </p>
            <p className='p3'>
                <strong>1. Anwendbarkeit</strong>
            </p>
            <p className='p3'>
                1.1. Wir, SafeTravy Limited (im Folgenden "SafeTravy"), bieten
                Dienstleistungen im Bereich der IT-Unterhaltung an Diese
                allgemeinen Gesch&auml;ftsbedingungen gelten f&uuml;r das
                Vertragsverh&auml;ltnis zwischen SafeTravy und dem Kunden
                ("Kunde" oder "Sie") in Bezug auf alle von uns erbrachten
                Dienstleistungen.
            </p>
            <p className='p3'>
                <strong>2. Begriffsbestimmungen</strong>
            </p>
            <p className='p3'>
                <strong>Konto </strong>bezeichnet das von einem Nutzer
                eingerichtete Konto, der die App heruntergeladen oder auf die
                Website zugegriffen hat und sich bei dem Unternehmen zur Nutzung
                der Website und der Plattform registriert hat.
            </p>
            <p className='p3'>
                <strong>App </strong>bezeichnet die vom Unternehmen
                bereitgestellte mobile Anwendung f&uuml;r den Zugriff auf die
                Plattform.
            </p>
            <p className='p3'>
                <strong>Autorisierte Person </strong>bezeichnet jede Person, die
                berechtigt ist, im Namen eines Nutzers auf die Website
                (einschlie&szlig;lich der App) und die Plattform zuzugreifen und
                diese zu nutzen.
            </p>
            <p className='p3'>
                <strong>Digitale Plattformen </strong>beziehen sich auf
                Vertriebsplattformen von Drittanbietern, &uuml;ber die mobile
                Anwendungen oder andere Softwareprogramme abgerufen oder
                heruntergeladen werden k&ouml;nnen, einschlie&szlig;lich, aber
                nicht beschr&auml;nkt auf den Apple App Store und Google Play.
            </p>
            <p className='p3'>
                <strong>Regierungsbeh&ouml;rde </strong>bedeutet eine Nation
                oder Regierung oder eine Provinz oder ein Bundesland oder eine
                andere politische Untereinheit davon oder eine Einrichtung,
                Beh&ouml;rde oder ein Gremium, das Exekutiv-, Legislativ-,
                Gerichts-, Regulierungs- oder Verwaltungsfunktionen der
                Regierung aus&uuml;bt oder mit ihr in Verbindung steht,
                einschlie&szlig;lich einer Regierungsbeh&ouml;rde, einer
                Agentur, einer Abteilung, eines Gremiums, einer Kommission oder
                eines Instruments oder einer politischen Untereinheit davon,
                eines Gerichts, eines Tribunals oder eines Schiedsrichters und
                einer Selbstregulierungsorganisation.
            </p>
            <p className='p3'>
                <strong>Material </strong>ist jegliches Angebotsmaterial, Term
                Sheet, Forschungsbericht, Produkt- oder
                Dienstleistungsdokumentation oder jede andere &uuml;ber die
                Plattform bereitgestellte Information.
            </p>
            <p className='p3'>
                <strong>Personenbezogene Daten </strong>sind Informationen, die
                von einem Nutzer zur Verf&uuml;gung gestellt werden und aus
                denen die Identit&auml;t des Nutzers direkt oder indirekt
                abgeleitet werden kann.
            </p>
            <p className='p3'>
                <strong>Datenschutzrichtlinie </strong>bezeichnet die
                zus&auml;tzlichen Bedingungen, die die Sammlung, Verwendung und
                Offenlegung der pers&ouml;nlichen Daten jedes Nutzers regeln,
                wie hier dargelegt. Jeder Nutzer muss die Datenschutzrichtlinie
                lesen und ihr zustimmen, um die App oder die Website nutzen zu
                k&ouml;nnen.
            </p>
            <p className='p3'>
                <strong>Service-Benachrichtigungen </strong>sind
                Einweg-Benachrichtigungen des Unternehmens (die auch
                sicherheitsrelevante Benachrichtigungen enthalten k&ouml;nnen)
                per Textnachricht oder E-Mail und ggf. Push-Benachrichtigungen
                &uuml;ber die Website.
                <span className='Apple-converted-space'>&nbsp; </span>Diese
                Benachrichtigungen werden dem Nutzer in Bezug auf bestimmte
                Informationen oder Ereignisse im Zusammenhang mit einem Konto,
                auf das der Nutzer &uuml;ber die Plattform Zugriff hat,
                zugesandt.
            </p>
            <p className='p3'>
                <strong>Nutzer </strong>bezeichnet jede Person, die sich beim
                Unternehmen registriert hat, um die Website zu nutzen und auf
                die Plattform zuzugreifen, sowie jede autorisierte Person, die
                in deren Namen handelt.
            </p>
            <p className='p3'>
                <strong>Benutzer-Credentials </strong>sind die
                Benutzeridentifikation, das Passwort, die pers&ouml;nliche
                Identifikationsnummer, das Token und alle anderen Informationen
                oder Ger&auml;te, die einem Benutzer f&uuml;r den Zugriff auf
                die Plattform zur Verf&uuml;gung gestellt werden.
            </p>
            <p className='p3'>
                <strong>Das Unternehmen </strong>(in diesem Vertrag entweder als
                "das Unternehmen", "wir", "uns" oder "unser" bezeichnet) bezieht
                sich auf SAFETRAVY LIMITED, Suite 4, 2nd floor, the West Wing,
                Montarik House, 3 Bedlam Court, Gibraltar, GX11 1AA, GIBRALTAR,
                GIBRALTAR.
            </p>
            <p className='p3'>
                <strong>Ger&auml;t </strong>ist jedes Ger&auml;t, das auf den
                Dienst zugreifen kann, wie z. B. ein Computer, ein Mobiltelefon
                oder ein digitales Tablet.
            </p>
            <p className='p3'>
                <strong>Eine kostenlose Testversion </strong>bezieht sich auf
                einen begrenzten Zeitraum, der beim Kauf eines Abonnements
                kostenlos sein kann.
            </p>
            <p className='p3'>
                <strong>Der </strong>Begriff "<strong>Service" </strong>bezieht
                sich auf alle Informationen, Empfehlungen und
                Benachrichtigungen, die auf der Website bereitgestellt werden.
            </p>
            <p className='p3'>
                <strong>Abonnements </strong>beziehen sich auf die Dienste oder
                den Zugang zum Dienst, die Ihnen vom Unternehmen auf
                Abonnementbasis angeboten werden.
            </p>
            <p className='p3'>
                <strong>Allgemeine Gesch&auml;ftsbedingungen </strong>(auch als
                "Bedingungen" bezeichnet) bezeichnen diese Allgemeinen
                Gesch&auml;ftsbedingungen, die die gesamte Vereinbarung zwischen
                Ihnen und dem Unternehmen bez&uuml;glich der Nutzung des
                Dienstes bilden.
            </p>
            <p className='p3'>
                Dienste <strong>von Dritten </strong>sind alle Dienste oder
                Inhalte (einschlie&szlig;lich Daten, Informationen, Produkte
                oder Dienste), die von Dritten bereitgestellt werden und die
                durch den Dienst angezeigt, einbezogen oder verf&uuml;gbar
                gemacht werden k&ouml;nnen.
            </p>
            <p className='p3'>
                <strong>Die Website </strong>verweist auf SafeTravy,
                zug&auml;nglich &uuml;ber www.safetravy.com
            </p>
            <p className='p3'>
                <strong>&Auml;nderungen</strong>
            </p>
            <p className='p3'>Wir behalten uns das Recht vor, jederzeit:</p>
            <p className='p3'>
                (a) die Bedingungen dieser Vereinbarung oder unserer
                Datenschutzrichtlinie zu modifizieren, zu aktualisieren oder zu
                &auml;ndern;
            </p>
            <p className='p3'>
                (b) die Website und die Plattform zu modifizieren, zu
                aktualisieren oder zu &auml;ndern, einschlie&szlig;lich der
                Beseitigung oder Einstellung von Inhalten oder Funktionen der
                Website oder der Plattform (alle vorgenannten Punkte werden als
                "&Auml;nderungen" bezeichnet).
            </p>
            <p className='p3'>
                Wir k&ouml;nnen solche &Auml;nderungen jederzeit ohne vorherige
                Ank&uuml;ndigung vornehmen.
                <span className='Apple-converted-space'>&nbsp; </span>
                &Auml;nderungen an dieser Vereinbarung k&ouml;nnen auf unserer
                Website ver&ouml;ffentlicht oder Ihnen durch
                Push-Benachrichtigungen auf der Website oder per E-Mail an die
                E-Mail-Adresse in Ihrem Konto mitgeteilt werden. Aus diesem
                Grund sollten Sie unsere Website regelm&auml;&szlig;ig
                &uuml;berpr&uuml;fen, den Empfang solcher
                Push-Benachrichtigungen auf der Website zulassen und Ihre
                E-Mail-Adresse und andere Kontaktinformationen in Ihrem Konto
                auf dem neuesten Stand halten. Sie akzeptieren alle
                &Auml;nderungen, wenn Sie die Website und die Plattform nach dem
                Inkrafttreten dieser &Auml;nderungen weiterhin nutzen.
            </p>
            <p className='p3'>
                <strong>Begriffe der digitalen Plattform</strong>
            </p>
            <p className='p3'>
                Die App kann zum Herunterladen von einer oder mehreren digitalen
                Plattformen verf&uuml;gbar sein. Ihr Download, Ihre
                Installation, Ihr Zugriff auf oder Ihre Nutzung der App ist auch
                an die Bedingungen und Datenschutzrichtlinien der jeweiligen
                digitalen Plattform (die "Bedingungen der digitalen Plattform")
                gebunden. Im Falle eines Widerspruchs zwischen diesen
                Bedingungen und den Bedingungen der digitalen Plattform haben
                diese Bedingungen Vorrang.
            </p>
            <p className='p3'>
                Die App ist unabh&auml;ngig von einer Digitalen Plattform und
                ist nicht mit dieser verbunden, angegliedert, gesponsert,
                gebilligt oder in irgendeiner Weise mit ihr verkn&uuml;pft. Sie
                und wir erkennen an, dass diese Vereinbarung nur zwischen Ihnen
                und uns und nicht mit einer Digitalen Plattform geschlossen
                wird, und dass wir, nicht die Digitale Plattform, allein
                f&uuml;r die App und deren Inhalt in dem in dieser Vereinbarung
                angegebenen Umfang verantwortlich sind.
            </p>
            <p className='p3'>
                Sie und wir erkennen an und erkl&auml;ren uns damit
                einverstanden, dass die betreffende Digitale Plattform und die
                Tochtergesellschaften der Digitalen Plattform
                Drittbeg&uuml;nstigte dieser Bedingungen sind und dass die
                Digitale Plattform nach Ihrer Zustimmung zu diesen Bedingungen
                das Recht hat (und es wird davon ausgegangen, dass sie das Recht
                akzeptiert hat), diese Bedingungen gegen Sie als
                Drittbeg&uuml;nstigte durchzusetzen.
            </p>
            <p className='p3'>
                <strong>
                    Anforderungen an Netzwerkger&auml;te und Netzbetreiber
                </strong>
            </p>
            <p className='p3'>
                Sie erkennen an, dass Ihre Vereinbarung mit Ihrem Mobilfunk- und
                Internetanbieter (der "Netzanbieter") f&uuml;r Ihre Nutzung der
                Website gilt. Sie erkennen an, dass Ihnen von Ihrem
                Netzbetreiber bei der Nutzung bestimmter Funktionen der Website
                Datendienste oder andere Geb&uuml;hren von Dritten in Rechnung
                gestellt werden k&ouml;nnen, und Sie &uuml;bernehmen die
                alleinige Verantwortung f&uuml;r diese Geb&uuml;hren. Wenn Sie
                nicht der Rechnungszahler f&uuml;r das Mobil-/Internetger&auml;t
                sind, mit dem Sie auf die Website zugreifen, wird davon
                ausgegangen, dass Sie die Erlaubnis des Rechnungszahlers zur
                Nutzung der Website erhalten haben. Sie m&uuml;ssen auch
                sicherstellen, dass Ihre Nutzung der Website nicht gegen den
                Vertrag Ihres Mobil- oder Internetger&auml;ts oder einen Vertrag
                &uuml;ber einen drahtlosen Datendienst verst&ouml;&szlig;t.
            </p>
            <p className='p3'>
                <strong>Teilnahmeberechtigung und Registrierung</strong>
            </p>
            <p className='p3'>
                Sie m&uuml;ssen mindestens 18 Jahre alt sein, um auf die Website
                und die Plattform zuzugreifen und diese zu nutzen. Sie
                best&auml;tigen au&szlig;erdem, dass Sie voll und ganz in der
                Lage und f&auml;hig sind, die Bedingungen, Verpflichtungen,
                Best&auml;tigungen, Zusicherungen und Garantien, die in diesen
                Bedingungen festgelegt sind, einzugehen und diese Bedingungen
                einzuhalten. Sie m&uuml;ssen sich bei dem Unternehmen
                registrieren, um die Website und die Plattform nutzen zu
                k&ouml;nnen; Sie verpflichten sich, bei der Registrierung zur
                Nutzung der Website und der Plattform vollst&auml;ndige und
                genaue Angaben zu machen und diese Angaben auf dem neuesten
                Stand zu halten.
            </p>
            <p className='p3'>
                Es liegt in unserem alleinigen Ermessen, Ihre Registrierung auf
                der Plattform zu akzeptieren oder abzulehnen. Nur Nutzer, deren
                Registrierungen von uns genehmigt werden, sind unsere Kunden.
            </p>
            <p className='p3'>
                <strong>Danksagung</strong>
            </p>
            <p className='p3'>
                Dies sind die Allgemeinen Gesch&auml;ftsbedingungen, die die
                Nutzung dieses Dienstes regeln und die Vereinbarung zwischen
                Ihnen und dem Unternehmen. Diese Allgemeinen
                Gesch&auml;ftsbedingungen legen die Rechte und Pflichten aller
                Nutzer in Bezug auf die Nutzung des Dienstes fest.
            </p>
            <p className='p3'>
                Ihr Zugang zu und Ihre Nutzung des Dienstes setzt voraus, dass
                Sie diese Allgemeinen Gesch&auml;ftsbedingungen akzeptieren und
                einhalten. Diese Allgemeinen Gesch&auml;ftsbedingungen gelten
                f&uuml;r alle Besucher, Nutzer und andere, die auf den Dienst
                zugreifen oder ihn nutzen.
            </p>
            <p className='p3'>
                Indem Sie auf den Dienst zugreifen oder ihn nutzen,
                erkl&auml;ren Sie sich mit diesen Allgemeinen
                Gesch&auml;ftsbedingungen einverstanden. Wenn Sie mit einem Teil
                dieser Bedingungen nicht einverstanden sind, d&uuml;rfen Sie den
                Dienst nicht nutzen.
            </p>
            <p className='p3'>
                Sie versichern, dass Sie &uuml;ber 18 Jahre alt sind. Das
                Unternehmen erlaubt es Personen unter 18 Jahren nicht, den
                Service zu nutzen.
            </p>
            <p className='p3'>
                Ihr Zugang zu und Ihre Nutzung des Dienstes ist auch davon
                abh&auml;ngig, dass Sie die Datenschutzrichtlinie des
                Unternehmens akzeptieren und einhalten. Unsere
                Datenschutzrichtlinie beschreibt unsere Richtlinien und
                Verfahren f&uuml;r die Erfassung, Verwendung und Offenlegung
                Ihrer pers&ouml;nlichen Daten, wenn Sie die Anwendung oder die
                Website nutzen, und informiert Sie &uuml;ber Ihre
                Datenschutzrechte und dar&uuml;ber, wie das Gesetz Sie
                sch&uuml;tzt. Bitte lesen Sie unsere Datenschutzrichtlinie
                sorgf&auml;ltig durch, bevor Sie unseren Service nutzen.
            </p>
            <p className='p3'>
                <strong>Abonnements</strong>
            </p>
            <p className='p3'>
                <strong>Dauer des Abonnements</strong>
            </p>
            <p className='p3'>
                Der Dienst oder einige Teile des Dienstes sind nur mit einem
                bezahlten Abonnement verf&uuml;gbar. Die Abrechnung erfolgt im
                Voraus auf wiederkehrender und regelm&auml;&szlig;iger Basis (z.
                B. t&auml;glich, w&ouml;chentlich, monatlich oder
                j&auml;hrlich), je nach der Art des Abonnementplans, den Sie
                beim Kauf des Abonnements ausw&auml;hlen.
            </p>
            <p className='p3'>
                Am Ende eines jeden Zeitraums verl&auml;ngert sich Ihr
                Abonnement automatisch zu denselben Bedingungen, es sei denn,
                Sie k&uuml;ndigen es oder das Unternehmen k&uuml;ndigt es.
            </p>
            <p className='p3'>
                <strong>K&uuml;ndigungen von Abonnements</strong>
            </p>
            <p className='p3'>
                Sie k&ouml;nnen die Verl&auml;ngerung Ihres Abonnements entweder
                &uuml;ber die Seite mit den Kontoeinstellungen oder durch
                Kontaktaufnahme mit dem Unternehmen k&uuml;ndigen. Sie erhalten
                keine R&uuml;ckerstattung f&uuml;r die Geb&uuml;hren, die Sie
                bereits f&uuml;r Ihren aktuellen Abonnementzeitraum bezahlt
                haben, und Sie k&ouml;nnen bis zum Ende Ihres aktuellen
                Abonnementzeitraums auf den Dienst zugreifen.
            </p>
            <p className='p3'>
                <strong>Rechnungsstellung</strong>
            </p>
            <p className='p3'>
                Sie m&uuml;ssen dem Unternehmen korrekte und vollst&auml;ndige
                Rechnungsdaten zur Verf&uuml;gung stellen, einschlie&szlig;lich
                vollst&auml;ndiger Name, Adresse, Bundesland, und g&uuml;ltige
                Zahlungsinformationen.
            </p>
            <p className='p3'>
                Sollte die automatische Rechnungsstellung aus irgendeinem Grund
                ausbleiben, stellt das Unternehmen eine elektronische Rechnung
                aus, in der Sie darauf hingewiesen werden, dass Sie innerhalb
                eines bestimmten Termins die Zahlung des vollen Betrags f&uuml;r
                den auf der Rechnung angegebenen Abrechnungszeitraum manuell
                vornehmen m&uuml;ssen.
            </p>
            <p className='p3'>
                <strong>Geb&uuml;hren&auml;nderungen</strong>
            </p>
            <p className='p3'>
                Das Unternehmen kann die Abonnementgeb&uuml;hren nach eigenem
                Ermessen und zu jeder Zeit &auml;ndern. Jede &Auml;nderung der
                Abonnementgeb&uuml;hren wird am Ende des aktuellen
                Abonnementzeitraums wirksam.
            </p>
            <p className='p3'>
                Das Unternehmen wird Sie mit angemessener Frist &uuml;ber
                &Auml;nderungen der Abonnementgeb&uuml;hren informieren, um
                Ihnen die M&ouml;glichkeit zu geben, Ihr Abonnement zu
                k&uuml;ndigen, bevor die &Auml;nderung in Kraft tritt.
            </p>
            <p className='p3'>
                Ihre fortgesetzte Nutzung des Dienstes nach Inkrafttreten der
                &Auml;nderung der Abonnementgeb&uuml;hr gilt als Ihr
                Einverst&auml;ndnis, den ge&auml;nderten Betrag der
                Abonnementgeb&uuml;hr zu zahlen.
            </p>
            <p className='p3'>
                <strong>Erstattungen</strong>
            </p>
            <p className='p3'>
                Die gezahlten Abonnementgeb&uuml;hren werden nicht
                zur&uuml;ckerstattet, es sei denn, dies ist gesetzlich
                vorgeschrieben.
            </p>
            <p className='p3'>
                Bestimmte R&uuml;ckerstattungsantr&auml;ge f&uuml;r Abonnements
                k&ouml;nnen vom Unternehmen von Fall zu Fall gepr&uuml;ft und
                nach alleinigem Ermessen des Unternehmens gew&auml;hrt werden.
            </p>
            <p className='p3'>
                <strong>Kostenlose Testversion</strong>
            </p>
            <p className='p3'>
                Das Unternehmen kann nach eigenem Ermessen ein Abonnement mit
                einer kostenlosen Testversion f&uuml;r einen begrenzten Zeitraum
                anbieten.
            </p>
            <p className='p3'>
                Sie m&uuml;ssen m&ouml;glicherweise Ihre Rechnungsdaten
                eingeben, um sich f&uuml;r die kostenlose Testversion
                anzumelden.
            </p>
            <p className='p3'>
                Wenn Sie bei der Anmeldung f&uuml;r eine kostenlose Testversion
                Ihre Rechnungsdaten eingeben, werden Ihnen diese erst nach
                Ablauf der kostenlosen Testversion vom Unternehmen in Rechnung
                gestellt. Am letzten Tag des kostenlosen Testzeitraums werden
                Ihnen, sofern Sie Ihr Abonnement nicht k&uuml;ndigen,
                automatisch die entsprechenden Abonnementgeb&uuml;hren f&uuml;r
                die von Ihnen gew&auml;hlte Art des Abonnements in Rechnung
                gestellt.
            </p>
            <p className='p3'>
                Das Unternehmen beh&auml;lt sich das Recht vor, jederzeit und
                ohne Vorank&uuml;ndigung (i) die Bedingungen des kostenlosen
                Testangebots zu &auml;ndern oder (ii) das kostenlose Testangebot
                zu stornieren.
            </p>
            <p className='p3'>
                <strong>Benutzerkonten</strong>
            </p>
            <p className='p3'>
                Um die Dienste der Plattform nutzen zu k&ouml;nnen, m&uuml;ssen
                Sie ein Konto auf der Plattform erstellen (das "Konto").
                <span className='Apple-converted-space'>&nbsp; </span>Das Konto
                kann von jeder Person registriert werden, die &uuml;ber 18 Jahre
                alt ist.
            </p>
            <p className='p3'>
                Wenn Sie ein Konto bei uns erstellen, m&uuml;ssen Sie uns
                Informationen zur Verf&uuml;gung stellen, die jederzeit korrekt,
                vollst&auml;ndig und aktuell sind. Wenn Sie dies nicht tun,
                stellt dies einen Versto&szlig; gegen die Bedingungen dar, der
                zur sofortigen K&uuml;ndigung Ihres Kontos bei unserem Service
                f&uuml;hren kann.
            </p>
            <p className='p3'>
                Sie sind verantwortlich f&uuml;r den Schutz des Passworts, das
                Sie f&uuml;r den Zugriff auf den Dienst verwenden, sowie
                f&uuml;r alle Aktivit&auml;ten oder Handlungen unter Ihrem
                Passwort, unabh&auml;ngig davon, ob Ihr Passwort f&uuml;r
                unseren Dienst oder einen Dienst eines Drittanbieters verwendet
                wird.
            </p>
            <p className='p3'>
                Sie verpflichten sich, Ihr Passwort nicht an Dritte
                weiterzugeben. Sie m&uuml;ssen uns sofort benachrichtigen, wenn
                Sie von einer Verletzung der Sicherheit oder einer unbefugten
                Nutzung Ihres Kontos erfahren. Sie k&ouml;nnen Ihr Konto nur von
                einem Ger&auml;t aus betreten.
            </p>
            <p className='p3'>
                Sie d&uuml;rfen als Benutzernamen nicht den Namen einer anderen
                nat&uuml;rlichen oder juristischen Person oder einen Namen
                verwenden, der nicht rechtm&auml;&szlig;ig zur Verf&uuml;gung
                steht, einen Namen oder eine Marke, der/die ohne entsprechende
                Genehmigung den Rechten einer anderen nat&uuml;rlichen oder
                juristischen Person als Ihnen unterliegt, oder einen Namen, der
                anderweitig beleidigend, vulg&auml;r oder obsz&ouml;n ist.
            </p>
            <p className='p3'>
                Jeder Benutzer erkennt an, dass jeder Satz von
                Benutzeranmeldeinformationen nicht &uuml;bertragbar ist und nur
                von dem Benutzer verwendet werden darf, dem er ausgestellt
                wurde. Solche Benutzeranmeldeinformationen d&uuml;rfen ohne
                schriftliche Genehmigung des Unternehmens nicht an Dritte
                weitergegeben werden. Wir werden Sie niemals, aus welchem Grund
                auch immer, per E-Mail, Post oder Telefon auffordern, Ihre
                Benutzerdaten preiszugeben. Passwortabfragen erfolgen nur online
                und nur, nachdem Sie sich auf der Plattform angemeldet haben.
                Wir werden Ihnen niemals eingebettete Links in einer E-Mail
                senden und Sie auffordern, sich durch Anklicken eines solchen
                Links auf der Plattform anzumelden. Wenn Sie einen eingebetteten
                Link per E-Mail erhalten, der vorgibt, von uns oder der
                Plattform zu stammen, d&uuml;rfen Sie diesen Link nicht
                &ouml;ffnen oder anklicken.
                <span className='Apple-converted-space'>&nbsp; </span>Die E-Mail
                stammt nicht von uns und ist wahrscheinlich betr&uuml;gerisch.
            </p>
            <p className='p3'>
                <strong>Autorisierte Personen</strong>
            </p>
            <p className='p3'>
                Jeder Nutzer und jede autorisierte Person best&auml;tigt, dass
                sie diese Bedingungen erhalten und akzeptiert haben.
                <span className='Apple-converted-space'>&nbsp; </span>Mit der
                Beantragung eines Zugangs zur Plattform und eines Kontos
                f&uuml;r eine autorisierte Person erkennt der Nutzer an und
                sichert zu, dass die autorisierte Person
                ordnungsgem&auml;&szlig; bevollm&auml;chtigt ist, (i) im Namen
                des Nutzers auf die Plattform zuzugreifen und diese zu nutzen
                und, falls zutreffend, die gleichen Befugnisse auszu&uuml;ben,
                die der Nutzer der autorisierten Person gem&auml;&szlig; einer
                zugrunde liegenden Vollmacht &uuml;bertragen hat, soweit dies
                technisch m&ouml;glich ist, und dass die auf der Plattform
                angebotenen Dienste den Diensten entsprechen, die der Nutzer
                &uuml;ber andere Kan&auml;le in Anspruch nehmen kann; (ii)
                &Auml;nderungen dieser Bedingungen im Namen des Nutzers zu
                akzeptieren; und (iii) Dienstleistungen der Plattform zu
                beantragen oder zu abonnieren, die eine separate Beantragung
                oder ein Abonnement erfordern.
            </p>
            <p className='p3'>
                Jeder Nutzer hat daf&uuml;r zu sorgen, dass jede autorisierte
                Person, die in seinem Namen handelt, &uuml;ber diese Bedingungen
                und gegebenenfalls die Bedingungen des Drittanbieters von
                Dienstleistungen informiert ist und diesen zustimmt und sie
                einh&auml;lt. Sie haften in vollem Umfang f&uuml;r alle
                Handlungen, Unterlassungen oder Verst&ouml;&szlig;e der von
                Ihnen benannten bevollm&auml;chtigten Personen beim Zugang und
                der Nutzung der Plattform und bei allen &uuml;ber Ihr Konto
                durchgef&uuml;hrten Transaktionen.
            </p>
            <p className='p3'>
                Sie erkl&auml;ren, verpflichten sich und best&auml;tigen, dass
                Sie die Zustimmung Ihrer autorisierten Personen zur Sammlung,
                Verwendung, &Uuml;bertragung, Offenlegung und Verarbeitung der
                pers&ouml;nlichen Daten dieser autorisierten Personen in
                &Uuml;bereinstimmung mit diesen Bedingungen und der
                Datenschutzrichtlinie eingeholt haben.
            </p>
            <p className='p3'>
                Sie nehmen zur Kenntnis und erkl&auml;ren sich damit
                einverstanden, dass die von der Plattform bereitgestellten
                Informationen und Dienste nicht f&uuml;r nat&uuml;rliche oder
                juristische Personen in L&auml;ndern bereitgestellt werden, in
                denen deren Bereitstellung oder Nutzung gegen geltendes Recht
                verst&ouml;&szlig;t oder in denen wir nicht befugt sind, diese
                Plattform oder Informationen und Dienste bereitzustellen, und
                dass sie von diesen Personen oder Organisationen nicht genutzt
                werden d&uuml;rfen.
            </p>
            <p className='p3'>
                <strong>Geistiges Eigentum</strong>
            </p>
            <p className='p3'>
                Alle Titel, Eigentumsrechte und geistigen Eigentumsrechte an
                oder in Bezug auf die Website und die Plattform, jegliche
                Informationen, die von, an oder &uuml;ber die Plattform
                &uuml;bertragen werden, sowie Informationen bez&uuml;glich der
                Nutzung der Plattform verbleiben beim Unternehmen oder seinen
                Lizenzgebern. Nichts auf der Plattform ist so auszulegen, dass
                einem Nutzer eine Lizenz an den Eigentumsrechten,
                Eigentumsrechten und/oder geistigen Eigentumsrechten des
                Unternehmens oder eines Dritten &uuml;bertragen wird, es sei
                denn, dies ist hier ausdr&uuml;cklich festgelegt, sei es durch
                Rechtsverwirkung, stillschweigend oder anderweitig.
            </p>
            <p className='p3'>
                Die Plattform und die App k&ouml;nnen Ihnen Zugang zu Inhalten,
                Informationen, Zitaten, Videos, Fotos oder anderen Materialien
                (die "Inhalte Dritter") bieten, die von bestimmten Dritten (den
                "Anbietern von Inhalten Dritter") bereitgestellt werden. Das
                Unternehmen bef&uuml;rwortet oder empfiehlt nicht die
                Richtigkeit, G&uuml;ltigkeit oder Vollst&auml;ndigkeit von
                Inhalten Dritter, die &uuml;ber die Website oder Plattform
                bereitgestellt werden, und ist nicht daf&uuml;r verantwortlich,
                diese zu &uuml;berpr&uuml;fen.{' '}
                <span className='Apple-converted-space'>&nbsp; </span>Die
                Nutzung solcher Inhalte von Drittanbietern oder Ihr Vertrauen in
                diese erfolgt auf Ihr eigenes Risiko. Alle Titel,
                Eigentumsrechte und geistigen Eigentumsrechte an oder in Bezug
                auf die Inhalte Dritter verbleiben bei dem jeweiligen Anbieter
                der Inhalte Dritter. Nichts auf der Plattform ist so auszulegen,
                dass einem Nutzer eine Lizenz f&uuml;r das Eigentum, die
                Eigentumsrechte und/oder die Rechte am geistigen Eigentum eines
                Drittanbieters von Inhalten &uuml;bertragen wird, es sei denn,
                dies ist hier ausdr&uuml;cklich vorgesehen, sei es durch
                Rechtsverwirkung, stillschweigend oder anderweitig.
            </p>
            <p className='p3'>
                Unter der Voraussetzung, dass Sie diese Bedingungen einhalten,
                k&ouml;nnen Sie die Website auf ein einziges mobiles Ger&auml;t
                herunterladen und darauf zugreifen und mit
                ordnungsgem&auml;&szlig; ausgestellten
                Benutzerberechtigungsnachweisen auf die Plattform zugreifen.
                Alle anderen Rechte an der Website sind dem Unternehmen
                vorbehalten.
                <span className='Apple-converted-space'>&nbsp; </span>
                Sollten Sie gegen diese Bedingungen versto&szlig;en, sind wir
                berechtigt, Ihre Nutzung und Ihren Zugang zur Website und zur
                Plattform sofort zu beenden.
            </p>
            <p className='p3'>Sie stimmen zu, dies nicht zu tun:</p>
            <p className='p3'>
                (a) die Website oder die Plattform oder Daten oder Inhalte
                (einschlie&szlig;lich der Inhalte Dritter), die &uuml;ber die
                Website oder die Plattform bereitgestellt werden, oder Teile
                davon zu ver&auml;ndern, anzupassen, zu reproduzieren, zu
                &uuml;bersetzen oder davon abgeleitete Werke zu erstellen oder
                zu versuchen, den Quellcode der Website oder der Plattform
                zur&uuml;ckzuentwickeln, zu dekompilieren, zu disassemblieren
                oder anderweitig zu versuchen, ihn zu ermitteln;
            </p>
            <p className='p3'>
                (b) Urheberrechtsvermerke, Marken, Legenden, Logos oder
                Produktkennzeichnungen von der Website oder Plattform zu
                entfernen;
            </p>
            <p className='p3'>
                (c) die anderen Sites f&auml;lschlicherweise als die Site des
                Unternehmens auszugeben, indem sie das visuelle "Look and Feel"
                oder den Text der Site des Unternehmens &uuml;bernehmen oder
                anderweitig die geistigen Eigentumsrechte des Unternehmens
                verletzen, einschlie&szlig;lich, aber nicht beschr&auml;nkt auf
                das "Scraping" von Text oder Bildern von der Site des
                Unternehmens oder von dem Unternehmen verwalteten Bannern
                und/oder Textlinks, Suchmaschinenmarketing oder allen anderen
                Online- und Offline-Kampagnen,
            </p>
            <p className='p3'>
                (d) die Reihenfolge der auf den Websites des Unternehmens
                enthaltenen Informationen zu bearbeiten, zu modifizieren, zu
                filtern, zu k&uuml;rzen oder zu &auml;ndern oder Teile der
                Website des Unternehmens ohne Genehmigung des Unternehmens zu
                entfernen, unkenntlich zu machen oder zu minimieren; oder
            </p>
            <p className='p3'>
                (e) die Website oder Plattform oder das Logo, die Marke oder den
                Markennamen des Unternehmens in irgendeiner Weise kommerziell zu
                nutzen.
            </p>
            <p className='p3'>
                Jeder Nutzer erm&auml;chtigt das Unternehmen, alle Informationen
                oder Inhalte, die der Nutzer zur Verf&uuml;gung stellt oder die
                im Zusammenhang mit der Nutzung der Website und der Plattform
                verarbeitet werden (z. B. pers&ouml;nliche Informationen,
                geografische Informationen, Ger&auml;teinformationen), im
                Zusammenhang und zum Zweck der Bereitstellung von
                Dienstleistungen oder Produkten auf der Plattform und der
                sicheren Nutzung der Website und der Plattform zu verwenden.
            </p>
            <p className='p3'>
                <strong>Bereitstellung von Material und Informationen</strong>
            </p>
            <p className='p3'>
                Sie erkennen an, dass weder das Unternehmen noch die Plattform
                Ihr Reiseberater oder Treuh&auml;nder ist. Sie erkennen ferner
                an, dass keines der von uns bereitgestellten oder auf der
                Plattform zur Verf&uuml;gung gestellten Materialien eine
                Empfehlung oder Aufforderung an Sie darstellt, eine bestimmte
                Entscheidung w&auml;hrend Ihrer Reise zu treffen.
            </p>
            <p className='p3'>
                Sie erkennen an, dass wir nicht verpflichtet sind, die auf der
                Plattform angezeigten Materialien zu &uuml;berpr&uuml;fen, zu
                korrigieren, zu erg&auml;nzen oder zu aktualisieren. Die
                Materialien, insbesondere Nachrichten und Forschungsergebnisse,
                k&ouml;nnen von Informationsanbietern erstellt werden, die von
                uns unabh&auml;ngig sind. Wir &uuml;bernehmen keine Gew&auml;hr
                f&uuml;r die Richtigkeit, Vollst&auml;ndigkeit oder rechtzeitige
                Aktualisierung des Materials. Sie sollten weitere Recherchen und
                Analysen durchf&uuml;hren oder einen Reiseberater konsultieren,
                bevor Sie Entscheidungen treffen. Jegliche Nutzung des Materials
                oder das Vertrauen darauf erfolgt auf Ihr eigenes Risiko.
            </p>
            <p className='p3'>
                <strong>Service und andere Benachrichtigungen</strong>
            </p>
            <p className='p3'>
                Sie erkl&auml;ren sich damit einverstanden, Benachrichtigungen
                &uuml;ber die App, die Plattform, Ihr Konto und die Bedingungen
                durch Service-Benachrichtigungen zu akzeptieren. Sie
                erkl&auml;ren sich damit einverstanden, dass solche
                Service-Benachrichtigungen eine wirksame Benachrichtigung
                anstelle von schriftlichen, per Post versandten oder anderen
                Formen der Benachrichtigung darstellen, die nach geltendem Recht
                erforderlich sind.
            </p>
            <p className='p3'>
                Es liegt in Ihrer alleinigen Verantwortung, das betreffende
                E-Mail-Konto ohne weitere Erinnerungen oder wiederholte
                Benachrichtigungen durch das Unternehmen zu &uuml;berwachen. Sie
                m&uuml;ssen jede unbefugte Nutzung oder jeden unbefugten Zugriff
                auf die Plattform unverz&uuml;glich melden.
            </p>
            <p className='p3'>
                Sie entbinden das Unternehmen von jeglicher Haftung f&uuml;r
                Verluste oder Sch&auml;den, die sich aus der Nutzung der
                Service-Benachrichtigungen ergeben, soweit dies gesetzlich
                zul&auml;ssig ist. Das Unternehmen &uuml;bernimmt keine Garantie
                oder Haftung daf&uuml;r, dass die &uuml;ber die
                Service-Benachrichtigungen bereitgestellten Informationen
                aktuell, richtig oder vollst&auml;ndig sind.
            </p>
            <p className='p3'>
                <strong>Pers&ouml;nliche Informationen</strong>
            </p>
            <p className='p3'>
                Im Rahmen der Plattform k&ouml;nnen personenbezogene Daten des
                Nutzers vom Unternehmen in &Uuml;bereinstimmung mit der
                Datenschutzrichtlinie erfasst, verwendet, &uuml;bertragen,
                offengelegt oder anderweitig verarbeitet werden. Sie sollten die
                Datenschutzrichtlinie sorgf&auml;ltig lesen, bevor Sie sich auf
                der Website und der Plattform registrieren und diese nutzen.
                <span className='Apple-converted-space'>&nbsp; </span>Sie
                stimmen der Erfassung, Verwendung und Offenlegung Ihrer
                pers&ouml;nlichen Daten in &Uuml;bereinstimmung mit diesen
                Bedingungen und der Datenschutzrichtlinie zu,
                einschlie&szlig;lich, aber nicht beschr&auml;nkt auf die
                Offenlegung gegen&uuml;ber dem Drittanbieter von
                Dienstleistungen zum Zwecke der Bereitstellung von
                Dienstleistungen und der Durchf&uuml;hrung von Transaktionen im
                Zusammenhang mit dem Konto.
            </p>
            <p className='p3'>
                Sie verpflichten sich, wahrheitsgem&auml;&szlig;e, genaue,
                aktuelle und vollst&auml;ndige pers&ouml;nliche Daten anzugeben.
                Sie verpflichten sich ferner, die pers&ouml;nlichen Daten zu
                pflegen und unverz&uuml;glich zu aktualisieren, damit sie
                w&auml;hrend der Laufzeit dieser Vereinbarung jederzeit wahr,
                genau, aktuell und vollst&auml;ndig sind.
            </p>
            <p className='p3'>
                Sie m&uuml;ssen uns unverz&uuml;glich &uuml;ber alle
                &Auml;nderungen informieren, einschlie&szlig;lich, aber nicht
                beschr&auml;nkt auf &Auml;nderungen der pers&ouml;nlichen Daten
                in Verbindung mit der Plattform. Wenn Sie unwahre, ungenaue,
                nicht aktuelle oder unvollst&auml;ndige Angaben machen oder wenn
                wir oder einer unserer bevollm&auml;chtigten Vertreter den
                begr&uuml;ndeten Verdacht haben, dass diese Angaben unwahr,
                ungenau, nicht aktuell oder unvollst&auml;ndig sind, haben wir
                das Recht, das Konto zu sperren oder zu k&uuml;ndigen und Ihnen
                jegliche derzeitige oder k&uuml;nftige Nutzung der Plattform und
                der Website zu verweigern sowie Sie zivilrechtlich haftbar zu
                machen oder Sie an die zust&auml;ndigen
                Strafverfolgungsbeh&ouml;rden zur strafrechtlichen Verfolgung zu
                verweisen.<span className='Apple-converted-space'>&nbsp; </span>
                Wir sind nicht verpflichtet, nach einer solchen Aussetzung,
                Beendigung oder Unf&auml;higkeit zur Nutzung der Plattform oder
                der Website eine Entsch&auml;digung zu leisten, weder in
                finanzieller noch in anderer Form.
            </p>
            <p className='p3'>
                Bitte beachten Sie, dass wir mithilfe von Tracking-Technologien
                Informationen &uuml;ber Ihr Ger&auml;t sammeln k&ouml;nnen, wie
                z. B. IP-Adresse, Netzbetreiber, Mobilfunkanbieter, Typ des
                mobilen Browsers, Zeitstempel, Zeitzone, Informationen &uuml;ber
                Geschwindigkeit, Peilung, Ausrichtung und H&ouml;he eines
                Ger&auml;ts oder andere ger&auml;teidentifizierende
                Informationen. Der Nutzer stimmt einer solchen Verwendung von
                Tracking-Technologien zu und erkennt an, dass die erhaltenen
                Informationen, einschlie&szlig;lich pers&ouml;nlicher Daten, mit
                &ouml;ffentlichen oder privaten Informationen abgeglichen werden
                k&ouml;nnen, die dem Unternehmen oder einem Drittanbieter von
                Dienstleistungen zug&auml;nglich sind. Der Nutzer erkl&auml;rt
                sich au&szlig;erdem damit einverstanden, dass solche
                Informationen an die Dienstleister des Unternehmens und des
                Drittanbieters weitergegeben werden, um die
                Tracking-Technologien und die damit verbundenen Dienste
                bereitzustellen und zu pflegen. Wir k&ouml;nnen auch genaue
                Geolokalisierungsdaten von oder &uuml;ber Ihr Ger&auml;t
                sammeln, die durch Breiten- und L&auml;ngenkoordinaten
                ausgedr&uuml;ckt werden k&ouml;nnen, die durch GPS-Tools,
                WiFi-Daten, Triangulation von Mobilfunkmasten oder andere
                Techniken erhalten werden. Unsere Verwendung solcher
                Informationen wird in unserer Datenschutzrichtlinie beschrieben.
            </p>
            <p className='p3'>
                <strong>
                    Haftungsausschluss und Risiken der Nutzung der Plattform
                </strong>
            </p>
            <p className='p3'>
                Die Plattform und die Website, einschlie&szlig;lich aller
                Inhalte (einschlie&szlig;lich der Inhalte Dritter), Funktionen
                und aller damit verbundenen Dienstleistungen werden auf
                alleiniges Risiko des Nutzers und ohne jegliche Zusicherungen
                oder Gew&auml;hrleistungen "wie besehen" und "wie
                verf&uuml;gbar" bereitgestellt. Wir garantieren nicht, dass die
                Plattform oder die App ganz oder teilweise zu jeder Zeit
                f&uuml;r den Nutzer verf&uuml;gbar oder zug&auml;nglich sein
                wird.
            </p>
            <p className='p3'>
                Die Nutzung der Plattform birgt insbesondere durch das
                Herunterladen, die Installation oder die Nutzung der Website und
                der damit verbundenen Verweise auf Dritte (z.B. Anbieter von
                Vertriebsplattformen, Netzbetreiber, Ger&auml;tehersteller)
                Risiken:
            </p>
            <p className='p3'>
                (a) Offenlegung Ihrer pers&ouml;nlichen Daten oder anderer
                Informationen und des Bestehens Ihrer Beziehung zum
                Drittanbieter von Dienstleistungen gegen&uuml;ber Dritten;
            </p>
            <p className='p3'>
                (b) Systemausf&auml;lle, sicherheitsbedingte
                Einschr&auml;nkungen und unbefugte Aufhebung von
                Nutzungsbeschr&auml;nkungen auf dem Endger&auml;t sowie andere
                St&ouml;rungen, die eine Nutzung unm&ouml;glich machen
                k&ouml;nnen, und
            </p>
            <p className='p3'>
                (c) Missbrauch aufgrund von Manipulationen durch Schadsoftware
                oder unbefugte Nutzung, einschlie&szlig;lich des Verlusts oder
                Diebstahls des Ger&auml;ts des Nutzers, das f&uuml;r den Zugriff
                auf die Website oder die Plattform verwendet wird.
            </p>
            <p className='p3'>
                Wir sind berechtigt, die Nutzung der Website auf Endger&auml;ten
                zu sperren oder zu deaktivieren, wenn die Sicherheitsmerkmale
                des Betriebssystems oder des Herstellers des Ger&auml;ts, auf
                dem die Website installiert ist, zu irgendeinem Zeitpunkt
                ver&auml;ndert wurden (z.B. ein Ger&auml;t, das "jailbroken"
                wurde). Dementsprechend &uuml;bernehmen wir keine Gew&auml;hr
                f&uuml;r die Funktionsf&auml;higkeit und den Betrieb der App auf
                derartig ver&auml;nderten Endger&auml;ten oder auf &auml;lteren
                Endger&auml;ten, die nicht mehr den technischen Anforderungen
                f&uuml;r die Nutzung der Website oder den Zugang zur Plattform
                entsprechen.
            </p>
            <p className='p3'>
                ALLE GARANTIEN, BEDINGUNGEN ODER BESTIMMUNGEN (OB
                AUSDR&Uuml;CKLICH, STILLSCHWEIGEND, GESETZLICH ODER
                ANDERWEITIG), EINSCHLIESSLICH UND OHNE EINSCHR&Auml;NKUNG IN
                BEZUG AUF QUALIT&Auml;T, MARKTG&Auml;NGIGKEIT, EIGNUNG F&Uuml;R
                EINEN BESTIMMTEN ZWECK ODER UNUNTERBROCHENEN, FEHLERFREIEN
                ZUGANG, WERDEN F&Uuml;R DIE WEBSITE UND DIE PLATTFORM
                AUSDR&Uuml;CKLICH AUSGESCHLOSSEN, SOWEIT DIES GESETZLICH
                ZUL&Auml;SSIG IST.
            </p>
            <p className='p3'>
                Es kann keine ausdr&uuml;ckliche oder stillschweigende
                Zusicherung oder Gew&auml;hrleistung hinsichtlich der
                Richtigkeit oder Vollst&auml;ndigkeit der auf der Plattform
                bereitgestellten Informationen gegeben werden.
            </p>
            <p className='p3'>
                Jeder Nutzer erkennt die Risiken an, die sich aus dem
                Reiseerlebnis ergeben k&ouml;nnen, und akzeptiert diese. Wir
                k&ouml;nnen f&uuml;r die Bereitstellung eines Teils der
                Plattform auch Server und andere Computer-Hardware verwenden,
                die sich in jeder beliebigen Gerichtsbarkeit weltweit befinden.
            </p>
            <p className='p3'>
                Wir schlie&szlig;en jegliche Haftung f&uuml;r Verluste oder
                Sch&auml;den aus, die durch &Uuml;bermittlungsfehler, technische
                M&auml;ngel, Betriebsst&ouml;rungen, Betriebsunterbrechungen
                oder rechtswidrige Eingriffe in &Uuml;bermittlungsnetze,
                EDV-Systeme/Rechner des Nutzers oder Dritter
                (einschlie&szlig;lich &ouml;ffentlich zug&auml;nglicher Systeme)
                verursacht werden.
            </p>
            <p className='p3'>
                <strong>Terminierung</strong>
            </p>
            <p className='p3'>
                Wir k&ouml;nnen Ihr Konto sofort und ohne vorherige
                Ank&uuml;ndigung oder Haftung k&uuml;ndigen oder aussetzen, aus
                welchem Grund auch immer, einschlie&szlig;lich und ohne
                Einschr&auml;nkung, wenn Sie gegen diese Allgemeinen
                Gesch&auml;ftsbedingungen versto&szlig;en.
            </p>
            <p className='p3'>
                Mit der K&uuml;ndigung erlischt Ihr Recht zur Nutzung des
                Dienstes sofort. Wenn Sie Ihr Konto k&uuml;ndigen m&ouml;chten,
                k&ouml;nnen Sie die Nutzung des Dienstes einfach einstellen.
            </p>
            <p className='p3'>
                <strong>Freigabe</strong>
            </p>
            <p className='p3'>
                Soweit dies nach geltendem Recht zul&auml;ssig ist, entbinden
                Sie uns, unsere Muttergesellschaft, verbundene Unternehmen und
                Tochtergesellschaften sowie deren jeweilige
                F&uuml;hrungskr&auml;fte, Direktoren, Anteilseigner, Mitglieder,
                Partner, Anw&auml;lte, Mitarbeiter, unabh&auml;ngige
                Auftragnehmer, Telekommunikationsanbieter und Vertreter
                (zusammen die "entsch&auml;digten Parteien") hiermit von allen
                Vorw&uuml;rfen, Klagen, Forderungen, Anspr&uuml;chen und
                Verlusten in Bezug auf die Nutzung der Website, der Plattform,
                eines Kontos und von Dienstleistungen und Aktivit&auml;ten im
                Zusammenhang mit der Nutzung der Plattform, von allen
                Behauptungen, Anklagen, Belastungen, Schulden,
                Klagegr&uuml;nden, Anspr&uuml;chen und Verlusten, die in
                irgendeiner Weise mit der Nutzung oder Aktivit&auml;ten im
                Zusammenhang mit der Nutzung der Website, der Plattform, eines
                Kontos und jeglicher Dienstleistungen oder Inhalte Dritter, die
                &uuml;ber die Website, die Plattform oder ein Konto
                bereitgestellt werden, zusammenh&auml;ngen,
                einschlie&szlig;lich, aber nicht beschr&auml;nkt auf
                Anspr&uuml;che in Bezug auf Folgendes: Fahrl&auml;ssigkeit,
                grobe Fahrl&auml;ssigkeit, vors&auml;tzliche
                Beeintr&auml;chtigung von Vertr&auml;gen oder vorteilhaften
                Gesch&auml;ftsbeziehungen, Verleumdung, Datenschutz, Werbung,
                Falschdarstellung, falsche Identit&auml;ten, betr&uuml;gerische
                Handlungen durch andere, Verletzung der Privatsph&auml;re,
                Freigabe pers&ouml;nlicher Daten, fehlgeschlagene Transaktionen,
                K&auml;ufe oder Funktionalit&auml;t der Plattform,
                Nichtverf&uuml;gbarkeit der Website, der Plattform, von Inhalten
                Dritter oder eines Kontos, deren Funktionen und jedes andere
                technische Versagen, das zu einer Unzug&auml;nglichkeit der
                Website, der Plattform, der Inhalte Dritter oder eines Kontos
                f&uuml;hren kann, oder jeder Anspruch, der auf der Haftung
                f&uuml;r Handlungen beruht, die von Ihnen begangen wurden und
                mit denen Sie &uuml;ber die Website, die Plattform, die Inhalte
                Dritter und ein Konto in Ber&uuml;hrung gekommen sind,
                einschlie&szlig;lich, aber nicht beschr&auml;nkt auf Betrug,
                Computer-Hacking, Diebstahl oder Missbrauch von
                pers&ouml;nlichen Daten, K&ouml;rperverletzung, Stalking,
                Vergewaltigung, Betrug, Meineid, Totschlag oder Mord.
                <span className='Apple-converted-space'>&nbsp; </span>Die obige
                Liste dient lediglich der Veranschaulichung und ist nicht
                ersch&ouml;pfend in Bezug auf die Arten oder Kategorien von
                Anspr&uuml;chen, die von uns freigegeben werden.
                <span className='Apple-converted-space'>&nbsp; </span>Die
                Parteien beabsichtigen, diese Freigabe weit zu unseren Gunsten
                auszulegen, so dass jede Zweideutigkeit so ausgelegt wird, dass
                die weitestgehende Freigabe der Anspr&uuml;che erfolgt.
                <span className='Apple-converted-space'>&nbsp; </span>Diese
                Freistellung soll eine vollst&auml;ndige Freistellung von
                Anspr&uuml;chen sein, und die Parteien erkennen die
                rechtsverbindliche Natur dieser Bestimmung und die Art der damit
                aufgegebenen Rechte an.
            </p>
            <p className='p3'>
                <strong>Beschr&auml;nkung der Haftung</strong>
            </p>
            <p className='p3'>
                SafeTravy haftet nicht f&uuml;r Informationen, die &uuml;ber die
                SafeTravy-Produkte verf&uuml;gbar und/oder in diese integriert
                sind. Folglich kann SafeTravy nicht f&uuml;r die Richtigkeit,
                Vollst&auml;ndigkeit, Qualit&auml;t, Rechtm&auml;&szlig;igkeit
                und Zuverl&auml;ssigkeit der Informationen oder f&uuml;r die
                Ergebnisse, die mit Hilfe dieser Informationen erzielt werden,
                haftbar gemacht werden.
            </p>
            <p className='p3'>
                Die SafeTravy-Dienste stellen Instrumente dar, die f&uuml;r das
                Risikomanagement und die Risikobewertung verwendet werden und
                diese unterst&uuml;tzen. SafeTravy kann nicht garantieren, dass
                alle Informationen und Aktualisierungen vollst&auml;ndig,
                angemessen und fehlerfrei sind. Die Nutzer sollten die
                Ergebnisse und Daten des Dienstes stets auf individueller Basis
                bewerten. SafeTravy ist bestrebt, das Risiko f&uuml;r die Nutzer
                zu verringern, ist jedoch in keiner Weise f&uuml;r die
                Sicherheit der Nutzer verantwortlich, und SafeTravy
                &uuml;bernimmt keine Haftung f&uuml;r Verluste,
                K&ouml;rperverletzungen oder Tod von Nutzern.
            </p>
            <p className='p3'>
                UNTER KEINEN UMST&Auml;NDEN UND UNTER KEINER RECHTSTHEORIE
                (UNERLAUBTE HANDLUNG, VERTRAG, VERSCHULDENSUNABH&Auml;NGIGE
                HAFTUNG ODER SONSTIGES) HAFTEN WIR ODER EINER DER
                SCHADENSERSATZPFLICHTIGEN GEGEN&Uuml;BER IHNEN ODER EINER
                ANDEREN PERSON F&Uuml;R SCH&Auml;DEN, DIE SICH AUS DER NUTZUNG
                ODER MISSBR&Auml;UCHLICHEN NUTZUNG ODER DER UNF&Auml;HIGKEIT ZUR
                NUTZUNG DER PLATTFORM, DER SITE, DER INHALTE DRITTER ODER EINES
                KONTOS ERGEBEN, UNABH&Auml;NGIG DAVON, OB ES SICH UM DIREKTE,
                INDIREKTE, BESONDERE, ZUF&Auml;LLIGE ODER FOLGESCH&Auml;DEN
                JEGLICHER ART, INFORMATIONSVERLUSTE, BETRIEBSUNTERBRECHUNGEN
                ODER ENTGANGENE GEWINNE HANDELT, SCH&Auml;DEN JEGLICHER ART,
                VERLUST VON INFORMATIONEN, GESCH&Auml;FTSUNTERBRECHUNGEN ODER
                ENTGANGENE GEWINNE, ENTGANGENE EINSPARUNGEN ODER DATENVERLUSTE
                ODER HAFTUNGEN AUFGRUND VON VERTR&Auml;GEN, FAHRL&Auml;SSIGKEIT,
                VERSCHULDENSUNABH&Auml;NGIGER HAFTUNG ODER ANDEREN THEORIEN, DIE
                SICH IN IRGENDEINER WEISE AUS DER WEBSITE, DER PLATTFORM, DEN
                INHALTEN DRITTER ODER EINEM KONTO ERGEBEN ODER MIT DIESEN IN
                ZUSAMMENHANG STEHEN, ODER F&Uuml;R ANSPR&Uuml;CHE ODER
                FORDERUNGEN DRITTER, SELBST WENN WIR VON DER M&Ouml;GLICHKEIT
                SOLCHER SCH&Auml;DEN, FORDERUNGEN ODER ANSPR&Uuml;CHE WUSSTEN
                ODER WISSEN MUSSTEN, FALLS DER VORSTEHENDE HAFTUNGSAUSSCHLUSS
                UND -VERZICHT ALS UNG&Uuml;LTIG ODER UNWIRKSAM ERACHTET WERDEN
                SOLLTE. IN EINIGEN GERICHTSBARKEITEN IST DER AUSSCHLUSS ODER DIE
                BESCHR&Auml;NKUNG BESTIMMTER GARANTIEN UND/ODER HAFTUNGEN NICHT
                ZUL&Auml;SSIG, SO DASS BESTIMMTE DER OBEN GENANNTEN
                BESCHR&Auml;NKUNGEN ODER AUSSCHL&Uuml;SSE F&Uuml;R SIE
                M&Ouml;GLICHERWEISE NICHT GELTEN.
            </p>
            <p className='p3'>
                IN KEINEM FALL &Uuml;BERSTEIGT UNSERE HAFTUNG, UNABH&Auml;NGIG
                VON DER ART DER KLAGE UND DES VON IHNEN ERLITTENEN SCHADENS, DEN
                H&Ouml;CHSTBETRAG DER VON IHNEN AN UNS IN VERBINDUNG MIT DER
                PLATTFORM ODER DER SITE GEZAHLTEN ABONNEMENTGEB&Uuml;HREN ODER
                500 US-DOLLAR, JE NACHDEM, WELCHER BETRAG H&Ouml;HER IST.
            </p>
            <p className='p3'>
                Wir haften nicht f&uuml;r die Nichterf&uuml;llung von
                Verpflichtungen gem&auml;&szlig; diesen Bedingungen aufgrund von
                Ereignissen, die sich unserer Kontrolle entziehen, und die
                f&uuml;r die Erf&uuml;llung solcher Verpflichtungen vorgesehene
                Zeit wird um einen Zeitraum verl&auml;ngert, der der Dauer
                solcher Ereignisse entspricht.
                <span className='Apple-converted-space'>&nbsp; </span>Zu den
                Ereignissen, die sich unserer Kontrolle entziehen, geh&ouml;ren
                unter anderem h&ouml;here Gewalt, Krieg, Aufruhr, Brandstiftung,
                Embargos, zivile Unruhen, Streiks, Arbeitsk&auml;mpfe,
                Ausr&uuml;stungsausf&auml;lle, Bankausf&auml;lle, Zusammenbruch
                oder Schwankungen des Marktes f&uuml;r virtuelle W&auml;hrungen,
                Ausf&auml;lle bei der Verarbeitung von Kredit- oder
                Debitkartentransaktionen, Streiks, Feuer, &Uuml;berschwemmungen,
                Erdbeben, Wirbelst&uuml;rme, tropische St&uuml;rme oder andere
                Naturkatastrophen oder Unf&auml;lle, Arbeitskr&auml;fte- oder
                Materialmangel, Mangel an Transportmitteln, Einrichtungen,
                Treibstoff, Energie, staatliche Vorschriften oder
                Einschr&auml;nkungen, Handlungen ziviler oder milit&auml;rischer
                Beh&ouml;rden oder Terrorismus, Unterbrechungen von
                Glasfaserkabeln, Wetterbedingungen, Verst&ouml;&szlig;e oder
                Vers&auml;umnisse Dritter, technische Probleme,
                einschlie&szlig;lich Hardware- und Softwareabst&uuml;rze und
                andere Fehlfunktionen, Versagen der Infrastruktur von
                Telekommunikations- oder Informationsdiensten, Hacking, SPAM
                oder Versagen von Computern, Servern oder
                Softwareunterbrechungen aufgrund von Vandalismus, Diebstahl,
                Ausf&auml;llen von Telefondiensten, Stromausf&auml;llen,
                Internetunterbrechungen, Viren und mechanischen, Strom- oder
                Kommunikationsausf&auml;llen.
            </p>
            <p className='p3'>
                <strong>
                    &Auml;nderungen an diesen Bedingungen und Konditionen
                </strong>
            </p>
            <p className='p3'>
                Wir behalten uns das Recht vor, diese Bedingungen jederzeit nach
                unserem alleinigen Ermessen zu &auml;ndern oder zu ersetzen.
                Wenn eine &Auml;nderung wesentlich ist, werden wir uns
                angemessen bem&uuml;hen, Sie mindestens 30 Tage vor
                Inkrafttreten der neuen Bedingungen zu informieren. Was eine
                wesentliche &Auml;nderung darstellt, wird nach unserem
                alleinigen Ermessen bestimmt.
            </p>
            <p className='p3'>
                Indem Sie nach Inkrafttreten dieser &Auml;nderungen weiterhin
                auf unseren Dienst zugreifen oder ihn nutzen, erkl&auml;ren Sie
                sich mit den &uuml;berarbeiteten Bedingungen einverstanden. Wenn
                Sie mit den neuen Bedingungen ganz oder teilweise nicht
                einverstanden sind, beenden Sie bitte die Nutzung der Website
                und des Dienstes.
            </p>
            <p className='p3'>
                <strong>Allgemein</strong>
            </p>
            <p className='p3'>
                Diese Bedingungen, einschlie&szlig;lich der
                Datenschutzrichtlinie und anderer hierin enthaltener
                Richtlinien, stellen die gesamte und einzige Vereinbarung
                zwischen Ihnen und dem Unternehmen in Bezug auf den Gegenstand
                dieser Bedingungen dar und ersetzen alle fr&uuml;heren oder
                gleichzeitigen Vereinbarungen, Zusicherungen, Garantien und
                Absprachen, ob schriftlich oder m&uuml;ndlich, in Bezug auf den
                Gegenstand dieser Bedingungen. Sollte sich eine Bestimmung
                dieser Bedingungen als ungesetzlich, ung&uuml;ltig oder aus
                irgendeinem Grund nicht durchsetzbar erweisen, so gilt diese
                Bestimmung als von diesen Bedingungen abtrennbar und
                ber&uuml;hrt nicht die G&uuml;ltigkeit und Durchsetzbarkeit der
                &uuml;brigen Bestimmungen. Diese Bedingungen k&ouml;nnen nicht
                ge&auml;ndert, aufgehoben oder modifiziert werden, au&szlig;er
                durch das Unternehmen, wie hierin vorgesehen. Weder diese
                Bedingungen noch irgendein Recht, eine Verpflichtung oder ein
                Rechtsbehelf hierunter sind von Ihnen abtretbar,
                &uuml;bertragbar, delegierbar oder unterlizenzierbar,
                au&szlig;er mit unserer vorherigen schriftlichen Zustimmung, und
                jeder Versuch einer Abtretung, &Uuml;bertragung, Delegation oder
                Unterlizenzierung ist null und nichtig. Der Verzicht einer
                Partei auf eine Vertragsverletzung oder ein Vers&auml;umnis im
                Rahmen dieser Vereinbarung gilt nicht als Verzicht auf eine
                vorhergehende oder nachfolgende Vertragsverletzung oder ein
                Vers&auml;umnis.
                <span className='Apple-converted-space'>&nbsp; </span>
                &Uuml;berschriften, Titel oder Abschnitts&uuml;berschriften in
                diesen Bedingungen dienen nur der &Uuml;bersichtlichkeit und
                definieren oder erkl&auml;ren in keiner Weise einen Abschnitt
                oder eine Bestimmung dieser Bedingungen.
            </p>
            <p className='p3'>
                <strong>Geltendes Recht und Streitbeilegung</strong>
            </p>
            <p className='p3'>
                Alle Streitigkeiten, Meinungsverschiedenheiten oder
                Anspr&uuml;che, die sich aus diesem Vertrag oder im Zusammenhang
                mit ihm ergeben, einschlie&szlig;lich solcher, die sich auf
                seinen Abschluss, seine Auslegung, seine Durchf&uuml;hrung,
                seine Verletzung, seine Beendigung oder seine Ung&uuml;ltigkeit
                beziehen, werden vom internationalen Handelsschiedsgericht bei
                der ukrainischen Industrie- und Handelskammer in
                &Uuml;bereinstimmung mit dessen Bestimmungen entschieden.
            </p>
            <p className='p3'>
                Das f&uuml;r diesen Vertrag ma&szlig;gebliche Recht ist das
                materielle Recht von Gibraltar.
            </p>
            <p className='p3'>
                Das Schiedsgericht besteht aus einem Einzelschiedsrichter.
                Sitzungsort des Schiedsgerichts ist die Stadt Kiew, Ukraine. Die
                Sprache des Schiedsgerichtsverfahrens ist Englisch oder
                Ukrainisch.
            </p>
            <p className='p3'>
                <strong>Kontaktieren Sie uns</strong>
            </p>
            <p className='p3'>
                Wenn Sie Fragen zu diesen Allgemeinen Geschäftsbedingungen
                haben, können Sie uns kontaktieren:
            </p>
            <p className='p3'>
                Per E-Mail:{' '}
                <span style={{ color: 'blue', textDecoration: 'underline' }}>
                    team@safetravy.com
                </span>
            </p>
            <p className='p3'>
                Per Telefon: <a href='tel:+350 54095364'>+350 54095364</a>
            </p>
        </>
    ),
    [Languages.EN]: (
        <>
            <p className='p3'>
                These Terms and Conditions (the &ldquo;Terms&rdquo; or this
                &ldquo;Agreement&rdquo;) govern the use of the electronic
                platform, including any website or mobile application (the
                &ldquo;App&rdquo;, together with the website, the
                &ldquo;Site&rdquo;) for accessing the platform, and any services
                provided through the platform (collectively, the
                &ldquo;Platform&rdquo;) provided by SafeTravy Limited (the
                &ldquo;Company&rdquo;, &ldquo;we&rdquo;, &ldquo;us&rdquo; or
                &ldquo;our&rdquo;).
                <span className='Apple-converted-space'>&nbsp; </span>
                The Terms form a binding agreement between the Company and you,
                as an individual user (&ldquo;you&rdquo;, &ldquo;your&rdquo; or
                &ldquo;User&rdquo;) for your individual usage of the App and
                Platform. By registering for and downloading the App and using
                the Platform, you confirm your acceptance of this Agreement and
                our associated Privacy Policy.
                <span className='Apple-converted-space'>&nbsp; </span>If you do
                not agree to these Terms, you must immediately uninstall the App
                and cease using the App and the Platform.
            </p>
            <p className='p3'>
                Securities Disclaimer: No material or any other information that
                may be made available on the Site or Platform shall constitute
                or be construed as a recommendation, endorsement, offer,
                invitation or solicitation to you to make any decision, all made
                decisions are made out of your own will and experience. You
                further understand that none of the information providers,
                including any Third-Party Providers (as defined below) are
                advising you personally concerning the nature, potential, value
                or suitability of any particular security questions regarding
                your travels. Please note that the situation in the world
                changes rapidly, so any information, content, Third-Party
                Content (as defined below) or other material provided on or
                through the Site or Platform may not be complete or current, or
                may be superseded by more current information. You rely on such
                information at your own risk.
            </p>
            <p className='p3'>
                Our Site and Platform are not intended to provide legal,
                insurance, or investment advice, and nothing on the Site or
                Platform should be construed as an offer to you on how to
                organize your travel. All this information is only personalized
                recommendation.
            </p>
            <p className='p3'>
                <strong>1. Applicability</strong>
            </p>
            <p className='p3'>
                1.1. We, SafeTravy Limited (hereinafter
                &ldquo;SafeTravy&rdquo;), offer services in the field of IT
                entertainment These general terms and conditions apply to the
                contractual relationship between SafeTravy and the Customer
                (&ldquo;Customer&rdquo; or &ldquo;You&rdquo;) with respect to
                all services we provide.
            </p>
            <p className='p3'>
                <strong>2. Definitions</strong>
            </p>
            <p className='p3'>
                <strong>Account </strong>means the account established by a User
                that has downloaded the App or accessed the Site and registered
                with the Company to use the Site and the Platform.
            </p>
            <p className='p3'>
                <strong>App</strong> means the mobile application provided by
                the Company to access the Platform.
            </p>
            <p className='p3'>
                <strong>Authorized Individual </strong>means any person that is
                authorized to access and use the Site (including the App) and
                Platform on behalf of a User.
            </p>
            <p className='p3'>
                <strong>Digital Platforms</strong> refers to third-party
                distribution platforms where mobile applications or other
                software programs can be accessed or downloaded, including, but
                not limited to, the Apple App Store and Google Play.
            </p>
            <p className='p3'>
                <strong>Governmental Authority</strong> means any nation or
                government or any province or state or any other political
                subdivision thereof, or any entity, authority or body exercising
                executive, legislative, judicial, regulatory or administrative
                functions of or pertaining to government, including any
                government authority, agency, department, board, commission or
                instrumentality or any political subdivision thereof, any court,
                tribunal or arbitrator, and any self-regulatory organization.
            </p>
            <p className='p3'>
                <strong>Material </strong>means any offering material, term
                sheet, research report, product or service documentation or any
                other information provided through the Platform.
            </p>
            <p className='p3'>
                <strong>Personal Information </strong>refers to information
                supplied by a User from which the identity of such User may be
                directly or indirectly ascertained.
            </p>
            <p className='p3'>
                <strong>Privacy Policy</strong> means the additional terms and
                conditions governing the collection, use and disclosure of each
                User&rsquo;s Personal Information, as set out here. Each User
                must read and agree to the Privacy Policy in order to use the
                App or the Site.
            </p>
            <p className='p3'>
                <strong>Service Notifications</strong> are one-way notifications
                from the Company (which may include security-related
                notifications) via text message or emails and, where applicable,
                push notifications through the Site.
                <span className='Apple-converted-space'>&nbsp; </span>
                These notifications are sent to the User in respect of certain
                information or events relating to an account to which an User
                has access through the Platform.
            </p>
            <p className='p3'>
                <strong>User</strong> means any person that has registered with
                the Company to use the Site and access the Platform and any
                Authorized Individual acting on their behalf.
            </p>
            <p className='p3'>
                <strong>User Credentials</strong> means the set of user
                identification, password, personal identification number, token
                and any other information or device provided to an User to
                access the Platform.
            </p>
            <p className='p3'>
                <strong>Company</strong> (referred to as either "the Company",
                "We", "Us" or "Our" in this Agreement) refers to SAFETRAVY
                LIMITED, Suite 4, 2nd floor, the West Wing, Montarik House, 3
                Bedlam Court, Gibraltar, GX11 1AA, GIBRALTAR, GIBRALTAR.
            </p>
            <p className='p3'>
                <strong>Device</strong> means any device that can access the
                Service such as a computer, a cellphone or a digital tablet.
            </p>
            <p className='p3'>
                <strong>Free Trial </strong>refers to a limited period of time
                that may be free when purchasing a Subscription.
            </p>
            <p className='p3'>
                <strong>Service </strong>refers to all the information,
                recommendations and notifications provided by the website.
            </p>
            <p className='p3'>
                <strong>Subscriptions</strong> refer to the services or access
                to the Service offered on a subscription basis by the Company to
                You.
            </p>
            <p className='p3'>
                <strong>Terms and Conditions </strong>(also referred as "Terms")
                mean these Terms and Conditions that form the entire agreement
                between You and the Company regarding the use of the Service.
            </p>
            <p className='p3'>
                <strong>Third-party</strong> Service means any services or
                content (including data, information, products or services)
                provided by a third-party that may be displayed, included or
                made available by the Service.
            </p>
            <p className='p3'>
                <strong>Website</strong> refers to SafeTravy, accessible from
                www.safetravy.com
            </p>
            <p className='p3'>
                <strong>Changes</strong>
            </p>
            <p className='p3'>We reserve the right at any time to:</p>
            <p className='p3'>
                (a) modify, update or change the terms and conditions of this
                Agreement or our Privacy Policy ;
            </p>
            <p className='p3'>
                (b) modify, update, or change the Site and Platform, including
                eliminating or discontinuing any content or feature of the Site
                or Platform (all of the foregoing referred to as
                &ldquo;Changes&rdquo;).
            </p>
            <p className='p3'>
                We may make such Changes at any time without prior notice.
                <span className='Apple-converted-space'>&nbsp; </span>
                Any Changes to this Agreement may be posted on our website or
                notified to you through push notifications through the Site or
                an email to the email address in your Account. For this reason,
                you should check our website regularly, allow the Site to
                receive such push notifications, and keep your email address and
                other contact information up to date in the Account. You accept
                any Changes if you continue to use the Site and Platform after
                such Changes are effected.
            </p>
            <p className='p3'>
                <strong>Digital Platform Terms</strong>
            </p>
            <p className='p3'>
                The App may be available for download from one or more Digital
                Platforms. Your download, installation, access to or use of the
                App is also bound by the terms and conditions and privacy
                policies of the applicable Digital Platform (the &ldquo;Digital
                Platform Terms&rdquo;). If there is any conflict between these
                Terms and the Digital Platform Terms, then these Terms will
                prevail.
            </p>
            <p className='p3'>
                The App is independent of and is not associated, affiliated,
                sponsored, endorsed or in any way linked to any Digital
                Platform. You and we acknowledge that this Agreement is entered
                into between you and us only, and not with any Digital Platform,
                and we, not the Digital Platform, are solely responsible for the
                App and the content thereof to the extent specified in this
                Agreement.
            </p>
            <p className='p3'>
                You and we acknowledge and agree that the relevant Digital
                Platform, and that Digital Platform&rsquo;s subsidiaries are
                third-party beneficiaries of these Terms, and that, upon your
                acceptance of these Terms, that Digital Platform will have the
                right (and will be deemed to have accepted the right) to enforce
                these Terms against you as a third-party beneficiary thereof.
            </p>
            <p className='p3'>
                <strong>Network Device and Carrier Requirements</strong>
            </p>
            <p className='p3'>
                You acknowledge that your agreement with your mobile and
                Internet network provider (the &ldquo;Network Provider&rdquo;)
                will apply to your use of the Site. You acknowledge that you may
                be charged by your Network Provider for data services while
                using certain features of the Site or any other third-party
                charges as may arise and you accept sole responsibility for such
                charges. If you are not the bill payer for the mobile/Internet
                device being used to access the Site, you will be assumed to
                have received permission from the bill payer for using the Site.
                You must also ensure that your use of the Site is not in
                violation of your mobile or Internet device agreement or any
                wireless data service agreement.
            </p>
            <p className='p3'>
                <strong>Eligibility and Registration</strong>
            </p>
            <p className='p3'>
                You must be at least 18 years of age to access and use the Site
                and Platform. You further affirm that you are fully able and
                competent to enter into the terms, conditions, obligations,
                affirmations, representations, and warranties set forth in these
                Terms, and to abide by and comply with these Terms. You must
                register with the Company to use the Site and the Platform; you
                agree to provide complete and accurate information when
                registering to use the Site and the Platform, and to keep that
                information updated.
            </p>
            <p className='p3'>
                We have the sole discretion to accept or reject your
                registration with the Platform. Only Users whose registrations
                are approved by us will be our customers.
            </p>
            <p className='p3'>
                <strong>Acknowledgment</strong>
            </p>
            <p className='p3'>
                These are the Terms and Conditions governing the use of this
                Service and the agreement that operates between You and the
                Company. These Terms and Conditions set out the rights and
                obligations of all users regarding the use of the Service.
            </p>
            <p className='p3'>
                Your access to and use of the Service is conditioned on Your
                acceptance of and compliance with these Terms and Conditions.
                These Terms and Conditions apply to all visitors, users and
                others who access or use the Service.
            </p>
            <p className='p3'>
                By accessing or using the Service You agree to be bound by these
                Terms and Conditions. If You disagree with any part of these
                Terms and Conditions then You may not access the Service.
            </p>
            <p className='p3'>
                You represent that you are over the age of 18. The Company does
                not permit those under 18 to use the Service.
            </p>
            <p className='p3'>
                Your access to and use of the Service is also conditioned on
                Your acceptance of and compliance with the Privacy Policy of the
                Company. Our Privacy Policy describes Our policies and
                procedures on the collection, use and disclosure of Your
                personal information when You use the Application or the Website
                and tells You about Your privacy rights and how the law protects
                You. Please read Our Privacy Policy carefully before using Our
                Service.
            </p>
            <p className='p3'>
                <strong>Subscriptions</strong>
            </p>
            <p className='p3'>
                <strong>Subscription period</strong>
            </p>
            <p className='p3'>
                The Service or some parts of the Service are available only with
                a paid Subscription. You will be billed in advance on a
                recurring and periodic basis (such as daily, weekly, monthly or
                annually), depending on the type of Subscription plan you select
                when purchasing the Subscription.
            </p>
            <p className='p3'>
                At the end of each period, Your Subscription will automatically
                renew under the exact same conditions unless You cancel it or
                the Company cancels it.
            </p>
            <p className='p3'>
                <strong>Subscription cancellations</strong>
            </p>
            <p className='p3'>
                You may cancel Your Subscription renewal either through Your
                Account settings page or by contacting the Company. You will not
                receive a refund for the fees You already paid for Your current
                Subscription period and You will be able to access the Service
                until the end of Your current Subscription period.
            </p>
            <p className='p3'>
                <strong>Billing</strong>
            </p>
            <p className='p3'>
                You shall provide the Company with accurate and complete billing
                information including full name, address, state,
                <span className='Apple-converted-space'>&nbsp; </span>
                and valid payment method information.
            </p>
            <p className='p3'>
                Should automatic billing fail to occur for any reason, the
                Company will issue an electronic invoice indicating that you
                must proceed manually, within a certain deadline date, with the
                full payment corresponding to the billing period as indicated on
                the invoice.
            </p>
            <p className='p3'>
                <strong>Fee Changes</strong>
            </p>
            <p className='p3'>
                The Company, in its sole discretion and at any time, may modify
                the Subscription fees. Any Subscription fee change will become
                effective at the end of the then-current Subscription period.
            </p>
            <p className='p3'>
                The Company will provide You with reasonable prior notice of any
                change in Subscription fees to give You an opportunity to
                terminate Your Subscription before such change becomes
                effective.
            </p>
            <p className='p3'>
                Your continued use of the Service after the Subscription fee
                change comes into effect constitutes Your agreement to pay the
                modified Subscription fee amount.
            </p>
            <p className='p3'>
                <strong>Refunds</strong>
            </p>
            <p className='p3'>
                Except when required by law, paid Subscription fees are
                non-refundable.
            </p>
            <p className='p3'>
                Certain refund requests for Subscriptions may be considered by
                the Company on a case-by-case basis and granted at the sole
                discretion of the Company.
            </p>
            <p className='p3'>
                <strong>Free Trial</strong>
            </p>
            <p className='p3'>
                The Company may, at its sole discretion, offer a Subscription
                with a Free Trial for a limited period of time.
            </p>
            <p className='p3'>
                You may be required to enter Your billing information in order
                to sign up for the Free Trial.
            </p>
            <p className='p3'>
                If You do enter Your billing information when signing up for a
                Free Trial, You will not be charged by the Company until the
                Free Trial has expired. On the last day of the Free Trial
                period, unless You cancel Your Subscription, You will be
                automatically charged the applicable Subscription fees for the
                type of Subscription You have selected.
            </p>
            <p className='p3'>
                At any time and without notice, the Company reserves the right
                to (i) modify the terms and conditions of the Free Trial offer,
                or (ii) cancel such Free Trial offer.
            </p>
            <p className='p3'>
                <strong>User Accounts</strong>
            </p>
            <p className='p3'>
                In order to use the services on the Platform, you must create an
                account with the Platform (the &ldquo;Account&rdquo;).
                <span className='Apple-converted-space'>&nbsp; </span>
                The Account may be registered by any individual who is over 18
                years old.
            </p>
            <p className='p3'>
                When You create an account with Us, You must provide Us
                information that is accurate, complete, and current at all
                times. Failure to do so constitutes a breach of the Terms, which
                may result in immediate termination of Your account on Our
                Service.
            </p>
            <p className='p3'>
                You are responsible for safeguarding the password that You use
                to access the Service and for any activities or actions under
                Your password, whether Your password is with Our Service or a
                Third-Party Service.
            </p>
            <p className='p3'>
                You agree not to disclose Your password to any third party. You
                must notify Us immediately upon becoming aware of any breach of
                security or unauthorized use of Your account. You can enter your
                account only from one device.
            </p>
            <p className='p3'>
                You may not use as a username the name of another person or
                entity or that is not lawfully available for use, a name or
                trademark that is subject to any rights of another person or
                entity other than You without appropriate authorization, or a
                name that is otherwise offensive, vulgar or obscene.
            </p>
            <p className='p3'>
                Each User acknowledges that each set of User Credentials is
                non-transferable and shall only be used by the User to whom it
                is issued. Such User Credentials shall not be disclosed to or
                transfer to any third person without written permission of the
                Company. We will never ask you, for any reason, whether by
                email, regular mail or telephone, to disclose your User
                Credentials. Password inquiries will only be conducted online
                and only after you have signed onto the Platform. We will never
                send you embedded links in an email requesting that you sign
                onto the Platform by clicking such a link. If you receive an
                embedded link by email, claiming to be from us or the Platform,
                you shall not open or click on the link.
                <span className='Apple-converted-space'>&nbsp; </span>
                The email is not from us and is likely fraudulent.
            </p>
            <p className='p3'>
                <strong>Authorized Individuals</strong>
            </p>
            <p className='p3'>
                Each User and Authorized Individual acknowledges that they have
                received and accepted these Terms.
                <span className='Apple-converted-space'>&nbsp; </span>
                When applying for any Authorized Individual to receive access to
                the Platform and an Account on their behalf, the User
                acknowledges and represents that the Authorized Individual is
                duly authorized to (i) access and use the Platform on the
                User&rsquo;s behalf and, if applicable, to exercise the same
                powers conferred by the User upon the Authorized Individual
                <span className='Apple-converted-space'>&nbsp; </span>in
                accordance with any underlying power of attorney to the same
                extent as is technically feasible and that services offered
                under the Platform are analogous to services that the User may
                utilize through other channels; (ii) accept any Changes to these
                Terms on the User&rsquo;s behalf; and (iii) apply or subscribe
                to any of the Platform services that require separate
                application or subscription.
            </p>
            <p className='p3'>
                Each User shall procure that each Authorized Individual acting
                on their behalf is informed of and agrees to and complies with
                these Terms and, as applicable, the Third-Party Services
                Provider Terms. You shall be fully liable for all acts or
                omissions or non-compliance of your designated Authorized
                Individual in the access and use of the Platform and any
                transactions conducted through your Account.
            </p>
            <p className='p3'>
                You represent, undertake and confirm that you have procured the
                consent of your Authorized Individuals to the collection, use,
                transfer, disclosure and processing of the Personal Information
                of such Authorized Individuals in accordance with these Terms
                and the Privacy Policy.
            </p>
            <p className='p3'>
                You understand and agree that the information and services
                provided by the Platform are not provided to, and may not be
                used by or for the benefit of, any individual or entity in any
                jurisdiction where the provision or use thereof would be
                contrary to any applicable law, or where we are not authorized
                to provide such Platform or information and services.
            </p>
            <p className='p3'>
                <strong>Intellectual Property</strong>
            </p>
            <p className='p3'>
                All title, ownership rights and intellectual property rights in
                or relating to the Site and Platform, any information
                transmitted by, to or over the Platform and information
                regarding use of the Platform will remain with the Company or
                its licensors. Nothing on the Platform will be construed as
                conferring on any User any license, save as expressly set out
                herein, of any of the Company&rsquo;s or any third party&rsquo;s
                title, ownership rights and/or intellectual property rights,
                whether by estoppel, implication or otherwise.
            </p>
            <p className='p3'>
                The Platform and App may provide you access to content,
                information, quote, videos, photos or other materials (the
                &ldquo;Third-Party Content&rdquo;) supplied by certain third
                parties (the &ldquo;Third-Party Content Providers&rdquo;). The
                Company does not endorse or recommend, and is not responsible
                for verifying the accuracy, validity or completeness of any
                Third-Party Content provided through the Site or Platform.{' '}
                <span className='Apple-converted-space'>&nbsp; </span>
                Your use or reliance on such Third-Party Content is at your sole
                risk. All title, ownership rights and intellectual property
                rights in or relating to the Third-Party Content will remain
                with the applicable Third-Party Content Provider. Nothing on the
                Platform will be construed as conferring on any User any
                license, save as expressly set out herein, of any Third-Party
                Content Provider&rsquo;s title, ownership rights and/or
                intellectual property rights, whether by estoppel, implication
                or otherwise.
            </p>
            <p className='p3'>
                Provided you are in compliance with these Terms, you can
                download and access the Site on a single mobile device and
                access the Platform using properly issued User Credentials. All
                other rights in the Site are reserved by the Company.
                <span className='Apple-converted-space'>&nbsp; </span>In the
                event of your breach of these Terms, we will be entitled to
                terminate your use and access to the Site and Platform
                immediately.
            </p>
            <p className='p3'>You agree not to:</p>
            <p className='p3'>
                (a) modify, adapt, reproduce, translate or create derivative
                works of the Site or Platform, or any data or content (including
                the Third-Party Content) provided through the Site or Platform,
                or any portion thereof, or attempt to reverse engineer,
                decompile, disassemble or otherwise attempt to discover the
                source code of the Site or Platform;
            </p>
            <p className='p3'>
                (b) remove any copyright notice, trademark, legend, logo or
                product identification from the Site or Platform;
            </p>
            <p className='p3'>
                (c) misrepresent the other sites as the Company&rsquo;s Site by
                co-opting the visual &ldquo;look and feel&rdquo; of or text from
                the Company&rsquo;s Site or otherwise violate the
                Company&rsquo;s intellectual property rights, including, without
                limitation, &ldquo;scraping&rdquo; text or images from the
                Company&rsquo;s Site or the Company managed banners and/or text
                links, search marketing or all other online and offline
                campaigns,
            </p>
            <p className='p3'>
                (d) edit, modify, filter, truncate or change the order of the
                information contained in any part of the Company&rsquo;s Sites,
                or remove, obscure, or minimize any part of the Company&rsquo;s
                Site in any way without authorization of the Company; or
            </p>
            <p className='p3'>
                (e) make any commercial use of the Site or Platform or the
                Company&rsquo;s logo, trademark or brand name in any way.
            </p>
            <p className='p3'>
                Each User authorizes the Company to use any information or
                content provided by the User or processed in connection with the
                use of the Site and Platform (e.g. Personal Information,
                geographic information, device information) in the context and
                for the purpose of providing services or products on the
                Platform and the secure use of the Site and the Platform.
            </p>
            <p className='p3'>
                <strong>Provision of Material and Information</strong>
            </p>
            <p className='p3'>
                You acknowledge that neither the Company nor the Platform is
                your travel adviser or fiduciary. You further acknowledge that
                none of the Materials we provide or made available on the
                Platform constitutes our recommendation or solicitation that you
                enter into any particular decision during your travel.
            </p>
            <p className='p3'>
                You acknowledge that we have no duty or obligation to verify,
                correct, complete or update any Material displayed on the
                Platform. Materials, including without limitation, news and
                research, may be prepared by information providers that are
                independent of us. We do not warrant that the Material will be
                accurate, complete or refreshed in a timely manner. You should
                conduct further research and analysis or consult a travel
                advisor before making decisions. Any use of or reliance on
                materials by you is at your own risk.
            </p>
            <p className='p3'>
                <strong>Service and Other Notifications</strong>
            </p>
            <p className='p3'>
                You agree to accept notifications regarding the App, Platform,
                your Account and Terms through Service Notifications. You agree
                that such Service Notifications shall constitute effective
                notice in lieu of written, mailed or other forms of notice
                required by applicable law.
            </p>
            <p className='p3'>
                It is your sole responsibility to monitor the applicable email
                account without further reminders or repeat notifications from
                the Company. You shall immediately report any unauthorized use
                or access of the Platform.
            </p>
            <p className='p3'>
                You release the Company from any liability for losses or damages
                resulting from the use of the Service Notifications to the
                extent permitted by law. The Company provides no warranty or
                accepts no liability that the information provided through
                Service Notifications is up-to-date, correct or complete.
            </p>
            <p className='p3'>
                <strong>Personal Information</strong>
            </p>
            <p className='p3'>
                As part of the Platform, Personal Information of the User may be
                collected, used, transferred, disclosed or otherwise processed
                by the Company in accordance with the Privacy Policy. You should
                read the Privacy Policy carefully before registering for and
                using the Site and Platform.
                <span className='Apple-converted-space'>&nbsp; </span>
                You consent to the collection, use and disclosure of your
                Personal Information in accordance with these Terms and the
                Privacy Policy, including without limitation, disclosure to the
                Third-Party Services Provider for purposes of providing services
                and conducting transactions in regards to the Account.
            </p>
            <p className='p3'>
                You agree to provide true, accurate, current and complete
                Personal Information. You further agree to maintain and promptly
                update the Personal Information to keep it true, accurate,
                current and complete at all times during the term of this
                Agreement.
            </p>
            <p className='p3'>
                You must promptly inform us of all changes, including, but not
                limited to, changes in the Personal Information in connection
                with the Platform. If you provide any information that is
                untrue, inaccurate, not current or incomplete, or if we or any
                of our authorized agents have reasonable grounds to suspect that
                such information is untrue, inaccurate, not current or
                incomplete, we have the right to suspend or terminate the
                Account and refuse any and all current or future use of the
                Platform and Site by you, as well as subject you to civil
                liability or refer you to the appropriate law enforcement
                authorities for criminal prosecution.
                <span className='Apple-converted-space'>&nbsp; </span>We shall
                not be liable to make any compensation, monetary or otherwise,
                following such suspension, termination or inability for you to
                use the Platform or the Site.
            </p>
            <p className='p3'>
                Please note that we may collect information using tracking
                technologies regarding your device, such as IP address, network
                provider, mobile carrier, mobile browser type, timestamp, time
                zone, information about the speed, bearing, orientation, and
                altitude of a device, or other device-identifying information.
                The User consents to such use of tracking technologies and
                acknowledges that the information obtained, including Personal
                Information, may be matched to public or private information
                accessible to the Company or any Third-Party Services Provider.
                The User also consents to such information being shared with the
                Company&rsquo;s and Third-Party Services Provider&rsquo;s
                service providers for the purposes of providing and maintaining
                the tracking technologies and related services. We may also
                collect precise geolocation data from or about your device,
                which may be expressed by latitude-longitude coordinates
                obtained through GPS tools, WiFi data, cell tower triangulation
                or other techniques. Our use of such information is described in
                our Privacy Policy.
            </p>
            <p className='p3'>
                <strong>Disclaimer and Risks of Use of Platform</strong>
            </p>
            <p className='p3'>
                The Platform and Site, including all content (including
                Third-Party Content), features and any related services are
                provided on an &ldquo;As Is&rdquo; and &ldquo;As
                Available&rdquo; basis at the User&rsquo;s sole risk and without
                any representations or warranties. We do not guarantee that all
                or any part of the Platform or the App will be available or
                accessible by the User at all times.
            </p>
            <p className='p3'>
                The use of the Platform, due to the download, installation or
                use of the Site and the associated reference points with third
                parties (for example, distribution platform providers, network
                providers, device manufacturers) involves risks, in particular:
            </p>
            <p className='p3'>
                (a) disclosure of your Personal Information or other information
                and the existence of your relationship with the Third-Party
                Services Provider to third parties;
            </p>
            <p className='p3'>
                (b) system outages, security-related restrictions and
                unauthorized removal of use restrictions on the end device, and
                other disturbances which may make use impossible; and
            </p>
            <p className='p3'>
                (c) misuse due to manipulation by malware or unauthorized use,
                including in the event the User&rsquo;s device used to access
                the Site or the Platform is lost or stolen.
            </p>
            <p className='p3'>
                We are entitled to block or disable the use of the Site on end
                devices if the security features devised by the operating system
                or manufacturer of such device on which the Site is installed
                have been modified at any time (for example, a device that has
                been &ldquo;jailbroken&rdquo;). Accordingly, we do not guarantee
                the functioning and operation of the App on end devices which
                have been modified in this way or on older end devices that no
                longer meet the technical requirements for the use of the Site
                or access to the Platform.
            </p>
            <p className='p3'>
                ALL WARRANTIES, CONDITIONS OR TERMS (WHETHER EXPRESS, IMPLIED,
                STATUTORY OR OTHERWISE) INCLUDING WITHOUT LIMITATION RELATING TO
                QUALITY, MERCHANTABILITY, FITNESS FOR PURPOSE, OR UNINTERRUPTED,
                ERROR-FREE ACCESS ARE EXPRESSLY EXCLUDED FOR THE SITE AND
                PLATFORM TO THE FULLEST EXTENT PERMITTED BY LAW.
            </p>
            <p className='p3'>
                No representation or warranty, express or implied, can be given
                as to the accuracy or completeness of the information provided
                in the Platform.
            </p>
            <p className='p3'>
                Each User acknowledges and accepts the risks that may arise from
                the travel experience. We may also use servers and other
                computer hardware situated in any jurisdiction worldwide for the
                provision of any portion of the Platform.
            </p>
            <p className='p3'>
                We exclude any and all liability for loss or damage caused by
                transmission errors, technical faults, breakdowns, business
                interruptions or illegal interventions into transmission
                networks, IT systems/computers of the User or of any third party
                (including systems in the public domain).
            </p>
            <p className='p3'>
                <strong>Termination</strong>
            </p>
            <p className='p3'>
                We may terminate or suspend Your Account immediately, without
                prior notice or liability, for any reason whatsoever, including
                without limitation if You breach these Terms and Conditions.
            </p>
            <p className='p3'>
                Upon termination, Your right to use the Service will cease
                immediately. If You wish to terminate Your Account, You may
                simply discontinue using the Service.
            </p>
            <p className='p3'>
                <strong>Release</strong>
            </p>
            <p className='p3'>
                To the maximum extent permitted by applicable law, you hereby
                discharge, acquit, and otherwise release us, our parent company,
                affiliates and subsidiaries and each of their respective
                officers, directors, shareholders, members, partners, attorneys,
                employees, independent contractors, telecommunication providers,
                and agents (collectively, the &ldquo;Indemnified
                Parties&rdquo;), from any and all allegations, counts, charges,
                debts, causes of action, claims and losses, relating in any way
                to the use of, or activities relating to the use of the Site,
                Platform, any Account and any services
                <span className='Apple-converted-space'>&nbsp; </span>or
                Third-Party Content provided through the Site, Platform or any
                Account, including, but not limited to, claims relating to the
                following: negligence, gross negligence, intentional
                interference with contract or advantageous business
                relationship, defamation, privacy, publicity, misrepresentation,
                <span className='Apple-converted-space'>&nbsp; </span>
                false identities, fraudulent acts by others, invasion of
                privacy, release of Personal Information, failed transactions,
                purchases or functionality of the Platform, unavailability of
                the Site, the Platform, Third-Party Content or any Account,
                their functions and any other technical failure that may result
                in inaccessibility to the Site, the Platform, Third-Party
                Content or any Account, or any claim based on vicarious
                liability for torts committed by you encountered or transacted
                with through the Site, Platform, Third-Party Content and any
                Account, including, but not limited to, fraud, computer hacking,
                theft or misuse of Personal Information, assault, battery,
                stalking, rape, cheating, perjury, manslaughter, or murder.
                <span className='Apple-converted-space'>&nbsp; </span>
                The above list is intended to be illustrative only, and not
                exhaustive of the types or categories of claims released by us.
                <span className='Apple-converted-space'>&nbsp; </span>
                This release is intended by the parties to be interpreted
                broadly in favor of us, and thus any ambiguity shall be
                interpreted in a manner providing release of the broadest
                claims.
                <span className='Apple-converted-space'>&nbsp; </span>
                This release is intended to be a full release of claims, and the
                parties acknowledge the legally binding nature of this
                provision, and the nature of the rights given up in connection
                therewith.
            </p>
            <p className='p3'>
                <strong>Limitation of Liability</strong>
            </p>
            <p className='p3'>
                SafeTravy is not liable for any information which is available
                via and/or integrated with the SafeTravy Products. Consequently,
                SafeTravy cannot be held liable for the correctness,
                completeness, quality, lawfulness and reliability of the
                information or for the results which are achieved by means of
                such information.
            </p>
            <p className='p3'>
                The SafeTravy Services constitute tools to be used for and
                support risk management and risk assessment. SafeTravy is unable
                to warrant that all information and updates are complete,
                adequate and error-free. Users should always evaluate output and
                data from the service on an individual basis. SafeTravy aims to
                reduce risk for the users but shall in no way be responsible for
                the safety and security of the users and SafeTravy assumes no
                liability for losses, bodily injury or death of users.
            </p>
            <p className='p3'>
                UNDER NO CIRCUMSTANCES AND UNDER NO THEORY OF LAW (TORT,
                CONTRACT, STRICT LIABILITY OR OTHERWISE), SHALL WE OR ANY OF THE
                INDEMNITEES BE LIABLE TO YOU OR ANY OTHER PERSON FOR ANY DAMAGES
                ARISING FROM THE USE OR MISUSE OF, OR INABILITY TO USE, THE
                PLATFORM, THE SITE, THIRD-PARTY CONTENT OR ANY ACCOUNT,
                REGARDLESS OF WHETHER SUCH DAMAGES ARE DIRECT, INDIRECT,
                SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES OF ANY CHARACTER,
                LOSS OF INFORMATION, BUSINESS INTERRUPTION OR LOST PROFITS, LOST
                SAVINGS, OR LOSS OF DATA, OR LIABILITIES UNDER ANY CONTRACT,
                NEGLIGENCE, STRICT LIABILITY, OR OTHER THEORY ARISING OUT OF OR
                RELATING IN ANY MANNER TO THE SITE, THE PLATFORM, THIRD-PARTY
                CONTENT OR ANY ACCOUNT OR FOR ANY CLAIM OR DEMAND BY ANY THIRD
                PARTY, EVEN IF WE KNEW OR HAD REASON TO KNOW OF THE POSSIBILITY
                OF SUCH DAMAGES, CLAIM OR DEMAND IF THE FOREGOING DISCLAIMER AND
                WAIVER OF LIABILITY SHOULD BE DEEMED INVALID OR INEFFECTIVE.
                SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
                CERTAIN WARRANTIES AND/OR LIABILITIES, SO CERTAIN OF THE ABOVE
                LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU.
            </p>
            <p className='p3'>
                IN NO EVENT SHALL OUR LIABILITY, REGARDLESS OF THE FORM OF
                ACTION AND DAMAGES SUFFERED BY YOU, EXCEED THE HIGHEST AGGREGATE
                SUBSCRIPTION FEES PAID BY YOU TO US IN CONNECTION WITH THE
                PLATFORM, OR THE SITE, OR 500 U.S. DOLLARS, WHICHEVER IS
                GREATER.
            </p>
            <p className='p3'>
                We will not be liable for our failure to perform any obligations
                under these Terms due to events beyond our control, and the time
                provided for performing such obligations shall be extended by a
                period of time equal to the duration of such events.
                <span className='Apple-converted-space'>&nbsp; </span>
                Events beyond our control include, without limitation, acts of
                God, war, riot, arson, embargoes, civil commotion, strikes,
                labor disputes, equipment failures, bank failures, virtual
                currency market collapse or fluctuations, credit or debit card
                transaction processing failures, strikes, fire, flood,
                earthquake, hurricanes, tropical storms or other natural
                disaster or casualty, shortages of labor or material, shortage
                of transportation, facilities, fuel, energy, government
                regulation or restriction, acts of civil or military authority
                or terrorism, fiber cuts, weather conditions, breaches or
                failures to perform by third parties, technical problems,
                including hardware and software crashes and other malfunctions,
                failure of the telecommunications or information services
                infrastructure, hacking, SPAM or failure of any computer, server
                or software disruptions on account of or caused by vandalism,
                theft, phone service outages, power outage, Internet
                disruptions, viruses, and mechanical, power or communications
                failures.
            </p>
            <p className='p3'>
                <strong>Changes to These Terms and Conditions</strong>
            </p>
            <p className='p3'>
                We reserve the right, at Our sole discretion, to modify or
                replace these Terms at any time. If a revision is material We
                will make reasonable efforts to provide at least 30 days' notice
                prior to any new terms taking effect. What constitutes a
                material change will be determined at Our sole discretion.
            </p>
            <p className='p3'>
                By continuing to access or use Our Service after those revisions
                become effective, You agree to be bound by the revised terms. If
                You do not agree to the new terms, in whole or in part, please
                stop using the website and the Service.
            </p>
            <p className='p3'>
                <strong>General</strong>
            </p>
            <p className='p3'>
                These Terms, including the Privacy Policy and other policies
                incorporated herein, constitute the entire and only agreement
                between you and the Company with respect to the subject matter
                of these Terms, and supersede any and all prior or
                contemporaneous agreements, representations, warranties and
                understandings, written or oral, with respect to the subject
                matter of these Terms. If any provision of these Terms is found
                to be unlawful, void or for any reason unenforceable, then that
                provision shall be deemed severable from these Terms and shall
                not affect the validity and enforceability of any remaining
                provisions. These Terms may not be changed, waived or modified
                except by the Company as provided herein. Neither these Terms
                nor any right, obligation or remedy hereunder is assignable,
                transferable, delegable or sublicensable by you except with our
                prior written consent, and any attempted assignment, transfer,
                delegation or sublicense shall be null and void. No waiver by
                any party of any breach or default hereunder shall be deemed to
                be a waiver of any preceding or subsequent breach or default.
                <span className='Apple-converted-space'>&nbsp; </span>
                Any heading, caption or section title contained in these Terms
                is inserted only as a matter of convenience and in no way
                defines or explains any section or provision hereof.
            </p>
            <p className='p3'>
                <strong>Governing Law and Dispute Resolution</strong>
            </p>
            <p className='p3'>
                All disputes, disagreements or claims arising under this
                agreement or in connection with it, including those relating to
                its conclusion, interpretation, execution, violation,
                termination or invalidity, shall be resolved by the
                International Commercial Arbitration Court at the Ukrainian
                Chamber of Commerce and Industry in accordance with its
                Regulations.
            </p>
            <p className='p3'>
                The law governing this agreement is the substantive law of
                Gibraltar.
            </p>
            <p className='p3'>
                The arbitration court consists of a sole arbitrator. The
                location of the meeting of the Arbitration Court is the city of
                Kyiv, Ukraine. The language of the arbitration proceedings is
                English or Ukrainian.
            </p>
            <p className='p3'>
                <strong>Contact Us</strong>
            </p>
            <p className='p3'>
                If you have any questions about these Terms and Conditions, You
                can contact us:
            </p>
            <p className='p3'>
                By email:{' '}
                <span style={{ color: 'blue', textDecoration: 'underline' }}>
                    team@safetravy.com
                </span>
            </p>
            <p className='p3'>
                By phone: <a href='tel:+350 54095364'>+350 54095364</a>
            </p>

            <div
                id='gtx-trans'
                style={{ position: 'absolute', left: -4, top: -17 }}>
                &nbsp;
            </div>
        </>
    ),
    [Languages.FR]: (
        <>
            <p className='p1'>
                <strong>Conditions g&eacute;n&eacute;rales de SafeTravy</strong>
            </p>
            <p className='p3'>
                Les pr&eacute;sentes conditions g&eacute;n&eacute;rales (les
                "conditions" ou le "contrat") r&eacute;gissent l'utilisation de
                la plateforme &eacute;lectronique, y compris tout site web ou
                application mobile (l'"application", conjointement avec le site
                web, le "site") permettant d'acc&eacute;der &agrave; la
                plateforme, et tout service fourni par l'interm&eacute;diaire de
                la plateforme (collectivement, la "plateforme") fournie par
                SafeTravy Limited (la "soci&eacute;t&eacute;", "nous", "notre"
                ou "nos").<span className='Apple-converted-space'>&nbsp; </span>
                Les Conditions constituent un accord contraignant entre la
                Soci&eacute;t&eacute; et vous, en tant qu'utilisateur individuel
                (" vous ", " votre " ou " Utilisateur ") pour votre utilisation
                individuelle de l'Application et de la Plateforme. En vous
                inscrivant, en t&eacute;l&eacute;chargeant l'application et en
                utilisant la plate-forme, vous confirmez votre acceptation du
                pr&eacute;sent accord et de notre politique de
                confidentialit&eacute; associ&eacute;e.
                <span className='Apple-converted-space'>&nbsp; </span>Si vous
                n'acceptez pas ces conditions, vous devez imm&eacute;diatement
                d&eacute;sinstaller l'application et cesser d'utiliser
                l'application et la plate-forme.
            </p>
            <p className='p3'>
                Avis de non-responsabilit&eacute; concernant les titres : aucun
                document ni aucune autre information pouvant &ecirc;tre mis
                &agrave; disposition sur le site ou la plate-forme ne constitue
                ou ne peut &ecirc;tre interpr&eacute;t&eacute; comme une
                recommandation, une approbation, une offre, une invitation ou
                une sollicitation &agrave; prendre une quelconque
                d&eacute;cision, toutes les d&eacute;cisions &eacute;tant prises
                en fonction de votre propre volont&eacute; et de votre
                exp&eacute;rience. Vous comprenez en outre qu'aucun des
                fournisseurs d'informations, y compris les fournisseurs tiers
                (tels que d&eacute;finis ci-dessous), ne vous conseille
                personnellement sur la nature, le potentiel, la valeur ou
                l'ad&eacute;quation de toute question de s&eacute;curit&eacute;
                particuli&egrave;re concernant vos voyages. Veuillez noter que
                la situation dans le monde &eacute;volue rapidement, de sorte
                que toute information, tout contenu, tout contenu de tiers (tel
                que d&eacute;fini ci-dessous) ou tout autre mat&eacute;riel
                fourni sur ou par l'interm&eacute;diaire du site ou de la
                plate-forme peut ne pas &ecirc;tre complet ou actuel, ou peut
                &ecirc;tre remplac&eacute; par des informations plus
                r&eacute;centes. Vous vous fiez &agrave; ces informations
                &agrave; vos propres risques.
            </p>
            <p className='p3'>
                Notre Site et notre Plateforme ne sont pas destin&eacute;s
                &agrave; fournir des conseils juridiques, d'assurance ou
                d'investissement, et rien sur le Site ou la Plateforme ne doit
                &ecirc;tre interpr&eacute;t&eacute; comme une offre sur la
                fa&ccedil;on d'organiser votre voyage. Toutes ces informations
                ne sont que des recommandations personnalis&eacute;es.
            </p>
            <p className='p3'>
                <strong>1. L'applicabilit&eacute;</strong>
            </p>
            <p className='p3'>
                1.1. Nous, SafeTravy Limited (ci-apr&egrave;s " SafeTravy "),
                offrons des services dans le domaine du divertissement
                informatique. Les pr&eacute;sentes conditions
                g&eacute;n&eacute;rales s'appliquent &agrave; la relation
                contractuelle entre SafeTravy et le client (" Client " ou " Vous
                ") en ce qui concerne tous les services que nous fournissons.
            </p>
            <p className='p3'>
                <strong>2. D&eacute;finitions</strong>
            </p>
            <p className='p3'>
                <strong>Compte </strong>d&eacute;signe le compte &eacute;tabli
                par un utilisateur qui a t&eacute;l&eacute;charg&eacute;
                l'application ou acc&eacute;d&eacute; au site et s'est inscrit
                aupr&egrave;s de la soci&eacute;t&eacute; pour utiliser le site
                et la plateforme.
            </p>
            <p className='p3'>
                <strong>App </strong>d&eacute;signe l'application mobile fournie
                par la Soci&eacute;t&eacute; pour acc&eacute;der &agrave; la
                Plateforme.
            </p>
            <p className='p3'>
                <strong>Personne autoris&eacute;e </strong>d&eacute;signe toute
                personne autoris&eacute;e &agrave; acc&eacute;der et &agrave;
                utiliser le Site (y compris l'App) et la Plateforme au nom d'un
                Utilisateur.
            </p>
            <p className='p3'>
                Les <strong>plateformes num&eacute;riques </strong>
                d&eacute;signent les plateformes de distribution tierces
                auxquelles il est possible d'acc&eacute;der ou de
                t&eacute;l&eacute;charger des applications mobiles ou d'autres
                programmes logiciels, y compris, mais sans s'y limiter, l'App
                Store d'Apple et Google Play.
            </p>
            <p className='p3'>
                <strong>Autorit&eacute; gouvernementale </strong>: toute nation
                ou tout gouvernement, toute province ou tout &Eacute;tat ou
                toute autre subdivision politique de ceux-ci, ou toute
                entit&eacute;, autorit&eacute; ou organisme exer&ccedil;ant des
                fonctions ex&eacute;cutives, l&eacute;gislatives, judiciaires,
                r&eacute;glementaires ou administratives, y compris toute
                autorit&eacute; gouvernementale, agence, d&eacute;partement,
                conseil, commission ou instrument, ou toute subdivision
                politique de ceux-ci, toute cour, tout tribunal ou tout arbitre,
                et tout organisme d'autor&eacute;glementation.
            </p>
            <p className='p3'>
                <strong>Mat&eacute;riel </strong>d&eacute;signe tout
                mat&eacute;riel d'offre, fiche technique, rapport de recherche,
                documentation de produit ou de service ou toute autre
                information fournie par l'interm&eacute;diaire de la Plateforme.
            </p>
            <p className='p3'>
                Les <strong>informations personnelles </strong>d&eacute;signent
                les informations fournies par un utilisateur &agrave; partir
                desquelles l'identit&eacute; de cet utilisateur peut &ecirc;tre
                directement ou indirectement d&eacute;termin&eacute;e.
            </p>
            <p className='p3'>
                <strong>Politique de confidentialit&eacute; </strong>
                d&eacute;signe les conditions suppl&eacute;mentaires
                r&eacute;gissant la collecte, l'utilisation et la divulgation
                des informations personnelles de chaque utilisateur, comme
                indiqu&eacute; ici. Chaque Utilisateur doit lire et accepter la
                Politique de confidentialit&eacute; afin d'utiliser
                l'Application ou le Site.
            </p>
            <p className='p3'>
                Les <strong>notifications de service </strong>sont des
                notifications unidirectionnelles de la Soci&eacute;t&eacute;
                (qui peuvent inclure des notifications li&eacute;es &agrave; la
                s&eacute;curit&eacute;) par le biais de messages texte ou
                d'e-mails et, le cas &eacute;ch&eacute;ant, de notifications
                push par le biais du Site.
                <span className='Apple-converted-space'>&nbsp; </span>Ces
                notifications sont envoy&eacute;es &agrave; l'Utilisateur en ce
                qui concerne certaines informations ou &eacute;v&eacute;nements
                relatifs &agrave; un compte auquel un Utilisateur a acc&egrave;s
                par le biais de la Plateforme.
            </p>
            <p className='p3'>
                L'<strong>utilisateur </strong>d&eacute;signe toute personne qui
                s'est inscrite aupr&egrave;s de la soci&eacute;t&eacute; pour
                utiliser le site et acc&eacute;der &agrave; la plate-forme,
                ainsi que toute personne autoris&eacute;e agissant en son nom.
            </p>
            <p className='p3'>
                Les <strong>r&eacute;f&eacute;rences de l'utilisateur </strong>
                d&eacute;signent l'ensemble des identifiants, mots de passe,
                num&eacute;ros d'identification personnels, jetons et toute
                autre information ou dispositif fournis &agrave; un utilisateur
                pour acc&eacute;der &agrave; la plate-forme.
            </p>
            <p className='p3'>
                La <strong>Soci&eacute;t&eacute; </strong>
                (d&eacute;sign&eacute;e par " la Soci&eacute;t&eacute; ", " nous
                ", " notre " ou " nos " dans le pr&eacute;sent contrat) est
                SAFETRAVY LIMITED, Suite 4, 2nd floor, the West Wing, Montarik
                House, 3 Bedlam Court, Gibraltar, GX11 1AA, GIBRALTAR,
                GIBRALTAR.
            </p>
            <p className='p3'>
                <strong>Appareil </strong>: tout appareil pouvant acc&eacute;der
                au Service, tel qu'un ordinateur, un t&eacute;l&eacute;phone
                portable ou une tablette num&eacute;rique.
            </p>
            <p className='p3'>
                L'<strong>essai gratuit </strong>fait r&eacute;f&eacute;rence
                &agrave; une p&eacute;riode de temps limit&eacute;e qui peut
                &ecirc;tre gratuite lors de l'achat d'un abonnement.
            </p>
            <p className='p3'>
                Le <strong>service </strong>fait r&eacute;f&eacute;rence
                &agrave; toutes les informations, recommandations et
                notifications fournies par le site web.
            </p>
            <p className='p3'>
                Les <strong>abonnements </strong>font r&eacute;f&eacute;rence
                aux services ou &agrave; l'acc&egrave;s au Service
                propos&eacute;s par la Soci&eacute;t&eacute; sur la base d'un
                abonnement.
            </p>
            <p className='p3'>
                Les conditions <strong>g&eacute;n&eacute;rales </strong>
                (&eacute;galement appel&eacute;es "conditions") sont les
                pr&eacute;sentes conditions g&eacute;n&eacute;rales qui
                constituent l'int&eacute;gralit&eacute; de l'accord entre vous
                et la soci&eacute;t&eacute; concernant l'utilisation du service.
            </p>
            <p className='p3'>
                Service <strong>tiers </strong>: tout service ou contenu (y
                compris les donn&eacute;es, informations, produits ou services)
                fourni par un tiers qui peut &ecirc;tre affich&eacute;, inclus
                ou mis &agrave; disposition par le service.
            </p>
            <p className='p3'>
                Le <strong>site web </strong>fait r&eacute;f&eacute;rence
                &agrave; SafeTravy, accessible &agrave; partir de
                www.safetravy.com
            </p>
            <p className='p3'>
                <strong>Changements</strong>
            </p>
            <p className='p3'>
                Nous nous r&eacute;servons le droit, &agrave; tout moment, de :
            </p>
            <p className='p3'>
                (a) modifier, mettre &agrave; jour ou changer les termes et
                conditions du pr&eacute;sent accord ou de notre politique en
                mati&egrave;re de protection de la vie priv&eacute;e ;
            </p>
            <p className='p3'>
                (b) modifier, mettre &agrave; jour ou changer le Site et la
                Plateforme, y compris &eacute;liminer ou interrompre tout
                contenu ou fonctionnalit&eacute; du Site ou de la Plateforme
                (tout ce qui pr&eacute;c&egrave;de &eacute;tant
                d&eacute;sign&eacute; par le terme "Changements").
            </p>
            <p className='p3'>
                Nous pouvons apporter de telles modifications &agrave; tout
                moment sans pr&eacute;avis.
                <span className='Apple-converted-space'>&nbsp; </span>Toute
                modification du pr&eacute;sent accord peut &ecirc;tre
                publi&eacute;e sur notre site web ou vous &ecirc;tre
                notifi&eacute;e par le biais de notifications push sur le site
                ou d'un courriel envoy&eacute; &agrave; l'adresse
                &eacute;lectronique figurant dans votre compte. C'est pourquoi
                vous devez consulter r&eacute;guli&egrave;rement notre site web,
                autoriser le site &agrave; recevoir de telles notifications push
                et tenir &agrave; jour votre adresse &eacute;lectronique et vos
                autres informations de contact dans le compte. Vous acceptez
                toute modification si vous continuez &agrave; utiliser le Site
                et la Plateforme apr&egrave;s l'entr&eacute;e en vigueur de ces
                modifications.
            </p>
            <p className='p3'>
                <strong>
                    Conditions d'utilisation de la plateforme num&eacute;rique
                </strong>
            </p>
            <p className='p3'>
                L'application peut &ecirc;tre disponible au
                t&eacute;l&eacute;chargement &agrave; partir d'une ou plusieurs
                plateformes num&eacute;riques. Le t&eacute;l&eacute;chargement,
                l'installation, l'acc&egrave;s ou l'utilisation de l'application
                sont &eacute;galement soumis aux conditions
                g&eacute;n&eacute;rales et aux politiques de
                confidentialit&eacute; de la plateforme num&eacute;rique
                concern&eacute;e (les "conditions de la plateforme
                num&eacute;rique"). En cas de conflit entre les pr&eacute;sentes
                conditions et les conditions de la plateforme num&eacute;rique,
                les pr&eacute;sentes conditions pr&eacute;vaudront.
            </p>
            <p className='p3'>
                L'application est ind&eacute;pendante et n'est pas
                associ&eacute;e, affili&eacute;e, parrain&eacute;e,
                approuv&eacute;e ou li&eacute;e de quelque mani&egrave;re que ce
                soit &agrave; une Plateforme num&eacute;rique. Vous et nous
                reconnaissons que le pr&eacute;sent Accord est conclu entre vous
                et nous uniquement, et non avec une Plateforme num&eacute;rique,
                et que nous, et non la Plateforme num&eacute;rique, sommes seuls
                responsables de l'application et de son contenu dans la mesure
                sp&eacute;cifi&eacute;e dans le pr&eacute;sent Accord.
            </p>
            <p className='p3'>
                Vous et nous reconnaissons et acceptons que la Plate-forme
                num&eacute;rique concern&eacute;e et les filiales de cette
                Plate-forme num&eacute;rique sont des tiers
                b&eacute;n&eacute;ficiaires des pr&eacute;sentes Conditions et
                que, d&egrave;s votre acceptation des pr&eacute;sentes
                Conditions, cette Plate-forme num&eacute;rique aura le droit (et
                sera r&eacute;put&eacute;e avoir accept&eacute; le droit) de
                faire appliquer les pr&eacute;sentes Conditions &agrave; votre
                encontre en tant que tiers b&eacute;n&eacute;ficiaire de
                celles-ci.
            </p>
            <p className='p3'>
                <strong>
                    Exigences en mati&egrave;re de dispositifs de r&eacute;seau
                    et d'op&eacute;rateurs
                </strong>
            </p>
            <p className='p3'>
                Vous reconnaissez que votre accord avec votre fournisseur de
                r&eacute;seau mobile et Internet (le "fournisseur de
                r&eacute;seau") s'appliquera &agrave; votre utilisation du site.
                Vous reconnaissez que votre fournisseur de r&eacute;seau peut
                vous facturer des services de donn&eacute;es lors de
                l'utilisation de certaines fonctionnalit&eacute;s du site ou
                toute autre charge de tiers qui pourrait survenir, et vous
                acceptez la responsabilit&eacute; exclusive de ces charges. Si
                vous n'&ecirc;tes pas le payeur de la facture de l'appareil
                mobile/Internet utilis&eacute; pour acc&eacute;der au site, vous
                serez suppos&eacute; avoir re&ccedil;u l'autorisation du payeur
                de la facture pour utiliser le site. Vous devez &eacute;galement
                vous assurer que votre utilisation du site n'est pas en
                violation du contrat de votre appareil mobile ou Internet ou de
                tout contrat de service de donn&eacute;es sans fil.
            </p>
            <p className='p3'>
                <strong>Admissibilit&eacute; et inscription</strong>
            </p>
            <p className='p3'>
                Vous devez &ecirc;tre &acirc;g&eacute; d'au moins 18 ans pour
                acc&eacute;der au site et &agrave; la plateforme et les
                utiliser. Vous affirmez en outre que vous &ecirc;tes pleinement
                capable et comp&eacute;tent pour conclure les termes,
                conditions, obligations, affirmations, repr&eacute;sentations et
                garanties &eacute;nonc&eacute;s dans les pr&eacute;sentes
                Conditions, et pour respecter et se conformer aux
                pr&eacute;sentes Conditions. Vous devez vous inscrire
                aupr&egrave;s de la Soci&eacute;t&eacute; pour utiliser le Site
                et la Plateforme ; vous acceptez de fournir des informations
                compl&egrave;tes et exactes lorsque vous vous inscrivez pour
                utiliser le Site et la Plateforme, et de maintenir ces
                informations &agrave; jour.
            </p>
            <p className='p3'>
                Nous avons le pouvoir discr&eacute;tionnaire d'accepter ou de
                refuser votre inscription &agrave; la Plateforme. Seuls les
                utilisateurs dont l'inscription est approuv&eacute;e par nous
                seront nos clients.
            </p>
            <p className='p3'>
                <strong>Remerciements</strong>
            </p>
            <p className='p3'>
                Les pr&eacute;sentes conditions g&eacute;n&eacute;rales
                r&eacute;gissent l'utilisation de ce service et constituent
                l'accord conclu entre vous et la soci&eacute;t&eacute;. Ces
                conditions d&eacute;finissent les droits et obligations de tous
                les utilisateurs en ce qui concerne l'utilisation du service.
            </p>
            <p className='p3'>
                Votre acc&egrave;s au service et son utilisation sont
                subordonn&eacute;s &agrave; votre acceptation et au respect des
                pr&eacute;sentes conditions g&eacute;n&eacute;rales. Ces
                conditions s'appliquent &agrave; tous les visiteurs,
                utilisateurs et autres personnes qui acc&egrave;dent au service
                ou l'utilisent.
            </p>
            <p className='p3'>
                En acc&eacute;dant au service ou en l'utilisant, vous acceptez
                d'&ecirc;tre li&eacute; par les pr&eacute;sentes conditions
                g&eacute;n&eacute;rales. Si vous n'&ecirc;tes pas d'accord avec
                une partie de ces conditions, vous ne pouvez pas acc&eacute;der
                au service.
            </p>
            <p className='p3'>
                Vous d&eacute;clarez &ecirc;tre &acirc;g&eacute; de plus de 18
                ans. La soci&eacute;t&eacute; n'autorise pas les personnes de
                moins de 18 ans &agrave; utiliser le service.
            </p>
            <p className='p3'>
                Votre acc&egrave;s et votre utilisation du Service sont
                &eacute;galement conditionn&eacute;s par votre acceptation et
                votre respect de la Politique de confidentialit&eacute; de la
                Soci&eacute;t&eacute;. Notre politique de confidentialit&eacute;
                d&eacute;crit nos politiques et proc&eacute;dures en
                mati&egrave;re de collecte, d'utilisation et de divulgation de
                vos informations personnelles lorsque vous utilisez
                l'application ou le site Web et vous informe de vos droits en
                mati&egrave;re de confidentialit&eacute; et de la mani&egrave;re
                dont la loi vous prot&egrave;ge. Veuillez lire attentivement
                notre politique de confidentialit&eacute; avant d'utiliser notre
                service.
            </p>
            <p className='p3'>
                <strong>Abonnements</strong>
            </p>
            <p className='p3'>
                <strong>P&eacute;riode d'abonnement</strong>
            </p>
            <p className='p3'>
                Le service ou certaines parties du service ne sont disponibles
                que moyennant un abonnement payant. Vous serez factur&eacute;
                &agrave; l'avance sur une base r&eacute;currente et
                p&eacute;riodique (quotidienne, hebdomadaire, mensuelle ou
                annuelle), en fonction du type de plan d'abonnement que vous
                aurez choisi lors de l'achat de l'abonnement.
            </p>
            <p className='p3'>
                A la fin de chaque p&eacute;riode, votre abonnement sera
                automatiquement renouvel&eacute; dans les m&ecirc;mes
                conditions, &agrave; moins que vous ne l'annuliez ou que la
                Soci&eacute;t&eacute; ne l'annule.
            </p>
            <p className='p3'>
                <strong>R&eacute;siliation d'abonnement</strong>
            </p>
            <p className='p3'>
                Vous pouvez annuler le renouvellement de votre abonnement soit
                par le biais de la page des param&egrave;tres de votre compte,
                soit en contactant la Soci&eacute;t&eacute;. Vous ne recevrez
                pas de remboursement pour les frais que vous avez
                d&eacute;j&agrave; pay&eacute;s pour la p&eacute;riode
                d'abonnement en cours et vous pourrez acc&eacute;der au service
                jusqu'&agrave; la fin de la p&eacute;riode d'abonnement en
                cours.
            </p>
            <p className='p3'>
                <strong>Facturation</strong>
            </p>
            <p className='p3'>
                Vous devez fournir &agrave; la Soci&eacute;t&eacute; des
                informations de facturation exactes et compl&egrave;tes, y
                compris le nom complet, l'adresse, l'&eacute;tat, et des
                informations valides sur la m&eacute;thode de paiement.
            </p>
            <p className='p3'>
                Si, pour une raison quelconque, la facturation automatique n'a
                pas lieu, l'Entreprise &eacute;mettra une facture
                &eacute;lectronique indiquant que vous devez proc&eacute;der
                manuellement, dans un certain d&eacute;lai, au paiement
                int&eacute;gral correspondant &agrave; la p&eacute;riode de
                facturation telle qu'indiqu&eacute;e sur la facture.
            </p>
            <p className='p3'>
                <strong>Modifications de la redevance</strong>
            </p>
            <p className='p3'>
                La Soci&eacute;t&eacute; peut, &agrave; sa seule
                discr&eacute;tion et &agrave; tout moment, modifier les frais
                d'abonnement. Toute modification des frais d'abonnement entrera
                en vigueur &agrave; la fin de la p&eacute;riode d'abonnement en
                cours.
            </p>
            <p className='p3'>
                La Soci&eacute;t&eacute; vous donnera un pr&eacute;avis
                raisonnable de toute modification des frais d'abonnement afin de
                vous donner la possibilit&eacute; de r&eacute;silier votre
                abonnement avant que cette modification n'entre en vigueur.
            </p>
            <p className='p3'>
                En continuant &agrave; utiliser le Service apr&egrave;s
                l'entr&eacute;e en vigueur de la modification des frais
                d'abonnement, vous acceptez de payer le montant modifi&eacute;
                des frais d'abonnement.
            </p>
            <p className='p3'>
                <strong>Remboursements</strong>
            </p>
            <p className='p3'>
                Sauf si la loi l'exige, les frais d'abonnement pay&eacute;s ne
                sont pas remboursables.
            </p>
            <p className='p3'>
                Certaines demandes de remboursement d'abonnements peuvent
                &ecirc;tre examin&eacute;es par la Soci&eacute;t&eacute; au cas
                par cas et accord&eacute;es &agrave; la seule discr&eacute;tion
                de la Soci&eacute;t&eacute;.
            </p>
            <p className='p3'>
                <strong>Essai gratuit</strong>
            </p>
            <p className='p3'>
                La Soci&eacute;t&eacute; peut, &agrave; sa seule
                discr&eacute;tion, proposer un abonnement avec un essai gratuit
                pour une p&eacute;riode limit&eacute;e.
            </p>
            <p className='p3'>
                Il peut vous &ecirc;tre demand&eacute; de saisir vos
                informations de facturation pour vous inscrire &agrave; l'essai
                gratuit.
            </p>
            <p className='p3'>
                Si vous saisissez vos informations de facturation lors de
                l'inscription &agrave; un essai gratuit, vous ne serez pas
                factur&eacute; par la Soci&eacute;t&eacute; tant que l'essai
                gratuit n'aura pas expir&eacute;. Le dernier jour de la
                p&eacute;riode d'essai gratuit, &agrave; moins que vous
                n'annuliez votre abonnement, vous serez automatiquement
                factur&eacute; des frais d'abonnement applicables pour le type
                d'abonnement que vous avez choisi.
            </p>
            <p className='p3'>
                A tout moment et sans pr&eacute;avis, la Soci&eacute;t&eacute;
                se r&eacute;serve le droit de (i) modifier les termes et
                conditions de l'offre d'essai gratuit, ou (ii) d'annuler cette
                offre d'essai gratuit.
            </p>
            <p className='p3'>
                <strong>Comptes d'utilisateurs</strong>
            </p>
            <p className='p3'>
                Afin d'utiliser les services de la Plateforme, vous devez
                cr&eacute;er un compte sur la Plateforme (le "Compte").
                <span className='Apple-converted-space'>&nbsp; </span>Le compte
                peut &ecirc;tre enregistr&eacute; par toute personne physique
                &acirc;g&eacute;e de plus de 18 ans.
            </p>
            <p className='p3'>
                Lorsque vous cr&eacute;ez un compte chez nous, vous devez nous
                fournir des informations exactes, compl&egrave;tes et &agrave;
                jour &agrave; tout moment. Le non-respect de cette obligation
                constitue une violation des Conditions, qui peut entra&icirc;ner
                la r&eacute;siliation imm&eacute;diate de votre compte sur notre
                Service.
            </p>
            <p className='p3'>
                Vous &ecirc;tes responsable de la protection du mot de passe que
                vous utilisez pour acc&eacute;der au service et de toutes les
                activit&eacute;s ou actions effectu&eacute;es avec votre mot de
                passe, que celui-ci soit li&eacute; &agrave; notre service ou
                &agrave; un service tiers.
            </p>
            <p className='p3'>
                Vous acceptez de ne pas divulguer votre mot de passe &agrave; un
                tiers. Vous devez nous informer imm&eacute;diatement d&egrave;s
                que vous avez connaissance d'une violation de la
                s&eacute;curit&eacute; ou d'une utilisation non autoris&eacute;e
                de votre compte. Vous ne pouvez acc&eacute;der &agrave; votre
                compte qu'&agrave; partir d'un seul appareil.
            </p>
            <p className='p3'>
                Vous ne pouvez pas utiliser comme nom d'utilisateur le nom d'une
                autre personne ou entit&eacute; ou un nom qui n'est pas
                l&eacute;galement disponible pour l'utilisation, un nom ou une
                marque commerciale qui est soumis aux droits d'une autre
                personne ou entit&eacute; que vous sans autorisation
                appropri&eacute;e, ou un nom qui est autrement offensant,
                vulgaire ou obsc&egrave;ne.
            </p>
            <p className='p3'>
                Chaque utilisateur reconna&icirc;t que chaque s&eacute;rie
                d'identifiants est incessible et ne peut &ecirc;tre
                utilis&eacute;e que par l'utilisateur auquel elle a
                &eacute;t&eacute; d&eacute;livr&eacute;e. Ces justificatifs
                d'identit&eacute; ne peuvent &ecirc;tre divulgu&eacute;s ou
                transf&eacute;r&eacute;s &agrave; une tierce personne sans
                l'autorisation &eacute;crite de la Soci&eacute;t&eacute;. Nous
                ne vous demanderons jamais, pour quelque raison que ce soit, que
                ce soit par courrier &eacute;lectronique, par courrier postal ou
                par t&eacute;l&eacute;phone, de divulguer vos informations
                d'identification de l'utilisateur. Les demandes de mot de passe
                ne seront effectu&eacute;es qu'en ligne et seulement
                apr&egrave;s que vous vous soyez connect&eacute; &agrave; la
                plate-forme. Nous ne vous enverrons jamais de liens
                int&eacute;gr&eacute;s dans un courrier &eacute;lectronique vous
                demandant de vous connecter &agrave; la plate-forme en cliquant
                sur un tel lien. Si vous recevez un lien int&eacute;gr&eacute;
                par courrier &eacute;lectronique, pr&eacute;tendant provenir de
                nous ou de la Plateforme, vous ne devez pas ouvrir ou cliquer
                sur le lien.
                <span className='Apple-converted-space'>&nbsp; </span>
                L'e-mail ne vient pas de nous et est probablement frauduleux.
            </p>
            <p className='p3'>
                <strong>Personnes autoris&eacute;es</strong>
            </p>
            <p className='p3'>
                Chaque utilisateur et chaque personne autoris&eacute;e
                reconna&icirc;t avoir re&ccedil;u et accept&eacute; les
                pr&eacute;sentes conditions.
                <span className='Apple-converted-space'>&nbsp; </span>Lorsqu'il
                demande &agrave; une personne autoris&eacute;e d'acc&eacute;der
                &agrave; la plate-forme et d'ouvrir un compte en son nom,
                l'utilisateur reconna&icirc;t et d&eacute;clare que la personne
                autoris&eacute;e est d&ucirc;ment habilit&eacute;e (i) &agrave;
                acc&eacute;der &agrave; la plate-forme et &agrave; l'utiliser en
                son nom et, le cas &eacute;ch&eacute;ant, &agrave; exercer les
                m&ecirc;mes pouvoirs que ceux conf&eacute;r&eacute;s par
                l'utilisateur &agrave; la personne autoris&eacute;e
                conform&eacute;ment &agrave; toute procuration sous-jacente,
                dans la mesure o&ugrave; cela est techniquement possible, et que
                les services offerts dans le cadre de la plate-forme sont
                analogues aux services que l'utilisateur peut utiliser par
                d'autres voies ; (ii) accepter toute modification des
                pr&eacute;sentes conditions au nom de l'utilisateur ; et (iii)
                demander ou souscrire &agrave; tout service de la plate-forme
                qui n&eacute;cessite une demande ou une souscription distincte.
            </p>
            <p className='p3'>
                Chaque utilisateur doit veiller &agrave; ce que chaque personne
                autoris&eacute;e agissant en son nom soit inform&eacute;e des
                pr&eacute;sentes conditions et, le cas &eacute;ch&eacute;ant,
                des conditions du fournisseur de services tiers, qu'elle les
                accepte et s'y conforme. Vous &ecirc;tes enti&egrave;rement
                responsable de tous les actes, omissions ou manquements de la
                personne autoris&eacute;e que vous avez d&eacute;sign&eacute;e
                dans le cadre de l'acc&egrave;s et de l'utilisation de la
                plate-forme et de toute transaction effectu&eacute;e par
                l'interm&eacute;diaire de votre compte.
            </p>
            <p className='p3'>
                Vous d&eacute;clarez, vous vous engagez et vous confirmez que
                vous avez obtenu le consentement de vos personnes
                autoris&eacute;es pour la collecte, l'utilisation, le transfert,
                la divulgation et le traitement des informations personnelles de
                ces personnes autoris&eacute;es conform&eacute;ment aux
                pr&eacute;sentes conditions et &agrave; la politique de
                protection de la vie priv&eacute;e.
            </p>
            <p className='p3'>
                Vous comprenez et acceptez que les informations et les services
                fournis par la Plateforme ne sont pas fournis &agrave;, et ne
                peuvent pas &ecirc;tre utilis&eacute;s par ou pour le
                b&eacute;n&eacute;fice de, tout individu ou entit&eacute; dans
                toute juridiction o&ugrave; la fourniture ou l'utilisation de
                ceux-ci serait contraire &agrave; toute loi applicable, ou
                o&ugrave; nous ne sommes pas autoris&eacute;s &agrave; fournir
                une telle Plateforme ou des informations et des services.
            </p>
            <p className='p3'>
                <strong>Propri&eacute;t&eacute; intellectuelle</strong>
            </p>
            <p className='p3'>
                Tous les titres, droits de propri&eacute;t&eacute; et droits de
                propri&eacute;t&eacute; intellectuelle relatifs au site et
                &agrave; la plate-forme, &agrave; toute information transmise
                par la plate-forme, &agrave; destination de celle-ci ou par son
                interm&eacute;diaire, ainsi que les informations relatives
                &agrave; l'utilisation de la plate-forme, restent la
                propri&eacute;t&eacute; de la soci&eacute;t&eacute; ou de ses
                conc&eacute;dants de licence. Rien sur la plate-forme ne peut
                &ecirc;tre interpr&eacute;t&eacute; comme conf&eacute;rant
                &agrave; un utilisateur une licence, sauf dans les cas
                express&eacute;ment pr&eacute;vus dans les pr&eacute;sentes, des
                titres, droits de propri&eacute;t&eacute; et/ou droits de
                propri&eacute;t&eacute; intellectuelle de la
                soci&eacute;t&eacute; ou d'un tiers, que ce soit par
                pr&eacute;clusion, implication ou autre.
            </p>
            <p className='p3'>
                La Plateforme et l'App peuvent vous donner acc&egrave;s &agrave;
                du contenu, des informations, des citations, des vid&eacute;os,
                des photos ou d'autres mat&eacute;riaux (le "Contenu Tiers")
                fournis par certains tiers (les "Fournisseurs de Contenu
                Tiers"). La Soci&eacute;t&eacute; n'approuve ni ne recommande,
                et n'est pas responsable de la v&eacute;rification de
                l'exactitude, de la validit&eacute; ou de l'exhaustivit&eacute;
                de tout contenu tiers fourni par l'interm&eacute;diaire du Site
                ou de la Plateforme.{' '}
                <span className='Apple-converted-space'>&nbsp; </span>
                L'utilisation ou la confiance que vous accordez &agrave; ce
                contenu tiers est &agrave; vos risques et p&eacute;rils. Tous
                les titres, droits de propri&eacute;t&eacute; et droits de
                propri&eacute;t&eacute; intellectuelle relatifs au contenu de
                tiers restent la propri&eacute;t&eacute; du fournisseur de
                contenu de tiers concern&eacute;. Rien sur la plate-forme ne
                peut &ecirc;tre interpr&eacute;t&eacute; comme conf&eacute;rant
                &agrave; un utilisateur une licence, sauf dans les cas
                express&eacute;ment pr&eacute;vus par les pr&eacute;sentes, sur
                les titres, droits de propri&eacute;t&eacute; et/ou droits de
                propri&eacute;t&eacute; intellectuelle d'un fournisseur de
                contenu tiers, que ce soit par pr&eacute;clusion, implication ou
                autre.
            </p>
            <p className='p3'>
                Si vous &ecirc;tes en conformit&eacute; avec les
                pr&eacute;sentes conditions, vous pouvez
                t&eacute;l&eacute;charger et acc&eacute;der au site sur un seul
                appareil mobile et acc&eacute;der &agrave; la plate-forme en
                utilisant les justificatifs d'identit&eacute; de l'utilisateur
                correctement &eacute;mis. Tous les autres droits sur le site
                sont r&eacute;serv&eacute;s par la soci&eacute;t&eacute;.
                <span className='Apple-converted-space'>&nbsp; </span>En cas de
                violation des pr&eacute;sentes conditions, nous serons en droit
                de mettre fin imm&eacute;diatement &agrave; votre utilisation et
                &agrave; votre acc&egrave;s au site et &agrave; la plate-forme.
            </p>
            <p className='p3'>Vous vous engagez &agrave; ne pas :</p>
            <p className='p3'>
                (a) modifier, adapter, reproduire, traduire ou cr&eacute;er des
                &oelig;uvres d&eacute;riv&eacute;es du site ou de la
                plate-forme, ou de toute donn&eacute;e ou contenu (y compris le
                contenu de tiers) fourni par l'interm&eacute;diaire du site ou
                de la plate-forme, ou de toute partie de ceux-ci, ou tenter de
                faire de l'ing&eacute;nierie inverse, de d&eacute;compiler, de
                d&eacute;sassembler ou de tenter de toute autre mani&egrave;re
                de d&eacute;couvrir le code source du site ou de la plate-forme
                ;
            </p>
            <p className='p3'>
                (b) supprimer tout avis de droit d'auteur, marque,
                l&eacute;gende, logo ou identification de produit du site ou de
                la plate-forme ;
            </p>
            <p className='p3'>
                (c) faire passer les autres sites pour le site de la
                Soci&eacute;t&eacute; en cooptant l'aspect visuel ou le texte du
                site de la Soci&eacute;t&eacute; ou violer de toute autre
                mani&egrave;re les droits de propri&eacute;t&eacute;
                intellectuelle de la Soci&eacute;t&eacute;, y compris, sans s'y
                limiter, en "r&eacute;cup&eacute;rant" du texte ou des images du
                site de la Soci&eacute;t&eacute; ou des banni&egrave;res et/ou
                des liens textuels g&eacute;r&eacute;s par la
                Soci&eacute;t&eacute;, en faisant du marketing de recherche ou
                toute autre campagne en ligne et hors ligne,
            </p>
            <p className='p3'>
                (d) &eacute;diter, modifier, filtrer, tronquer ou changer
                l'ordre des informations contenues dans toute partie des sites
                de la soci&eacute;t&eacute;, ou supprimer, obscurcir ou
                minimiser toute partie du site de la soci&eacute;t&eacute; de
                quelque mani&egrave;re que ce soit sans l'autorisation de la
                soci&eacute;t&eacute; ; ou
            </p>
            <p className='p3'>
                (e) faire un usage commercial du Site ou de la Plateforme ou du
                logo, de la marque ou du nom de marque de la
                Soci&eacute;t&eacute; de quelque mani&egrave;re que ce soit.
            </p>
            <p className='p3'>
                Chaque Utilisateur autorise la Soci&eacute;t&eacute; &agrave;
                utiliser toute information ou contenu fourni par l'Utilisateur
                ou trait&eacute; dans le cadre de l'utilisation du Site et de la
                Plateforme (par exemple, les Donn&eacute;es personnelles, les
                informations g&eacute;ographiques, les informations sur les
                appareils) dans le contexte et dans le but de fournir des
                services ou des produits sur la Plateforme et de
                s&eacute;curiser l'utilisation du Site et de la Plateforme.
            </p>
            <p className='p3'>
                <strong>Fourniture de mat&eacute;riel et d'informations</strong>
            </p>
            <p className='p3'>
                Vous reconnaissez que ni la Soci&eacute;t&eacute; ni la
                Plateforme ne sont vos conseillers en voyage ou votre
                fiduciaire. Vous reconnaissez en outre qu'aucun des documents
                que nous fournissons ou qui sont mis &agrave; disposition sur la
                plateforme ne constitue une recommandation ou une sollicitation
                de notre part vous incitant &agrave; prendre une d&eacute;cision
                particuli&egrave;re au cours de votre voyage.
            </p>
            <p className='p3'>
                Vous reconnaissez que nous n'avons aucun devoir ou obligation de
                v&eacute;rifier, corriger, compl&eacute;ter ou mettre &agrave;
                jour tout mat&eacute;riel affich&eacute; sur la Plateforme. Le
                mat&eacute;riel, y compris, mais sans s'y limiter, les nouvelles
                et les recherches, peut &ecirc;tre pr&eacute;par&eacute; par des
                fournisseurs d'informations qui sont ind&eacute;pendants de
                nous. Nous ne garantissons pas que le mat&eacute;riel sera
                exact, complet ou mis &agrave; jour en temps utile. Vous devez
                effectuer des recherches et des analyses plus approfondies ou
                consulter un conseiller en voyages avant de prendre des
                d&eacute;cisions. Toute utilisation ou confiance que vous
                accordez au mat&eacute;riel est &agrave; vos risques et
                p&eacute;rils.
            </p>
            <p className='p3'>
                <strong>Notifications de service et autres</strong>
            </p>
            <p className='p3'>
                Vous acceptez les notifications concernant l'application, la
                plate-forme, votre compte et les conditions par le biais des
                notifications de service. Vous acceptez que ces notifications de
                service constituent une notification effective en lieu et place
                d'une notification &eacute;crite, envoy&eacute;e par la poste ou
                d'autres formes de notification requises par la loi applicable.
            </p>
            <p className='p3'>
                Il est de votre seule responsabilit&eacute; de surveiller le
                compte de messagerie applicable sans autre rappel ou
                notification r&eacute;p&eacute;t&eacute;e de la part de la
                Soci&eacute;t&eacute;. Vous devez imm&eacute;diatement signaler
                toute utilisation ou tout acc&egrave;s non autoris&eacute;
                &agrave; la plate-forme.
            </p>
            <p className='p3'>
                Vous d&eacute;gagez la Soci&eacute;t&eacute; de toute
                responsabilit&eacute; en cas de pertes ou de dommages
                r&eacute;sultant de l'utilisation des notifications de service,
                dans les limites autoris&eacute;es par la loi. La
                Soci&eacute;t&eacute; ne garantit pas et n'accepte aucune
                responsabilit&eacute; quant &agrave; l'actualit&eacute;,
                l'exactitude et l'exhaustivit&eacute; des informations fournies
                par le biais des notifications de service.
            </p>
            <p className='p3'>
                <strong>Informations personnelles</strong>
            </p>
            <p className='p3'>
                Dans le cadre de la plate-forme, les informations personnelles
                de l'utilisateur peuvent &ecirc;tre collect&eacute;es,
                utilis&eacute;es, transf&eacute;r&eacute;es, divulgu&eacute;es
                ou trait&eacute;es d'une autre mani&egrave;re par la
                soci&eacute;t&eacute; conform&eacute;ment &agrave; la politique
                de confidentialit&eacute;. Vous devez lire attentivement la
                politique de confidentialit&eacute; avant de vous inscrire et
                d'utiliser le site et la plate-forme.
                <span className='Apple-converted-space'>&nbsp; </span>Vous
                consentez &agrave; la collecte, &agrave; l'utilisation et
                &agrave; la divulgation de vos informations personnelles
                conform&eacute;ment aux pr&eacute;sentes conditions et &agrave;
                la politique de confidentialit&eacute;, y compris, mais sans s'y
                limiter, &agrave; la divulgation au fournisseur de services
                tiers dans le but de fournir des services et d'effectuer des
                transactions en rapport avec le compte.
            </p>
            <p className='p3'>
                Vous acceptez de fournir des informations personnelles
                v&eacute;ridiques, exactes, actuelles et compl&egrave;tes. Vous
                acceptez en outre de maintenir et de mettre &agrave; jour
                rapidement les informations personnelles afin qu'elles restent
                v&eacute;ridiques, exactes, actuelles et compl&egrave;tes
                &agrave; tout moment pendant la dur&eacute;e du pr&eacute;sent
                accord.
            </p>
            <p className='p3'>
                Vous devez nous informer rapidement de tout changement, y
                compris, mais sans s'y limiter, des changements dans les
                informations personnelles en rapport avec la plate-forme. Si
                vous fournissez des informations fausses, inexactes,
                p&eacute;rim&eacute;es ou incompl&egrave;tes, ou si nous ou l'un
                de nos agents autoris&eacute;s avons des motifs raisonnables de
                soup&ccedil;onner que ces informations sont fausses, inexactes,
                p&eacute;rim&eacute;es ou incompl&egrave;tes, nous avons le
                droit de suspendre ou de r&eacute;silier le compte et de refuser
                toute utilisation actuelle ou future de la plate-forme et du
                site par vous, ainsi que d'engager votre responsabilit&eacute;
                civile ou de vous d&eacute;f&eacute;rer aux autorit&eacute;s
                comp&eacute;tentes charg&eacute;es de l'application de la loi
                pour des poursuites p&eacute;nales.
                <span className='Apple-converted-space'>&nbsp; </span>Nous ne
                serons pas tenus de verser une quelconque compensation,
                mon&eacute;taire ou autre, &agrave; la suite d'une telle
                suspension, r&eacute;siliation ou impossibilit&eacute;
                d'utiliser la Plateforme ou le Site.
            </p>
            <p className='p3'>
                Veuillez noter que nous pouvons collecter des informations
                &agrave; l'aide de technologies de suivi concernant votre
                appareil, telles que l'adresse IP, le fournisseur de
                r&eacute;seau, l'op&eacute;rateur mobile, le type de navigateur
                mobile, l'horodatage, le fuseau horaire, des informations sur la
                vitesse, le rel&egrave;vement, l'orientation et l'altitude d'un
                appareil, ou d'autres informations d'identification de
                l'appareil. L'utilisateur consent &agrave; cette utilisation des
                technologies de suivi et reconna&icirc;t que les informations
                obtenues, y compris les informations personnelles, peuvent
                &ecirc;tre mises en correspondance avec des informations
                publiques ou priv&eacute;es accessibles &agrave; la
                soci&eacute;t&eacute; ou &agrave; tout fournisseur de services
                tiers. L'utilisateur consent &eacute;galement &agrave; ce que
                ces informations soient partag&eacute;es avec les fournisseurs
                de services de la soci&eacute;t&eacute; et des fournisseurs de
                services tiers dans le but de fournir et de maintenir les
                technologies de suivi et les services connexes. Nous pouvons
                &eacute;galement collecter des donn&eacute;es de
                g&eacute;olocalisation pr&eacute;cises &agrave; partir de votre
                appareil ou &agrave; propos de celui-ci, qui peuvent &ecirc;tre
                exprim&eacute;es par des coordonn&eacute;es de latitude et de
                longitude obtenues par des outils GPS, des donn&eacute;es WiFi,
                la triangulation d'une tour de t&eacute;l&eacute;phonie
                cellulaire ou d'autres techniques. L'utilisation que nous
                faisons de ces informations est d&eacute;crite dans notre
                politique de confidentialit&eacute;.
            </p>
            <p className='p3'>
                <strong>
                    Clause de non-responsabilit&eacute; et risques li&eacute;s
                    &agrave; l'utilisation de la plate-forme
                </strong>
            </p>
            <p className='p3'>
                La Plateforme et le Site, y compris tout le contenu (y compris
                le contenu de tiers), les fonctionnalit&eacute;s et tous les
                services connexes sont fournis "en l'&eacute;tat" et "en
                fonction des disponibilit&eacute;s", au seul risque de
                l'utilisateur et sans aucune d&eacute;claration ou garantie.
                Nous ne garantissons pas que tout ou partie de la Plateforme ou
                de l'Application sera disponible ou accessible par l'Utilisateur
                &agrave; tout moment.
            </p>
            <p className='p3'>
                L'utilisation de la Plateforme, du fait du
                t&eacute;l&eacute;chargement, de l'installation ou de
                l'utilisation du Site et des points de r&eacute;f&eacute;rence
                associ&eacute;s avec des tiers (par exemple, des fournisseurs de
                plateformes de distribution, des fournisseurs de r&eacute;seaux,
                des fabricants d'appareils) comporte des risques, en particulier
                :
            </p>
            <p className='p3'>
                (a) la divulgation &agrave; des tiers de vos informations
                personnelles ou d'autres informations et de l'existence de votre
                relation avec le prestataire de services tiers ;
            </p>
            <p className='p3'>
                (b) les pannes de syst&egrave;me, les restrictions li&eacute;es
                &agrave; la s&eacute;curit&eacute; et la suppression non
                autoris&eacute;e des restrictions d'utilisation sur l'appareil
                final, ainsi que d'autres perturbations susceptibles de rendre
                l'utilisation impossible ; et
            </p>
            <p className='p3'>
                (c) une mauvaise utilisation due &agrave; une manipulation par
                des logiciels malveillants ou une utilisation non
                autoris&eacute;e, y compris en cas de perte ou de vol de
                l'appareil de l'utilisateur utilis&eacute; pour acc&eacute;der
                au site ou &agrave; la plateforme.
            </p>
            <p className='p3'>
                Nous sommes en droit de bloquer ou de d&eacute;sactiver
                l'utilisation du Site sur les appareils finaux si les
                dispositifs de s&eacute;curit&eacute; con&ccedil;us par le
                syst&egrave;me d'exploitation ou le fabricant de l'appareil sur
                lequel le Site est install&eacute; ont &eacute;t&eacute;
                modifi&eacute;s &agrave; tout moment (par exemple, un appareil
                qui a &eacute;t&eacute; "jailbreak&eacute;"). Par
                cons&eacute;quent, nous ne garantissons pas le fonctionnement et
                l'exploitation de l'App sur des appareils finaux qui ont
                &eacute;t&eacute; modifi&eacute;s de cette mani&egrave;re ou sur
                des appareils finaux plus anciens qui ne r&eacute;pondent plus
                aux exigences techniques pour l'utilisation du Site ou
                l'acc&egrave;s &agrave; la Plateforme.
            </p>
            <p className='p3'>
                TOUTES LES GARANTIES, CONDITIONS OU TERMES (QU'ILS SOIENT
                EXPLICITES, IMPLICITES, STATUTAIRES OU AUTRES), Y COMPRIS, SANS
                S'Y LIMITER, LES GARANTIES DE QUALIT&Eacute;, DE QUALIT&Eacute;
                MARCHANDE, D'AD&Eacute;QUATION &Agrave; UN USAGE PARTICULIER OU
                D'ACC&Egrave;S ININTERROMPU ET SANS ERREUR, SONT
                EXPRESS&Eacute;MENT EXCLUS DU SITE ET DE LA PLATE-FORME DANS
                TOUTE LA MESURE PERMISE PAR LA LOI.
            </p>
            <p className='p3'>
                Aucune d&eacute;claration ou garantie, expresse ou implicite, ne
                peut &ecirc;tre donn&eacute;e quant &agrave; l'exactitude ou
                &agrave; l'exhaustivit&eacute; des informations fournies dans la
                plate-forme.
            </p>
            <p className='p3'>
                Chaque utilisateur reconna&icirc;t et accepte les risques qui
                peuvent d&eacute;couler de l'exp&eacute;rience du voyage. Nous
                pouvons &eacute;galement utiliser des serveurs et d'autres
                mat&eacute;riels informatiques situ&eacute;s dans n'importe
                quelle juridiction dans le monde entier pour la fourniture de
                toute partie de la plate-forme.
            </p>
            <p className='p3'>
                Nous excluons toute responsabilit&eacute; en cas de perte ou de
                dommage caus&eacute; par des erreurs de transmission, des
                d&eacute;faillances techniques, des pannes, des interruptions
                d'activit&eacute; ou des interventions ill&eacute;gales dans les
                r&eacute;seaux de transmission, les syst&egrave;mes
                informatiques de l'utilisateur ou d'un tiers (y compris les
                syst&egrave;mes du domaine public).
            </p>
            <p className='p3'>
                <strong>Cessation d'activit&eacute;</strong>
            </p>
            <p className='p3'>
                Nous pouvons r&eacute;silier ou suspendre votre compte
                imm&eacute;diatement, sans pr&eacute;avis ni
                responsabilit&eacute;, pour quelque raison que ce soit, y
                compris, mais sans s'y limiter, si vous enfreignez les
                pr&eacute;sentes conditions g&eacute;n&eacute;rales.
            </p>
            <p className='p3'>
                En cas de r&eacute;siliation, votre droit d'utiliser le service
                cessera imm&eacute;diatement. Si vous souhaitez r&eacute;silier
                votre compte, vous pouvez simplement cesser d'utiliser le
                service.
            </p>
            <p className='p3'>
                <strong>Lib&eacute;ration</strong>
            </p>
            <p className='p3'>
                Dans toute la mesure permise par la loi applicable, vous nous
                d&eacute;chargez, nous acquittez et nous lib&eacute;rez de toute
                autre mani&egrave;re, ainsi que notre soci&eacute;t&eacute;
                m&egrave;re, nos soci&eacute;t&eacute;s affili&eacute;es et nos
                filiales, et chacun de leurs dirigeants, administrateurs,
                actionnaires, membres, partenaires, avocats, employ&eacute;s,
                entrepreneurs ind&eacute;pendants, fournisseurs de
                t&eacute;l&eacute;communications et agents respectifs
                (collectivement, les " parties indemnis&eacute;es "), de toutes
                les all&eacute;gations, chefs d'accusation, charges, dettes,
                causes d'action, r&eacute;clamations et pertes li&eacute;es de
                quelque mani&egrave;re que ce soit &agrave; l'utilisation ou aux
                activit&eacute;s li&eacute;es &agrave; l'utilisation du site, de
                la plate-forme, de tout compte et de tout service ou contenu de
                tiers fourni par l'interm&eacute;diaire du site, de la
                plate-forme ou de tout compte, y compris, mais sans s'y limiter,
                les r&eacute;clamations li&eacute;es &agrave; ce qui suit :
                n&eacute;gligence, n&eacute;gligence grave, interf&eacute;rence
                intentionnelle avec un contrat ou une relation commerciale
                avantageuse, diffamation, vie priv&eacute;e, publicit&eacute;,
                fausse d&eacute;claration, fausses identit&eacute;s, actes
                frauduleux commis par des tiers, atteinte &agrave; la vie
                priv&eacute;e, divulgation d'informations personnelles,
                &eacute;chec de transactions, d'achats ou de
                fonctionnalit&eacute;s de la plateforme, indisponibilit&eacute;
                du site, de la plateforme, du contenu de tiers ou de tout
                compte, de leurs fonctions et de toute autre d&eacute;faillance
                technique pouvant entra&icirc;ner l'inaccessibilit&eacute; au
                site, &agrave; la plate-forme, au contenu de tiers ou &agrave;
                tout compte, ou toute r&eacute;clamation fond&eacute;e sur la
                responsabilit&eacute; du fait d'autrui pour des d&eacute;lits
                commis par vous et rencontr&eacute;s ou trait&eacute;s par
                l'interm&eacute;diaire du site, de la plate-forme, du contenu de
                tiers et de tout compte, y compris, mais sans s'y limiter, la
                fraude, le piratage informatique, le vol ou l'utilisation
                abusive d'informations personnelles, les coups et blessures, le
                harc&egrave;lement, le viol, la tricherie, le parjure,
                l'homicide involontaire ou l'assassinat.
                <span className='Apple-converted-space'>&nbsp; </span>La liste
                ci-dessus n'est donn&eacute;e qu'&agrave; titre d'exemple et
                n'est pas exhaustive des types ou cat&eacute;gories de
                r&eacute;clamations auxquelles nous renon&ccedil;ons.
                <span className='Apple-converted-space'>&nbsp; </span>La
                pr&eacute;sente d&eacute;charge est destin&eacute;e par les
                parties &agrave; &ecirc;tre interpr&eacute;t&eacute;e largement
                en notre faveur, et donc toute ambigu&iuml;t&eacute; sera
                interpr&eacute;t&eacute;e de mani&egrave;re &agrave; permettre
                la d&eacute;charge des r&eacute;clamations les plus larges.
                <span className='Apple-converted-space'>&nbsp; </span>La
                pr&eacute;sente d&eacute;charge est cens&eacute;e &ecirc;tre une
                d&eacute;charge compl&egrave;te des r&eacute;clamations, et les
                parties reconnaissent la nature juridiquement contraignante de
                cette disposition, ainsi que la nature des droits auxquels elles
                renoncent dans ce cadre.
            </p>
            <p className='p3'>
                <strong>Limitation de la responsabilit&eacute;</strong>
            </p>
            <p className='p3'>
                SafeTravy n'est pas responsable des informations disponibles via
                et/ou int&eacute;gr&eacute;es aux produits SafeTravy. Par
                cons&eacute;quent, SafeTravy ne peut &ecirc;tre tenu responsable
                de l'exactitude, de l'exhaustivit&eacute;, de la qualit&eacute;,
                de la l&eacute;galit&eacute; et de la fiabilit&eacute; des
                informations ou des r&eacute;sultats obtenus &agrave; l'aide de
                ces informations.
            </p>
            <p className='p3'>
                Les services SafeTravy constituent des outils &agrave; utiliser
                pour la gestion et l'&eacute;valuation des risques. SafeTravy
                n'est pas en mesure de garantir que toutes les informations et
                mises &agrave; jour sont compl&egrave;tes, ad&eacute;quates et
                exemptes d'erreurs. Les utilisateurs doivent toujours
                &eacute;valuer les r&eacute;sultats et les donn&eacute;es du
                service sur une base individuelle. SafeTravy vise &agrave;
                r&eacute;duire les risques pour les utilisateurs mais n'est en
                aucun cas responsable de la s&ucirc;ret&eacute; et de la
                s&eacute;curit&eacute; des utilisateurs et SafeTravy n'assume
                aucune responsabilit&eacute; pour les pertes, les dommages
                corporels ou le d&eacute;c&egrave;s des utilisateurs.
            </p>
            <p className='p3'>
                EN AUCUN CAS ET EN VERTU D'AUCUNE TH&Eacute;ORIE DU DROIT
                (D&Eacute;LIT, CONTRAT, RESPONSABILIT&Eacute; STRICTE OU AUTRE),
                NOUS OU L'UN DES INDEMNIS&Eacute;S NE SERONS RESPONSABLES ENVERS
                VOUS OU TOUTE AUTRE PERSONNE DE TOUT DOMMAGE D&Eacute;COULANT DE
                L'UTILISATION OU DE LA MAUVAISE UTILISATION, OU DE
                L'INCAPACIT&Eacute; D'UTILISER LA PLATEFORME, LE SITE, LE
                CONTENU DE TIERS OU TOUT COMPTE, QUE CES DOMMAGES SOIENT
                DIRECTS, INDIRECTS, SP&Eacute;CIAUX, ACCESSOIRES OU
                CONS&Eacute;CUTIFS DE TOUTE NATURE, DE LA PERTE D'INFORMATIONS,
                DE L'INTERRUPTION DES ACTIVIT&Eacute;S OU DE LA PERTE DE
                B&Eacute;N&Eacute;FICES, PERTE D'&Eacute;CONOMIES OU DE
                DONN&Eacute;ES, OU DES RESPONSABILIT&Eacute;S EN VERTU DE TOUT
                CONTRAT, N&Eacute;GLIGENCE, RESPONSABILIT&Eacute; STRICTE OU
                AUTRE TH&Eacute;ORIE D&Eacute;COULANT DE OU LI&Eacute;E DE
                QUELQUE MANI&Egrave;RE QUE CE SOIT AU SITE, &Agrave; LA
                PLATE-FORME, AU CONTENU DE TIERS OU &Agrave; TOUT COMPTE OU POUR
                TOUTE R&Eacute;CLAMATION OU DEMANDE PAR UN TIERS, M&Ecirc;ME SI
                NOUS CONNAISSIONS OU AVIONS DES RAISONS DE CONNA&Icirc;TRE LA
                POSSIBILIT&Eacute; DE TELS DOMMAGES, R&Eacute;CLAMATIONS OU
                DEMANDES SI LA CLAUSE DE NON-RESPONSABILIT&Eacute; ET DE
                RENONCIATION &Agrave; LA RESPONSABILIT&Eacute; QUI
                PR&Eacute;C&Egrave;DE DEVAIT &Ecirc;TRE CONSID&Eacute;R&Eacute;E
                COMME INVALIDE OU INEFFICACE. CERTAINES JURIDICTIONS
                N'AUTORISENT PAS L'EXCLUSION OU LA LIMITATION DE CERTAINES
                GARANTIES ET/OU RESPONSABILIT&Eacute;S, DE SORTE QUE CERTAINES
                DES LIMITATIONS OU EXCLUSIONS SUSMENTIONN&Eacute;ES PEUVENT NE
                PAS S'APPLIQUER &Agrave; VOUS.
            </p>
            <p className='p3'>
                EN AUCUN CAS NOTRE RESPONSABILIT&Eacute;, QUELLE QUE SOIT LA
                FORME DE L'ACTION ET LES DOMMAGES SUBIS PAR VOUS, NE
                D&Eacute;PASSERA LE MONTANT TOTAL LE PLUS &Eacute;LEV&Eacute;
                DES FRAIS D'ABONNEMENT QUE VOUS NOUS AVEZ PAY&Eacute;S EN
                RAPPORT AVEC LA PLATEFORME OU LE SITE, OU 500 DOLLARS
                AM&Eacute;RICAINS, LE MONTANT LE PLUS &Eacute;LEV&Eacute;
                &Eacute;TANT RETENU.
            </p>
            <p className='p3'>
                Nous ne serons pas responsables de notre incapacit&eacute;
                &agrave; remplir les obligations pr&eacute;vues par les
                pr&eacute;sentes conditions en raison d'&eacute;v&eacute;nements
                ind&eacute;pendants de notre volont&eacute;, et le d&eacute;lai
                pr&eacute;vu pour remplir ces obligations sera prolong&eacute;
                d'une p&eacute;riode &eacute;gale &agrave; la dur&eacute;e de
                ces &eacute;v&eacute;nements.
                <span className='Apple-converted-space'>&nbsp; </span>Les
                &eacute;v&eacute;nements ind&eacute;pendants de notre
                volont&eacute; comprennent, sans s'y limiter, les cas de force
                majeure, la guerre, les &eacute;meutes, les incendies
                volontaires, les embargos, les troubles civils, les
                gr&egrave;ves, les conflits du travail, les pannes
                d'&eacute;quipement, les d&eacute;faillances bancaires,
                l'effondrement ou les fluctuations du march&eacute; des monnaies
                virtuelles, les d&eacute;faillances du traitement des
                transactions par carte de cr&eacute;dit ou de d&eacute;bit, les
                gr&egrave;ves, les incendies, les inondations, les tremblements
                de terre, les ouragans, les temp&ecirc;tes tropicales ou autres
                catastrophes naturelles, les p&eacute;nuries de
                main-d'&oelig;uvre ou de mat&eacute;riel, les p&eacute;nuries de
                transport, d'installations, de carburant, d'&eacute;nergie, les
                r&eacute;glementations ou restrictions impos&eacute;es par les
                pouvoirs publics, actes d'autorit&eacute; civile ou militaire ou
                de terrorisme, coupures de fibres, conditions
                m&eacute;t&eacute;orologiques, violations ou manquements de la
                part de tiers, probl&egrave;mes techniques, y compris les pannes
                de mat&eacute;riel et de logiciel et autres dysfonctionnements,
                d&eacute;faillance de l'infrastructure des
                t&eacute;l&eacute;communications ou des services d'information,
                piratage, SPAM ou d&eacute;faillance de tout ordinateur, serveur
                ou logiciel, perturbations dues ou caus&eacute;es par le
                vandalisme, le vol, les coupures de service
                t&eacute;l&eacute;phonique, les pannes
                d'&eacute;lectricit&eacute;, les perturbations de l'Internet,
                les virus et les pannes m&eacute;caniques, &eacute;lectriques ou
                de communication.
            </p>
            <p className='p3'>
                <strong>
                    Modifications des pr&eacute;sentes conditions
                    g&eacute;n&eacute;rales
                </strong>
            </p>
            <p className='p3'>
                Nous nous r&eacute;servons le droit, &agrave; notre seule
                discr&eacute;tion, de modifier ou de remplacer les
                pr&eacute;sentes conditions &agrave; tout moment. Si une
                r&eacute;vision est importante, nous nous efforcerons de fournir
                un pr&eacute;avis d'au moins 30 jours avant l'entr&eacute;e en
                vigueur des nouvelles conditions. La d&eacute;finition de ce qui
                constitue une modification substantielle est laiss&eacute;e
                &agrave; notre seule discr&eacute;tion.
            </p>
            <p className='p3'>
                En continuant &agrave; acc&eacute;der &agrave; notre service ou
                &agrave; l'utiliser apr&egrave;s l'entr&eacute;e en vigueur de
                ces r&eacute;visions, vous acceptez d'&ecirc;tre li&eacute; par
                les conditions r&eacute;vis&eacute;es. Si vous n'acceptez pas
                les nouvelles conditions, en tout ou en partie, veuillez cesser
                d'utiliser le site web et le service.
            </p>
            <p className='p3'>
                <strong>G&eacute;n&eacute;ral</strong>
            </p>
            <p className='p3'>
                Les pr&eacute;sentes conditions, y compris la politique de
                confidentialit&eacute; et les autres politiques qui y sont
                incorpor&eacute;es, constituent l'int&eacute;gralit&eacute; et
                le seul accord entre vous et la soci&eacute;t&eacute; en ce qui
                concerne l'objet des pr&eacute;sentes conditions et remplacent
                tous les accords, d&eacute;clarations, garanties et ententes
                ant&eacute;rieurs ou contemporains, &eacute;crits ou oraux, en
                ce qui concerne l'objet des pr&eacute;sentes conditions. Si une
                disposition des pr&eacute;sentes conditions est jug&eacute;e
                ill&eacute;gale, nulle ou inapplicable pour quelque raison que
                ce soit, cette disposition sera consid&eacute;r&eacute;e comme
                dissociable des pr&eacute;sentes conditions et n'affectera pas
                la validit&eacute; et l'applicabilit&eacute; des autres
                dispositions. Les pr&eacute;sentes conditions ne peuvent
                &ecirc;tre modifi&eacute;es, ni faire l'objet d'une renonciation
                ou d'une modification, sauf par la soci&eacute;t&eacute;,
                conform&eacute;ment aux dispositions des pr&eacute;sentes. Vous
                ne pouvez c&eacute;der, transf&eacute;rer,
                d&eacute;l&eacute;guer ou conc&eacute;der en sous-licence ni les
                pr&eacute;sentes conditions ni aucun droit, obligation ou
                recours qui en d&eacute;coule, sauf avec notre consentement
                &eacute;crit pr&eacute;alable, et toute tentative de cession, de
                transfert, de d&eacute;l&eacute;gation ou de concession de
                sous-licence sera nulle et non avenue. Toute tentative de
                cession, de transfert, de d&eacute;l&eacute;gation ou de
                sous-licence sera nulle et non avenue. Aucune renonciation par
                une partie &agrave; une violation ou &agrave; un manquement aux
                termes des pr&eacute;sentes ne sera consid&eacute;r&eacute;e
                comme une renonciation &agrave; une violation ou &agrave; un
                manquement ant&eacute;rieur ou ult&eacute;rieur.
                <span className='Apple-converted-space'>&nbsp; </span>Tout
                titre, l&eacute;gende ou titre de section contenu dans les
                pr&eacute;sentes conditions n'est ins&eacute;r&eacute;
                qu'&agrave; des fins de commodit&eacute; et ne d&eacute;finit ni
                n'explique en aucune fa&ccedil;on une section ou une disposition
                des pr&eacute;sentes.
            </p>
            <p className='p3'>
                <strong>
                    Droit applicable et r&egrave;glement des litiges
                </strong>
            </p>
            <p className='p3'>
                Tous les litiges, diff&eacute;rends ou r&eacute;clamations
                d&eacute;coulant du pr&eacute;sent accord ou en rapport avec
                celui-ci, y compris ceux relatifs &agrave; sa conclusion, son
                interpr&eacute;tation, son ex&eacute;cution, sa violation, sa
                r&eacute;siliation ou sa nullit&eacute;, seront r&eacute;solus
                par la Cour d'arbitrage commercial international de la Chambre
                de commerce et d'industrie ukrainienne, conform&eacute;ment
                &agrave; son r&egrave;glement.
            </p>
            <p className='p3'>
                Le droit r&eacute;gissant cet accord est le droit
                mat&eacute;riel de Gibraltar.
            </p>
            <p className='p3'>
                La cour d'arbitrage est compos&eacute;e d'un arbitre unique. Le
                lieu de r&eacute;union de la Cour d'arbitrage est la ville de
                Kiev, en Ukraine. La langue de la proc&eacute;dure d'arbitrage
                est l'anglais ou l'ukrainien.
            </p>
            <p className='p3'>
                <strong>Contactez-nous</strong>
            </p>
            <p className='p3'>
                Si vous avez des questions sur ces conditions générales, vous
                pouvez nous contacter:
            </p>
            <p className='p3'>
                Par e-mail:{' '}
                <span style={{ color: 'blue', textDecoration: 'underline' }}>
                    team@safetravy.com
                </span>
            </p>
            <p className='p3'>
                Par téléphone: <a href='tel:+350 54095364'>+350 54095364</a>
            </p>
            <p className='p2'>&nbsp;</p>
        </>
    ),
    [Languages.ES]: (
        <>
            <p className='p1'>
                <strong>T&eacute;rminos y condiciones de SafeTravy</strong>
            </p>
            <p className='p3'>
                Estos T&eacute;rminos y Condiciones (los "T&eacute;rminos" o
                este "Acuerdo") rigen el uso de la plataforma
                electr&oacute;nica, incluyendo cualquier sitio web o
                aplicaci&oacute;n m&oacute;vil (la "Aplicaci&oacute;n", junto
                con el sitio web, el "Sitio") para acceder a la plataforma, y
                cualquier servicio prestado a trav&eacute;s de la plataforma
                (colectivamente, la "Plataforma") proporcionada por SafeTravy
                Limited (la "Empresa", "nosotros", "nos" o "nuestro").
                <span className='Apple-converted-space'>&nbsp; </span>Las
                Condiciones constituyen un acuerdo vinculante entre la Empresa y
                usted, como usuario individual ("usted", "su" o "Usuario") para
                su uso individual de la Aplicaci&oacute;n y la Plataforma. Al
                registrarse y descargar la Aplicaci&oacute;n y utilizar la
                Plataforma, usted confirma su aceptaci&oacute;n del presente
                Acuerdo y de nuestra Pol&iacute;tica de Privacidad asociada.
                <span className='Apple-converted-space'>&nbsp; </span>Si no
                acepta las presentes Condiciones, deber&aacute; desinstalar
                inmediatamente la Aplicaci&oacute;n y dejar de utilizar la
                Aplicaci&oacute;n y la Plataforma.
            </p>
            <p className='p3'>
                Descargo de responsabilidad en materia de valores: Ning&uacute;n
                material ni ninguna otra informaci&oacute;n que pueda estar
                disponible en el Sitio o en la Plataforma constituir&aacute; ni
                se interpretar&aacute; como una recomendaci&oacute;n,
                aprobaci&oacute;n, oferta, invitaci&oacute;n o solicitud para
                que usted tome decisi&oacute;n alguna; todas las decisiones se
                toman por voluntad y experiencia propias. Adem&aacute;s, usted
                entiende que ninguno de los proveedores de informaci&oacute;n,
                incluidos los Proveedores Terceros (tal y como se definen
                m&aacute;s adelante), le est&aacute; asesorando personalmente
                sobre la naturaleza, el potencial, el valor o la idoneidad de
                ninguna cuesti&oacute;n de seguridad concreta en relaci&oacute;n
                con sus viajes. Tenga en cuenta que la situaci&oacute;n en el
                mundo cambia r&aacute;pidamente, por lo que cualquier
                informaci&oacute;n, contenido, Contenido de Terceros (como se
                define m&aacute;s adelante) u otro material proporcionado en o a
                trav&eacute;s del Sitio o la Plataforma puede no ser completo o
                actual, o puede ser sustituido por informaci&oacute;n m&aacute;s
                actual. Usted conf&iacute;a en dicha informaci&oacute;n por su
                cuenta y riesgo.
            </p>
            <p className='p3'>
                Nuestro Sitio y nuestra Plataforma no pretenden ofrecer
                asesoramiento jur&iacute;dico, sobre seguros o inversiones, y
                nada de lo que aparece en el Sitio o en la Plataforma debe
                interpretarse como una oferta sobre c&oacute;mo organizar su
                viaje. Toda esta informaci&oacute;n es solo una
                recomendaci&oacute;n personalizada.
            </p>
            <p className='p3'>
                <strong>1. Aplicabilidad</strong>
            </p>
            <p className='p3'>
                1.1. Nosotros, SafeTravy Limited (en adelante "SafeTravy"),
                ofrecemos servicios en el &aacute;mbito del entretenimiento
                inform&aacute;tico Estas condiciones generales se aplican a la
                relaci&oacute;n contractual entre SafeTravy y el Cliente
                ("Cliente" o "Usted") con respecto a todos los servicios que
                prestamos.
            </p>
            <p className='p3'>
                <strong>2. Definiciones</strong>
            </p>
            <p className='p3'>
                <strong>Cuenta </strong>significa la cuenta establecida por un
                Usuario que ha descargado la App o accedido al Sitio y se ha
                registrado en la Empresa para utilizar el Sitio y la Plataforma.
            </p>
            <p className='p3'>
                <strong>App </strong>significa la aplicaci&oacute;n m&oacute;vil
                proporcionada por la Empresa para acceder a la Plataforma.
            </p>
            <p className='p3'>
                <strong>Persona Autorizada </strong>significa cualquier persona
                autorizada a acceder y utilizar el Sitio (incluida la
                Aplicaci&oacute;n) y la Plataforma en nombre de un Usuario.
            </p>
            <p className='p3'>
                <strong>Plataformas digitales </strong>se refiere a plataformas
                de distribuci&oacute;n de terceros en las que se puede acceder o
                descargar aplicaciones m&oacute;viles u otros programas de
                software, incluidas, entre otras, Apple App Store y Google Play.
            </p>
            <p className='p3'>
                <strong>Autoridad gubernamental </strong>significa cualquier
                naci&oacute;n o gobierno o cualquier provincia o estado o
                cualquier otra subdivisi&oacute;n pol&iacute;tica del mismo, o
                cualquier entidad, autoridad u organismo que ejerza funciones
                ejecutivas, legislativas, judiciales, reguladoras o
                administrativas de o relativas al gobierno, incluyendo cualquier
                autoridad gubernamental, agencia, departamento, junta,
                comisi&oacute;n o instrumento o cualquier subdivisi&oacute;n
                pol&iacute;tica del mismo, cualquier juzgado, tribunal o
                &aacute;rbitro, y cualquier organizaci&oacute;n autorreguladora.
            </p>
            <p className='p3'>
                <strong>Material </strong>significa cualquier material de
                oferta, hoja de t&eacute;rminos, informe de
                investigaci&oacute;n, documentaci&oacute;n de productos o
                servicios o cualquier otra informaci&oacute;n proporcionada a
                trav&eacute;s de la Plataforma.
            </p>
            <p className='p3'>
                Por <strong>Datos Personales </strong>se entiende la
                informaci&oacute;n suministrada por un Usuario a partir de la
                cual puede determinarse, directa o indirectamente, la identidad
                de dicho Usuario.
            </p>
            <p className='p3'>
                <strong>Pol&iacute;tica de Privacidad </strong>significa los
                t&eacute;rminos y condiciones adicionales que rigen la
                recopilaci&oacute;n, uso y divulgaci&oacute;n de la
                Informaci&oacute;n Personal de cada Usuario, tal y como se
                establece aqu&iacute;. Cada Usuario debe leer y aceptar la
                Pol&iacute;tica de Privacidad para poder utilizar la
                Aplicaci&oacute;n o el Sitio.
            </p>
            <p className='p3'>
                <strong>Las Notificaciones de Servicio </strong>son
                notificaciones unidireccionales de la Empresa (que pueden
                incluir notificaciones relacionadas con la seguridad) a
                trav&eacute;s de mensajes de texto o correos electr&oacute;nicos
                y, en su caso, notificaciones push a trav&eacute;s del Sitio.
                <span className='Apple-converted-space'>&nbsp; </span>Estas
                notificaciones se env&iacute;an al Usuario en relaci&oacute;n
                con determinada informaci&oacute;n o eventos relacionados con
                una cuenta a la que el Usuario tiene acceso a trav&eacute;s de
                la Plataforma.
            </p>
            <p className='p3'>
                <strong>Usuario </strong>significa cualquier persona que se haya
                registrado en la Empresa para utilizar el Sitio y acceder a la
                Plataforma y cualquier Persona Autorizada que act&uacute;e en su
                nombre.
            </p>
            <p className='p3'>
                <strong>Credenciales de usuario </strong>significa el conjunto
                de identificaci&oacute;n de usuario, contrase&ntilde;a,
                n&uacute;mero de identificaci&oacute;n personal, token y
                cualquier otra informaci&oacute;n o dispositivo proporcionado a
                un Usuario para acceder a la Plataforma.
            </p>
            <p className='p3'>
                <strong>La Empresa </strong>(denominada "la Empresa",
                "Nosotros", "Nos" o "Nuestro" en el presente Contrato) se
                refiere a SAFETRAVY LIMITED, Suite 4, 2nd floor, the West Wing,
                Montarik House, 3 Bedlam Court, Gibraltar, GX11 1AA, GIBRALTAR,
                GIBRALTAR.
            </p>
            <p className='p3'>
                <strong>Dispositivo</strong>: cualquier aparato que pueda
                acceder al Servicio, como un ordenador, un tel&eacute;fono
                m&oacute;vil o una tableta digital.
            </p>
            <p className='p3'>
                <strong>Prueba gratuita </strong>se refiere a un periodo de
                tiempo limitado que puede ser gratuito al adquirir una
                Suscripci&oacute;n.
            </p>
            <p className='p3'>
                <strong>Servicio </strong>se refiere a toda la
                informaci&oacute;n, recomendaciones y notificaciones
                proporcionadas por el sitio web.
            </p>
            <p className='p3'>
                <strong>Las suscripciones </strong>hacen referencia a los
                servicios o al acceso al Servicio que la Empresa le ofrece a
                Usted mediante suscripci&oacute;n.
            </p>
            <p className='p3'>
                <strong>T&eacute;rminos y Condiciones </strong>(tambi&eacute;n
                referidos como "T&eacute;rminos") significan estos
                T&eacute;rminos y Condiciones que forman el acuerdo completo
                entre Usted y la Compa&ntilde;&iacute;a con respecto al uso del
                Servicio.
            </p>
            <p className='p3'>
                Por Servicio de <strong>terceros </strong>se entiende cualquier
                servicio o contenido (incluidos datos, informaci&oacute;n,
                productos o servicios) proporcionado por un tercero que pueda
                mostrarse, incluirse o ponerse a disposici&oacute;n a
                trav&eacute;s del Servicio.
            </p>
            <p className='p3'>
                <strong>El sitio web </strong>remite a SafeTravy, accesible
                desde www.safetravy.com
            </p>
            <p className='p3'>
                <strong>Cambios</strong>
            </p>
            <p className='p3'>Nos reservamos el derecho a:</p>
            <p className='p3'>
                (a) modificar, actualizar o cambiar los t&eacute;rminos y
                condiciones del presente Acuerdo o de nuestra Pol&iacute;tica de
                Privacidad ;
            </p>
            <p className='p3'>
                (b) modificar, actualizar o cambiar el Sitio y la Plataforma,
                incluida la eliminaci&oacute;n o interrupci&oacute;n de
                cualquier contenido o caracter&iacute;stica del Sitio o la
                Plataforma (todo lo anterior denominado "Cambios").
            </p>
            <p className='p3'>
                Podemos realizar dichos Cambios en cualquier momento sin previo
                aviso.<span className='Apple-converted-space'>&nbsp; </span>
                Cualquier Cambio en este Acuerdo puede ser publicado en nuestro
                sitio web o notificado a usted a trav&eacute;s de notificaciones
                push a trav&eacute;s del Sitio o un correo electr&oacute;nico a
                la direcci&oacute;n de correo electr&oacute;nico en su Cuenta.
                Por este motivo, debe consultar nuestro sitio web con
                regularidad, permitir que el Sitio reciba dichas notificaciones
                push y mantener su direcci&oacute;n de correo electr&oacute;nico
                y otra informaci&oacute;n de contacto actualizada en la Cuenta.
                Usted acepta cualquier Cambio si contin&uacute;a utilizando el
                Sitio y la Plataforma despu&eacute;s de que dichos Cambios se
                hayan efectuado.
            </p>
            <p className='p3'>
                <strong>T&eacute;rminos de la plataforma digital</strong>
            </p>
            <p className='p3'>
                La App puede estar disponible para su descarga desde una o
                m&aacute;s Plataformas Digitales. Su descarga,
                instalaci&oacute;n, acceso o uso de la Aplicaci&oacute;n
                tambi&eacute;n est&aacute;n sujetos a los t&eacute;rminos y
                condiciones y a las pol&iacute;ticas de privacidad de la
                Plataforma Digital aplicable (los "T&eacute;rminos de la
                Plataforma Digital"). En caso de conflicto entre las presentes
                Condiciones y las Condiciones de la Plataforma Digital,
                prevalecer&aacute;n las presentes Condiciones.
            </p>
            <p className='p3'>
                La Aplicaci&oacute;n es independiente de cualquier Plataforma
                Digital y no est&aacute; asociada, afiliada, patrocinada,
                respaldada ni vinculada de modo alguno a ninguna de ellas. Usted
                y nosotros reconocemos que el presente Acuerdo se celebra
                &uacute;nicamente entre usted y nosotros, y no con ninguna
                Plataforma Digital, y nosotros, y no la Plataforma Digital,
                somos los &uacute;nicos responsables de la App y de su contenido
                en la medida especificada en el presente Acuerdo.
            </p>
            <p className='p3'>
                Usted y nosotros reconocemos y aceptamos que la Plataforma
                Digital correspondiente, y las filiales de dicha Plataforma
                Digital son terceros beneficiarios de estas Condiciones, y que,
                tras su aceptaci&oacute;n de estas Condiciones, dicha Plataforma
                Digital tendr&aacute; derecho (y se considerar&aacute; que ha
                aceptado el derecho) a hacer cumplir estas Condiciones frente a
                usted como tercero beneficiario de las mismas.
            </p>
            <p className='p3'>
                <strong>
                    Requisitos del dispositivo de red y del operador
                </strong>
            </p>
            <p className='p3'>
                Usted reconoce que su acuerdo con su proveedor de red
                m&oacute;vil y de Internet (el "Proveedor de Red") se
                aplicar&aacute; a su uso del Sitio. Usted reconoce que su
                proveedor de red puede cobrarle por los servicios de datos
                mientras utiliza determinadas funciones del Sitio o por
                cualquier otro cargo de terceros que pueda surgir, y usted
                acepta la responsabilidad exclusiva de dichos cargos. Si usted
                no es el pagador de la factura del dispositivo m&oacute;vil o de
                Internet utilizado para acceder al Sitio, se asumir&aacute; que
                ha recibido permiso del pagador de la factura para utilizar el
                Sitio. Tambi&eacute;n debe asegurarse de que el uso que hace del
                Sitio no infringe el contrato de su dispositivo m&oacute;vil o
                de Internet ni ning&uacute;n contrato de servicio de datos
                inal&aacute;mbricos.
            </p>
            <p className='p3'>
                <strong>Requisitos e inscripci&oacute;n</strong>
            </p>
            <p className='p3'>
                Usted debe tener al menos 18 a&ntilde;os de edad para acceder y
                utilizar el Sitio y la Plataforma. Asimismo, afirma que es
                plenamente capaz y competente para aceptar los t&eacute;rminos,
                condiciones, obligaciones, afirmaciones, declaraciones y
                garant&iacute;as establecidos en estos T&eacute;rminos, y para
                acatar y cumplir estos T&eacute;rminos. Debe registrarse en la
                Empresa para utilizar el Sitio y la Plataforma; se compromete a
                proporcionar informaci&oacute;n completa y precisa al
                registrarse para utilizar el Sitio y la Plataforma, y a mantener
                dicha informaci&oacute;n actualizada.
            </p>
            <p className='p3'>
                Tenemos la facultad exclusiva de aceptar o rechazar su registro
                en la Plataforma. Solo los Usuarios cuyos registros sean
                aprobados por nosotros ser&aacute;n nuestros clientes.
            </p>
            <p className='p3'>
                <strong>Acuse de recibo</strong>
            </p>
            <p className='p3'>
                Estos son los T&eacute;rminos y Condiciones que rigen el uso de
                este Servicio y el acuerdo que opera entre Usted y la
                Compa&ntilde;&iacute;a. Estos T&eacute;rminos y Condiciones
                establecen los derechos y obligaciones de todos los usuarios en
                relaci&oacute;n con el uso del Servicio.
            </p>
            <p className='p3'>
                Su acceso y uso del Servicio est&aacute;n condicionados a su
                aceptaci&oacute;n y cumplimiento de estos T&eacute;rminos y
                Condiciones. Estas Condiciones se aplican a todos los
                visitantes, usuarios y dem&aacute;s personas que accedan o
                utilicen el Servicio.
            </p>
            <p className='p3'>
                Al acceder o utilizar el Servicio, Usted acepta quedar vinculado
                por las presentes Condiciones Generales. Si no est&aacute; de
                acuerdo con alguna parte de estas Condiciones, no podr&aacute;
                acceder al Servicio.
            </p>
            <p className='p3'>
                Usted declara que es mayor de 18 a&ntilde;os. La Empresa no
                permite que los menores de 18 a&ntilde;os utilicen el Servicio.
            </p>
            <p className='p3'>
                Su acceso y uso del Servicio tambi&eacute;n est&aacute;n
                condicionados a Su aceptaci&oacute;n y cumplimiento de la
                Pol&iacute;tica de Privacidad de la Empresa. Nuestra
                Pol&iacute;tica de Privacidad describe Nuestras pol&iacute;ticas
                y procedimientos sobre la recopilaci&oacute;n, uso y
                divulgaci&oacute;n de Su informaci&oacute;n personal cuando
                Usted utiliza la Aplicaci&oacute;n o el Sitio Web y le informa
                sobre Sus derechos de privacidad y c&oacute;mo le protege la
                ley. Lea atentamente nuestra Pol&iacute;tica de Privacidad antes
                de utilizar nuestro Servicio.
            </p>
            <p className='p3'>
                <strong>Suscripciones</strong>
            </p>
            <p className='p3'>
                <strong>Periodo de suscripci&oacute;n</strong>
            </p>
            <p className='p3'>
                El Servicio o algunas partes del Servicio s&oacute;lo
                est&aacute;n disponibles con una Suscripci&oacute;n de pago. Se
                le facturar&aacute; por adelantado de forma recurrente y
                peri&oacute;dica (por ejemplo, diaria, semanal, mensual o
                anualmente), en funci&oacute;n del tipo de plan de
                Suscripci&oacute;n que seleccione al adquirir la
                Suscripci&oacute;n.
            </p>
            <p className='p3'>
                Al final de cada periodo, Su Suscripci&oacute;n se
                renovar&aacute; autom&aacute;ticamente en las mismas
                condiciones, a menos que Usted la cancele o la Empresa la
                cancele.
            </p>
            <p className='p3'>
                <strong>Cancelaci&oacute;n de suscripciones</strong>
            </p>
            <p className='p3'>
                Puede cancelar la renovaci&oacute;n de su Suscripci&oacute;n a
                trav&eacute;s de la p&aacute;gina de configuraci&oacute;n de su
                Cuenta o poni&eacute;ndose en contacto con la Empresa. No se le
                reembolsar&aacute;n las cuotas que ya haya abonado por el
                periodo de Suscripci&oacute;n actual y podr&aacute; acceder al
                Servicio hasta que finalice dicho periodo.
            </p>
            <p className='p3'>
                <strong>Facturaci&oacute;n</strong>
            </p>
            <p className='p3'>
                Deber&aacute; proporcionar a la Empresa informaci&oacute;n de
                facturaci&oacute;n precisa y completa, incluyendo nombre
                completo, direcci&oacute;n, estado, e informaci&oacute;n
                v&aacute;lida sobre el m&eacute;todo de pago.
            </p>
            <p className='p3'>
                En caso de que, por cualquier motivo, no se produzca la
                facturaci&oacute;n autom&aacute;tica, la Empresa emitir&aacute;
                una factura electr&oacute;nica en la que se le indicar&aacute;
                que debe proceder manualmente, dentro de un plazo determinado,
                al pago &iacute;ntegro correspondiente al periodo de
                facturaci&oacute;n indicado en la factura.
            </p>
            <p className='p3'>
                <strong>Cambios en las tasas</strong>
            </p>
            <p className='p3'>
                La Empresa, a su entera discreci&oacute;n y en cualquier
                momento, puede modificar las cuotas de Suscripci&oacute;n.
                Cualquier cambio en las cuotas de Suscripci&oacute;n
                entrar&aacute; en vigor al final del periodo de
                Suscripci&oacute;n vigente en ese momento.
            </p>
            <p className='p3'>
                La Empresa le avisar&aacute; con una antelaci&oacute;n razonable
                de cualquier cambio en las cuotas de Suscripci&oacute;n para
                darle la oportunidad de cancelar su Suscripci&oacute;n antes de
                que dicho cambio entre en vigor.
            </p>
            <p className='p3'>
                El uso continuado del Servicio por parte del usuario tras la
                entrada en vigor de la modificaci&oacute;n de la cuota de
                suscripci&oacute;n constituye su aceptaci&oacute;n del pago de
                la cuota de suscripci&oacute;n modificada.
            </p>
            <p className='p3'>
                <strong>Reembolsos</strong>
            </p>
            <p className='p3'>
                Salvo en los casos en que la ley lo exija, las cuotas de
                suscripci&oacute;n pagadas no son reembolsables.
            </p>
            <p className='p3'>
                La Empresa podr&aacute; estudiar caso por caso determinadas
                solicitudes de reembolso de las Suscripciones y concederlas a su
                entera discreci&oacute;n.
            </p>
            <p className='p3'>
                <strong>Prueba gratuita</strong>
            </p>
            <p className='p3'>
                La Empresa podr&aacute;, a su entera discreci&oacute;n, ofrecer
                una Suscripci&oacute;n con una Prueba Gratuita durante un
                per&iacute;odo de tiempo limitado.
            </p>
            <p className='p3'>
                Es posible que tenga que introducir sus datos de
                facturaci&oacute;n para inscribirse en la prueba gratuita.
            </p>
            <p className='p3'>
                Si introduce sus datos de facturaci&oacute;n al suscribirse a
                una Prueba Gratuita, la Empresa no le cobrar&aacute; hasta que
                la Prueba Gratuita haya caducado. El &uacute;ltimo d&iacute;a
                del periodo de Prueba Gratuita, a menos que cancele su
                Suscripci&oacute;n, se le cobrar&aacute;n autom&aacute;ticamente
                las tarifas de Suscripci&oacute;n aplicables al tipo de
                Suscripci&oacute;n que haya seleccionado.
            </p>
            <p className='p3'>
                En cualquier momento y sin previo aviso, la Empresa se reserva
                el derecho a (i) modificar los t&eacute;rminos y condiciones de
                la oferta de Prueba Gratuita, o (ii) cancelar dicha oferta de
                Prueba Gratuita.
            </p>
            <p className='p3'>
                <strong>Cuentas de usuario</strong>
            </p>
            <p className='p3'>
                Para utilizar los servicios de la Plataforma, debe crear una
                cuenta en la Plataforma (la "Cuenta").
                <span className='Apple-converted-space'>&nbsp; </span>La Cuenta
                puede ser registrada por cualquier persona f&iacute;sica mayor
                de 18 a&ntilde;os.
            </p>
            <p className='p3'>
                Al crear una cuenta con nosotros, debe proporcionarnos
                informaci&oacute;n precisa, completa y actualizada en todo
                momento. No hacerlo constituye un incumplimiento de las
                Condiciones, que puede dar lugar a la cancelaci&oacute;n
                inmediata de su cuenta en nuestro Servicio.
            </p>
            <p className='p3'>
                Usted es responsable de salvaguardar la contrase&ntilde;a que
                utiliza para acceder al Servicio y de cualquier actividad o
                acci&oacute;n realizada con su contrase&ntilde;a, ya sea con
                nuestro Servicio o con un Servicio de terceros.
            </p>
            <p className='p3'>
                Usted se compromete a no revelar su contrase&ntilde;a a
                terceros. Debe notificarnos inmediatamente cualquier
                violaci&oacute;n de la seguridad o uso no autorizado de su
                cuenta. S&oacute;lo puede acceder a su cuenta desde un
                dispositivo.
            </p>
            <p className='p3'>
                Usted no puede utilizar como nombre de usuario el nombre de otra
                persona o entidad o que no est&eacute; legalmente disponible
                para su uso, un nombre o marca comercial que est&eacute; sujeto
                a cualquier derecho de otra persona o entidad que no sea Usted
                sin la debida autorizaci&oacute;n, o un nombre que sea ofensivo,
                vulgar u obsceno.
            </p>
            <p className='p3'>
                Cada Usuario reconoce que cada conjunto de Credenciales de
                Usuario es intransferible y s&oacute;lo podr&aacute; ser
                utilizado por el Usuario al que se le haya expedido. Dichas
                Credenciales de Usuario no se revelar&aacute;n ni
                transferir&aacute;n a terceros sin el permiso por escrito de la
                Empresa. Nunca le pediremos, por ning&uacute;n motivo, ya sea
                por correo electr&oacute;nico, correo ordinario o
                tel&eacute;fono, que revele sus Credenciales de Usuario. Las
                solicitudes de contrase&ntilde;a s&oacute;lo se
                realizar&aacute;n en l&iacute;nea y una vez que haya iniciado
                sesi&oacute;n en la Plataforma. Nunca le enviaremos enlaces
                incrustados en un correo electr&oacute;nico solicit&aacute;ndole
                que se registre en la Plataforma haciendo clic en dicho enlace.
                Si recibe un enlace incrustado por correo electr&oacute;nico que
                dice proceder de nosotros o de la Plataforma, no lo abra ni haga
                clic en &eacute;l.
                <span className='Apple-converted-space'>&nbsp; </span>El correo
                electr&oacute;nico no procede de nosotros y es probable que sea
                fraudulento.
            </p>
            <p className='p3'>
                <strong>Personas autorizadas</strong>
            </p>
            <p className='p3'>
                Cada Usuario y Persona Autorizada reconoce haber recibido y
                aceptado las presentes Condiciones.
                <span className='Apple-converted-space'>&nbsp; </span>Al
                solicitar que cualquier Persona Autorizada reciba acceso a la
                Plataforma y una Cuenta en su nombre, el Usuario reconoce y
                declara que la Persona Autorizada est&aacute; debidamente
                autorizada para (i) acceder y utilizar la Plataforma en nombre
                del Usuario y, en su caso, ejercer las mismas facultades
                conferidas por el Usuario a la Persona Autorizada de conformidad
                con cualquier poder subyacente en la misma medida en que sea
                t&eacute;cnicamente factible y que los servicios ofrecidos en la
                Plataforma son an&aacute;logos a los servicios que el Usuario
                puede utilizar a trav&eacute;s de otros canales; (ii) aceptar
                cualquier modificaci&oacute;n de estas Condiciones en nombre del
                Usuario; y (iii) solicitar o suscribirse a cualquiera de los
                servicios de la Plataforma que requieran una solicitud o
                suscripci&oacute;n por separado.
            </p>
            <p className='p3'>
                Cada Usuario se asegurar&aacute; de que cada Persona Autorizada
                que act&uacute;e en su nombre est&eacute; informada, acepte y
                cumpla las presentes Condiciones y, en su caso, las Condiciones
                de Proveedores de Servicios de Terceros. Usted ser&aacute;
                plenamente responsable de todos los actos u omisiones o
                incumplimientos de su Persona Autorizada designada en el acceso
                y uso de la Plataforma y de cualquier transacci&oacute;n
                realizada a trav&eacute;s de su Cuenta.
            </p>
            <p className='p3'>
                Usted declara, se compromete y confirma que ha obtenido el
                consentimiento de sus Personas Autorizadas para la recogida,
                uso, transferencia, divulgaci&oacute;n y tratamiento de los
                Datos Personales de dichas Personas Autorizadas de conformidad
                con las presentes Condiciones y la Pol&iacute;tica de
                Privacidad.
            </p>
            <p className='p3'>
                Usted entiende y acepta que la informaci&oacute;n y los
                servicios proporcionados por la Plataforma no se facilitan a, ni
                pueden ser utilizados por o en beneficio de, cualquier persona o
                entidad en cualquier jurisdicci&oacute;n en la que la
                prestaci&oacute;n o el uso de los mismos sea contrario a
                cualquier ley aplicable, o en la que no estemos autorizados a
                proporcionar dicha Plataforma o informaci&oacute;n y servicios.
            </p>
            <p className='p3'>
                <strong>Propiedad intelectual</strong>
            </p>
            <p className='p3'>
                Todos los t&iacute;tulos, derechos de propiedad y derechos de
                propiedad intelectual en o relacionados con el Sitio y la
                Plataforma, cualquier informaci&oacute;n transmitida por, a o a
                trav&eacute;s de la Plataforma y la informaci&oacute;n relativa
                al uso de la Plataforma permanecer&aacute;n con la Empresa o sus
                licenciantes. Nada de lo contenido en la Plataforma se
                interpretar&aacute; en el sentido de que confiere al Usuario
                licencia alguna, salvo lo expresamente establecido en el
                presente documento, sobre cualquiera de los t&iacute;tulos,
                derechos de propiedad y/o derechos de propiedad intelectual de
                la Empresa o de terceros, ya sea por preclusi&oacute;n,
                implicaci&oacute;n o de otro modo.
            </p>
            <p className='p3'>
                La Plataforma y la Aplicaci&oacute;n pueden proporcionarle
                acceso a contenidos, informaci&oacute;n, citas, v&iacute;deos,
                fotos u otros materiales (los "Contenidos de Terceros")
                suministrados por determinados terceros (los "Proveedores de
                Contenidos de Terceros"). La Empresa no respalda ni recomienda,
                y no es responsable de verificar la exactitud, validez o
                integridad de ning&uacute;n Contenido de Terceros proporcionado
                a trav&eacute;s del Sitio o la Plataforma.{' '}
                <span className='Apple-converted-space'>&nbsp; </span>El uso que
                usted haga o la confianza que deposite en dicho Contenido de
                Terceros es por su cuenta y riesgo. Todos los t&iacute;tulos,
                derechos de propiedad y derechos de propiedad intelectual
                relativos al Contenido de Terceros seguir&aacute;n perteneciendo
                al Proveedor de Contenido de Terceros correspondiente. Nada de
                lo contenido en la Plataforma se interpretar&aacute; en el
                sentido de que confiere al Usuario licencia alguna, salvo lo
                expresamente establecido en el presente documento, sobre la
                titularidad, los derechos de propiedad y/o los derechos de
                propiedad intelectual de los Proveedores de Contenidos de
                Terceros, ya sea por preclusi&oacute;n, implicaci&oacute;n o de
                otro modo.
            </p>
            <p className='p3'>
                Siempre que cumpla las presentes Condiciones, podr&aacute;
                descargar y acceder al Sitio en un &uacute;nico dispositivo
                m&oacute;vil y acceder a la Plataforma utilizando las
                Credenciales de Usuario debidamente emitidas. Todos los
                dem&aacute;s derechos sobre el Sitio est&aacute;n reservados por
                la Empresa.
                <span className='Apple-converted-space'>&nbsp; </span>En caso de
                incumplimiento por su parte de las presentes Condiciones,
                tendremos derecho a poner fin inmediatamente a su uso y acceso
                al Sitio y a la Plataforma.
            </p>
            <p className='p3'>Usted se compromete a no hacerlo:</p>
            <p className='p3'>
                (a) modificar, adaptar, reproducir, traducir o crear trabajos
                derivados del Sitio o de la Plataforma, o de cualquier dato o
                contenido (incluido el Contenido de Terceros) proporcionado a
                trav&eacute;s del Sitio o de la Plataforma, o de cualquier parte
                de los mismos, o intentar realizar ingenier&iacute;a inversa,
                descompilar, desensamblar o intentar descubrir de cualquier otro
                modo el c&oacute;digo fuente del Sitio o de la Plataforma;
            </p>
            <p className='p3'>
                (b) eliminar cualquier aviso de copyright, marca comercial,
                leyenda, logotipo o identificaci&oacute;n de producto del Sitio
                o de la Plataforma;
            </p>
            <p className='p3'>
                (c) tergiversar los otros sitios como el Sitio de la Empresa
                mediante la cooptaci&oacute;n de la "apariencia" visual o el
                texto del Sitio de la Empresa o violar de otro modo los derechos
                de propiedad intelectual de la Empresa, incluyendo, sin
                limitaci&oacute;n, el "scraping" de texto o im&aacute;genes del
                Sitio de la Empresa o de los banners y/o enlaces de texto
                gestionados por la Empresa, marketing de b&uacute;squeda o
                cualquier otra campa&ntilde;a online y offline,
            </p>
            <p className='p3'>
                (d) editar, modificar, filtrar, truncar o cambiar el orden de la
                informaci&oacute;n contenida en cualquier parte de los Sitios de
                la Empresa, o eliminar, oscurecer o minimizar cualquier parte
                del Sitio de la Empresa de cualquier forma sin
                autorizaci&oacute;n de la Empresa; o
            </p>
            <p className='p3'>
                (e) hacer cualquier uso comercial del Sitio o de la Plataforma o
                del logotipo, marca comercial o nombre de marca de la Empresa de
                cualquier forma.
            </p>
            <p className='p3'>
                Cada Usuario autoriza a la Empresa a utilizar cualquier
                informaci&oacute;n o contenido proporcionado por el Usuario o
                procesado en relaci&oacute;n con el uso del Sitio y la
                Plataforma (por ejemplo, Informaci&oacute;n Personal,
                informaci&oacute;n geogr&aacute;fica, informaci&oacute;n del
                dispositivo) en el contexto y con el fin de proporcionar
                servicios o productos en la Plataforma y el uso seguro del Sitio
                y la Plataforma.
            </p>
            <p className='p3'>
                <strong>Suministro de material e informaci&oacute;n</strong>
            </p>
            <p className='p3'>
                Usted reconoce que ni la Empresa ni la Plataforma son sus
                asesores de viaje o fiduciarios. Asimismo, reconoce que ninguno
                de los Materiales que proporcionamos o ponemos a su
                disposici&oacute;n en la Plataforma constituye nuestra
                recomendaci&oacute;n o solicitud de que tome una decisi&oacute;n
                concreta durante su viaje.
            </p>
            <p className='p3'>
                Usted reconoce que no tenemos el deber ni la obligaci&oacute;n
                de verificar, corregir, completar o actualizar ning&uacute;n
                Material que aparezca en la Plataforma. Los materiales,
                incluidos, entre otros, noticias e investigaciones, pueden ser
                elaborados por proveedores de informaci&oacute;n independientes
                de nosotros. No garantizamos que el Material sea exacto,
                completo o actualizado oportunamente. Antes de tomar una
                decisi&oacute;n, le recomendamos que investigue y analice la
                informaci&oacute;n o consulte a un asesor de viajes. El uso que
                usted haga de los materiales o la confianza que deposite en
                ellos ser&aacute; por su cuenta y riesgo.
            </p>
            <p className='p3'>
                <strong>Servicio y otras notificaciones</strong>
            </p>
            <p className='p3'>
                Usted acepta recibir notificaciones relativas a la
                Aplicaci&oacute;n, la Plataforma, su Cuenta y las Condiciones a
                trav&eacute;s de las Notificaciones de Servicio. Acepta que
                dichas Notificaciones de Servicio constituir&aacute;n una
                notificaci&oacute;n efectiva en lugar de las notificaciones
                escritas, por correo u otras formas de notificaci&oacute;n
                exigidas por la legislaci&oacute;n aplicable.
            </p>
            <p className='p3'>
                Usted es el &uacute;nico responsable de supervisar la cuenta de
                correo electr&oacute;nico aplicable sin m&aacute;s recordatorios
                ni notificaciones repetidas por parte de la Empresa.
                Deber&aacute; informar inmediatamente de cualquier uso o acceso
                no autorizado a la Plataforma.
            </p>
            <p className='p3'>
                Usted exime a la Empresa de cualquier responsabilidad por
                p&eacute;rdidas o da&ntilde;os resultantes del uso de las
                Notificaciones del Servicio en la medida permitida por la ley.
                La Empresa no ofrece ninguna garant&iacute;a ni acepta ninguna
                responsabilidad de que la informaci&oacute;n proporcionada a
                trav&eacute;s de las Notificaciones de Servicio est&eacute;
                actualizada, sea correcta o est&eacute; completa.
            </p>
            <p className='p3'>
                <strong>Informaci&oacute;n personal</strong>
            </p>
            <p className='p3'>
                Como parte de la Plataforma, los Datos Personales del Usuario
                pueden ser recogidos, utilizados, transferidos, divulgados o
                tratados de otro modo por la Empresa de conformidad con la
                Pol&iacute;tica de Privacidad. El usuario debe leer atentamente
                la Pol&iacute;tica de Privacidad antes de registrarse y utilizar
                el Sitio y la Plataforma.
                <span className='Apple-converted-space'>&nbsp; </span>El usuario
                consiente la recopilaci&oacute;n, el uso y la divulgaci&oacute;n
                de sus Datos Personales de conformidad con las presentes
                Condiciones y la Pol&iacute;tica de Privacidad, incluida, sin
                limitaci&oacute;n, la divulgaci&oacute;n al Tercero Proveedor de
                Servicios a efectos de la prestaci&oacute;n de servicios y la
                realizaci&oacute;n de transacciones en relaci&oacute;n con la
                Cuenta.
            </p>
            <p className='p3'>
                Usted se compromete a proporcionar Datos Personales veraces,
                exactos, actuales y completos. Asimismo, se compromete a
                mantener y actualizar puntualmente los Datos Personales para que
                sigan siendo verdaderos, exactos, actuales y completos en todo
                momento durante la vigencia del presente Acuerdo.
            </p>
            <p className='p3'>
                Debe informarnos puntualmente de todos los cambios, incluidos,
                entre otros, los cambios en la Informaci&oacute;n personal en
                relaci&oacute;n con la Plataforma. Si proporciona cualquier
                informaci&oacute;n que sea falsa, inexacta, no actual o
                incompleta, o si nosotros o cualquiera de nuestros agentes
                autorizados tiene motivos razonables para sospechar que dicha
                informaci&oacute;n es falsa, inexacta, no actual o incompleta,
                tenemos derecho a suspender o cancelar la Cuenta y rechazar
                cualquier uso actual o futuro de la Plataforma y del Sitio por
                su parte, as&iacute; como a someterle a responsabilidad civil o
                remitirle a las autoridades policiales competentes para su
                enjuiciamiento penal.
                <span className='Apple-converted-space'>&nbsp; </span>No seremos
                responsables de realizar ninguna compensaci&oacute;n, monetaria
                o de otro tipo, tras dicha suspensi&oacute;n, rescisi&oacute;n o
                imposibilidad de que usted utilice la Plataforma o el Sitio.
            </p>
            <p className='p3'>
                Tenga en cuenta que podemos recopilar informaci&oacute;n
                utilizando tecnolog&iacute;as de seguimiento relativas a su
                dispositivo, como la direcci&oacute;n IP, el proveedor de red,
                el operador de telefon&iacute;a m&oacute;vil, el tipo de
                navegador m&oacute;vil, la marca de tiempo, la zona horaria,
                informaci&oacute;n sobre la velocidad, el rumbo, la
                orientaci&oacute;n y la altitud de un dispositivo, u otra
                informaci&oacute;n identificativa del dispositivo. El Usuario
                consiente el uso de estas tecnolog&iacute;as de seguimiento y
                reconoce que la informaci&oacute;n obtenida, incluida la
                Informaci&oacute;n Personal, puede compararse con
                informaci&oacute;n p&uacute;blica o privada a la que tenga
                acceso la Empresa o cualquier Tercero Proveedor de Servicios. El
                Usuario tambi&eacute;n consiente que dicha informaci&oacute;n
                sea compartida con los proveedores de servicios de la Empresa y
                de los Proveedores de Servicios de Terceros con el fin de
                proporcionar y mantener las tecnolog&iacute;as de rastreo y los
                servicios relacionados. Tambi&eacute;n podemos recopilar datos
                precisos de geolocalizaci&oacute;n de o sobre su dispositivo,
                que pueden expresarse mediante coordenadas de latitud-longitud
                obtenidas a trav&eacute;s de herramientas GPS, datos WiFi,
                triangulaci&oacute;n de torres de telefon&iacute;a m&oacute;vil
                u otras t&eacute;cnicas. El uso que hacemos de esa
                informaci&oacute;n se describe en nuestra Pol&iacute;tica de
                Privacidad.
            </p>
            <p className='p3'>
                <strong>
                    Descargo de responsabilidad y riesgos del uso de la
                    plataforma
                </strong>
            </p>
            <p className='p3'>
                La Plataforma y el Sitio, incluido todo el contenido (incluido
                el Contenido de Terceros), las funciones y cualquier servicio
                relacionado se proporcionan "tal cual" y "seg&uacute;n
                disponibilidad" por cuenta y riesgo exclusivos del Usuario y sin
                ninguna declaraci&oacute;n ni garant&iacute;a. No garantizamos
                que toda o parte de la Plataforma o de la Aplicaci&oacute;n
                est&eacute; disponible o accesible para el Usuario en todo
                momento.
            </p>
            <p className='p3'>
                El uso de la Plataforma, debido a la descarga,
                instalaci&oacute;n o uso del Sitio y los puntos de referencia
                asociados con terceros (por ejemplo, proveedores de plataformas
                de distribuci&oacute;n, proveedores de redes, fabricantes de
                dispositivos) implica riesgos, en particular:
            </p>
            <p className='p3'>
                (a) la divulgaci&oacute;n a terceros de sus Datos Personales u
                otra informaci&oacute;n, as&iacute; como de la existencia de su
                relaci&oacute;n con el Tercero Proveedor de Servicios;
            </p>
            <p className='p3'>
                (b) las interrupciones del sistema, las restricciones
                relacionadas con la seguridad y la eliminaci&oacute;n no
                autorizada de las restricciones de uso en el dispositivo final,
                as&iacute; como otras perturbaciones que puedan imposibilitar el
                uso; y
            </p>
            <p className='p3'>
                (c) uso indebido debido a manipulaci&oacute;n por malware o uso
                no autorizado, incluso en caso de p&eacute;rdida o robo del
                dispositivo del Usuario utilizado para acceder al Sitio o a la
                Plataforma.
            </p>
            <p className='p3'>
                Tenemos derecho a bloquear o inhabilitar el uso del Sitio en
                dispositivos finales si las caracter&iacute;sticas de seguridad
                ideadas por el sistema operativo o el fabricante de dicho
                dispositivo en el que est&aacute; instalado el Sitio han sido
                modificadas en alg&uacute;n momento (por ejemplo, un dispositivo
                que ha sido "jailbroken"). En consecuencia, no garantizamos el
                funcionamiento y la operatividad de la App en dispositivos
                finales que hayan sido modificados de esta forma o en
                dispositivos finales m&aacute;s antiguos que ya no cumplan los
                requisitos t&eacute;cnicos para el uso del Sitio o el acceso a
                la Plataforma.
            </p>
            <p className='p3'>
                QUEDAN EXPRESAMENTE EXCLUIDAS TODAS LAS GARANT&Iacute;AS,
                CONDICIONES O T&Eacute;RMINOS (YA SEAN EXPRESOS,
                IMPL&Iacute;CITOS, LEGALES O DE OTRO TIPO), INCLUIDOS, ENTRE
                OTROS, LOS RELATIVOS A LA CALIDAD, COMERCIABILIDAD, IDONEIDAD
                PARA UN FIN O ACCESO ININTERRUMPIDO Y LIBRE DE ERRORES, PARA EL
                SITIO Y LA PLATAFORMA EN LA M&Aacute;XIMA MEDIDA PERMITIDA POR
                LA LEY.
            </p>
            <p className='p3'>
                No puede darse ninguna declaraci&oacute;n ni garant&iacute;a,
                expresa o impl&iacute;cita, sobre la exactitud o integridad de
                la informaci&oacute;n proporcionada en la Plataforma.
            </p>
            <p className='p3'>
                Cada Usuario reconoce y acepta los riesgos que puedan derivarse
                de la experiencia de viaje. Tambi&eacute;n podemos utilizar
                servidores y otros equipos inform&aacute;ticos situados en
                cualquier jurisdicci&oacute;n del mundo para la
                prestaci&oacute;n de cualquier parte de la Plataforma.
            </p>
            <p className='p3'>
                Excluimos toda responsabilidad por p&eacute;rdidas o
                da&ntilde;os causados por errores de transmisi&oacute;n, fallos
                t&eacute;cnicos, aver&iacute;as, interrupciones de la actividad
                o intervenciones ilegales en las redes de transmisi&oacute;n,
                sistemas inform&aacute;ticos/ordenadores del Usuario o de
                terceros (incluidos los sistemas de dominio p&uacute;blico).
            </p>
            <p className='p3'>
                <strong>Terminaci&oacute;n</strong>
            </p>
            <p className='p3'>
                Podemos cancelar o suspender su Cuenta inmediatamente, sin
                previo aviso ni responsabilidad, por cualquier motivo,
                incluyendo, sin limitaci&oacute;n, si incumple estos
                T&eacute;rminos y Condiciones.
            </p>
            <p className='p3'>
                En caso de rescisi&oacute;n, su derecho a utilizar el Servicio
                cesar&aacute; inmediatamente. Si desea cancelar Su Cuenta, puede
                simplemente dejar de utilizar el Servicio.
            </p>
            <p className='p3'>
                <strong>Publique</strong>
            </p>
            <p className='p3'>
                En la medida m&aacute;xima permitida por la legislaci&oacute;n
                aplicable, por el presente documento usted nos exime, absuelve y
                libera de cualquier otro modo a nosotros, a nuestra empresa
                matriz, filiales y subsidiarias y a cada uno de sus respectivos
                directivos, directores, accionistas, miembros, socios, abogados,
                empleados, contratistas independientes, proveedores de
                telecomunicaciones y agentes (colectivamente, las "Partes
                indemnizadas"), de todas y cada una de las alegaciones, cargos,
                acusaciones, deudas, causas de acci&oacute;n, reclamaciones y
                p&eacute;rdidas, relacionadas de cualquier modo con el uso o las
                actividades relacionadas con el uso del Sitio, la Plataforma,
                cualquier Cuenta y cualquier servicio o Contenido de Terceros
                proporcionado a trav&eacute;s del Sitio, la Plataforma o
                cualquier Cuenta, incluidas, a t&iacute;tulo meramente
                enunciativo y no limitativo, las reclamaciones relacionadas con
                lo siguiente negligencia, negligencia grave, interferencia
                intencionada con el contrato o la relaci&oacute;n comercial
                ventajosa, difamaci&oacute;n, privacidad, publicidad,
                tergiversaci&oacute;n, identidades falsas, actos fraudulentos de
                terceros, invasi&oacute;n de la privacidad, divulgaci&oacute;n
                de Informaci&oacute;n Personal, transacciones, compras o
                funcionalidad fallidas de la Plataforma, indisponibilidad del
                Sitio, la Plataforma, el Contenido de Terceros o cualquier
                Cuenta, sus funciones y cualquier otro fallo t&eacute;cnico que
                pueda resultar en la inaccesibilidad al Sitio, la Plataforma,
                los Contenidos de Terceros o cualquier Cuenta, o cualquier
                reclamaci&oacute;n basada en responsabilidad indirecta por
                agravios cometidos por usted encontrados o transaccionados a
                trav&eacute;s del Sitio, la Plataforma, los Contenidos de
                Terceros y cualquier Cuenta, incluyendo, pero sin limitarse a,
                fraude, pirater&iacute;a inform&aacute;tica, robo o uso indebido
                de Informaci&oacute;n Personal, asalto, agresi&oacute;n, acoso,
                violaci&oacute;n, enga&ntilde;o, perjurio, homicidio
                involuntario o asesinato.
                <span className='Apple-converted-space'>&nbsp; </span>La lista
                anterior pretende ser meramente ilustrativa y no exhaustiva de
                los tipos o categor&iacute;as de reclamaciones liberadas por
                nosotros.<span className='Apple-converted-space'>&nbsp; </span>
                Las partes pretenden que esta exoneraci&oacute;n se interprete
                en sentido amplio a nuestro favor, por lo que cualquier
                ambig&uuml;edad se interpretar&aacute; de forma que se exonere
                de las reclamaciones m&aacute;s amplias.
                <span className='Apple-converted-space'>&nbsp; </span>Esta
                exoneraci&oacute;n pretende ser una exoneraci&oacute;n completa
                de reclamaciones, y las partes reconocen la naturaleza
                legalmente vinculante de esta disposici&oacute;n, y la
                naturaleza de los derechos a los que se renuncia en
                relaci&oacute;n con la misma.
            </p>
            <p className='p3'>
                <strong>Limitaci&oacute;n de responsabilidad</strong>
            </p>
            <p className='p3'>
                SafeTravy no es responsable de la informaci&oacute;n disponible
                a trav&eacute;s de los productos SafeTravy o integrada en ellos.
                En consecuencia, SafeTravy no se hace responsable de la
                exactitud, integridad, calidad, legalidad y fiabilidad de la
                informaci&oacute;n ni de los resultados que se obtengan mediante
                dicha informaci&oacute;n.
            </p>
            <p className='p3'>
                Los Servicios SafeTravy constituyen herramientas de uso y apoyo
                a la gesti&oacute;n y evaluaci&oacute;n de riesgos. SafeTravy no
                puede garantizar que toda la informaci&oacute;n y las
                actualizaciones sean completas, adecuadas y est&eacute;n libres
                de errores. Los usuarios siempre deben evaluar los resultados y
                los datos del servicio de forma individual. SafeTravy pretende
                reducir el riesgo para los usuarios, pero en ning&uacute;n caso
                ser&aacute; responsable de la seguridad de los usuarios y
                SafeTravy no asume ninguna responsabilidad por p&eacute;rdidas,
                lesiones corporales o muerte de los usuarios.
            </p>
            <p className='p3'>
                EN NING&Uacute;N CASO Y BAJO NINGUNA TEOR&Iacute;A
                JUR&Iacute;DICA (EXTRACONTRACTUAL, CONTRACTUAL, DE
                RESPONSABILIDAD OBJETIVA O DE OTRO TIPO), NI NOSOTROS NI NINGUNO
                DE LOS INDEMNIZADOS SEREMOS RESPONSABLES ANTE USTED NI ANTE
                NINGUNA OTRA PERSONA POR NING&Uacute;N DA&Ntilde;O DERIVADO DEL
                USO O MAL USO, O DE LA IMPOSIBILIDAD DE USO, DE LA PLATAFORMA,
                EL SITIO, LOS CONTENIDOS DE TERCEROS O CUALQUIER CUENTA,
                INDEPENDIENTEMENTE DE QUE DICHOS DA&Ntilde;OS SEAN DIRECTOS,
                INDIRECTOS, ESPECIALES, FORTUITOS O CONSECUENTES DE CUALQUIER
                CAR&Aacute;CTER, P&Eacute;RDIDA DE INFORMACI&Oacute;N,
                INTERRUPCI&Oacute;N DE LA ACTIVIDAD EMPRESARIAL O LUCRO CESANTE,
                P&Eacute;RDIDA DE AHORROS O P&Eacute;RDIDA DE DATOS, O
                RESPONSABILIDADES BAJO CUALQUIER CONTRATO, NEGLIGENCIA,
                RESPONSABILIDAD ESTRICTA U OTRA TEOR&Iacute;A QUE SURJA DE O
                EST&Eacute; RELACIONADA DE CUALQUIER MANERA CON EL SITIO, LA
                PLATAFORMA, EL CONTENIDO DE TERCEROS O CUALQUIER CUENTA O POR
                CUALQUIER RECLAMACI&Oacute;N O DEMANDA DE CUALQUIER TERCERO,
                INCLUSO SI SAB&Iacute;AMOS O TEN&Iacute;AMOS RAZONES PARA SABER
                DE LA POSIBILIDAD DE TALES DA&Ntilde;OS, RECLAMACI&Oacute;N O
                DEMANDA SI LA RENUNCIA DE RESPONSABILIDAD ANTERIOR SE
                CONSIDERARA INV&Aacute;LIDA O INEFICAZ. ALGUNAS JURISDICCIONES
                NO PERMITEN LA EXCLUSI&Oacute;N O LIMITACI&Oacute;N DE
                DETERMINADAS GARANT&Iacute;AS Y/O RESPONSABILIDADES, POR LO QUE
                ES POSIBLE QUE ALGUNAS DE LAS LIMITACIONES O EXCLUSIONES
                ANTERIORES NO SEAN APLICABLES EN SU CASO.
            </p>
            <p className='p3'>
                EN NING&Uacute;N CASO NUESTRA RESPONSABILIDAD,
                INDEPENDIENTEMENTE DE LA FORMA DE LA ACCI&Oacute;N Y DE LOS
                DA&Ntilde;OS SUFRIDOS POR USTED, EXCEDER&Aacute; LAS TARIFAS DE
                SUSCRIPCI&Oacute;N AGREGADAS M&Aacute;S ALTAS PAGADAS POR USTED
                A NOSOTROS EN RELACI&Oacute;N CON LA PLATAFORMA, O EL SITIO, O
                500 D&Oacute;LARES ESTADOUNIDENSES, LO QUE SEA MAYOR.
            </p>
            <p className='p3'>
                No seremos responsables del incumplimiento de cualquiera de
                nuestras obligaciones en virtud de las presentes Condiciones
                debido a acontecimientos que escapen a nuestro control, y el
                plazo previsto para el cumplimiento de dichas obligaciones se
                prorrogar&aacute; por un per&iacute;odo de tiempo igual a la
                duraci&oacute;n de tales acontecimientos.
                <span className='Apple-converted-space'>&nbsp; </span>Los
                acontecimientos que escapan a nuestro control incluyen, entre
                otros, casos fortuitos, guerras, disturbios, incendios
                provocados, embargos, conmociones civiles, huelgas, conflictos
                laborales, fallos en los equipos, fallos bancarios, colapso o
                fluctuaciones del mercado de divisas virtuales, fallos en el
                procesamiento de transacciones con tarjeta de cr&eacute;dito o
                d&eacute;bito, huelgas, incendios, inundaciones, terremotos,
                huracanes, tormentas tropicales u otros desastres naturales o
                siniestros, escasez de mano de obra o material, escasez de
                transporte, instalaciones, combustible, energ&iacute;a,
                regulaciones o restricciones gubernamentales, actos de autoridad
                civil o militar o terrorismo, cortes de fibra, condiciones
                meteorol&oacute;gicas, infracciones o incumplimientos por parte
                de terceros, problemas t&eacute;cnicos, incluidos bloqueos de
                hardware y software y otros fallos de funcionamiento, fallos de
                la infraestructura de telecomunicaciones o servicios de
                informaci&oacute;n, pirater&iacute;a inform&aacute;tica, SPAM o
                fallos de cualquier ordenador, servidor o interrupciones de
                software a causa o provocados por vandalismo, robo, cortes del
                servicio telef&oacute;nico, cortes de electricidad,
                interrupciones de Internet, virus y fallos mec&aacute;nicos,
                el&eacute;ctricos o de comunicaciones.
            </p>
            <p className='p3'>
                <strong>Modificaciones de las presentes condiciones</strong>
            </p>
            <p className='p3'>
                Nos reservamos el derecho, a nuestra entera discreci&oacute;n,
                de modificar o sustituir estas Condiciones en cualquier momento.
                Si una revisi&oacute;n es sustancial, haremos todo lo
                razonablemente posible para notificarlo con al menos 30
                d&iacute;as de antelaci&oacute;n a la entrada en vigor de los
                nuevos t&eacute;rminos. Lo que constituya un cambio sustancial
                se determinar&aacute; a nuestra entera discreci&oacute;n.
            </p>
            <p className='p3'>
                Al continuar accediendo o utilizando nuestro Servicio
                despu&eacute;s de que dichas revisiones entren en vigor, usted
                acepta quedar vinculado por los t&eacute;rminos revisados. Si no
                est&aacute; de acuerdo con las nuevas condiciones, en su
                totalidad o en parte, deje de utilizar el sitio web y el
                Servicio.
            </p>
            <p className='p3'>
                <strong>General</strong>
            </p>
            <p className='p3'>
                Las presentes Condiciones, incluida la Pol&iacute;tica de
                privacidad y otras pol&iacute;ticas incorporadas en el presente
                documento, constituyen el acuerdo &iacute;ntegro y &uacute;nico
                entre usted y la Empresa en relaci&oacute;n con el objeto de
                estas Condiciones, y sustituyen a todos y cada uno de los
                acuerdos, manifestaciones, garant&iacute;as y entendimientos
                anteriores o contempor&aacute;neos, escritos u orales, en
                relaci&oacute;n con el objeto de estas Condiciones. Si alguna de
                las disposiciones de estas Condiciones resultara ilegal, nula o
                inaplicable por cualquier motivo, dicha disposici&oacute;n se
                considerar&aacute; separable de estas Condiciones y no
                afectar&aacute; a la validez y aplicabilidad de las
                disposiciones restantes. La Empresa no podr&aacute; cambiar,
                renunciar ni modificar las presentes Condiciones salvo en los
                casos previstos en el presente documento. Usted no podr&aacute;
                ceder, transferir, delegar ni sublicenciar ninguna de las
                presentes Condiciones, ni ning&uacute;n derecho,
                obligaci&oacute;n o recurso derivados de las mismas, salvo con
                nuestro consentimiento previo por escrito, y cualquier intento
                de cesi&oacute;n, transferencia, delegaci&oacute;n o sublicencia
                ser&aacute; nulo y sin efecto. Ninguna renuncia por cualquiera
                de las partes de cualquier incumplimiento o falta en virtud del
                presente se considerar&aacute; una renuncia de cualquier
                incumplimiento o falta anterior o posterior.
                <span className='Apple-converted-space'>&nbsp; </span>Cualquier
                encabezamiento, t&iacute;tulo o t&iacute;tulo de secci&oacute;n
                contenidos en estas Condiciones se insertan &uacute;nicamente
                por comodidad y no definen ni explican en modo alguno ninguna
                secci&oacute;n o disposici&oacute;n de las mismas.
            </p>
            <p className='p3'>
                <strong>
                    Legislaci&oacute;n aplicable y resoluci&oacute;n de litigios
                </strong>
            </p>
            <p className='p3'>
                Todas las disputas, desacuerdos o reclamaciones que surjan en
                virtud del presente acuerdo o en relaci&oacute;n con el mismo,
                incluidas las relativas a su celebraci&oacute;n,
                interpretaci&oacute;n, ejecuci&oacute;n, violaci&oacute;n,
                rescisi&oacute;n o invalidez, ser&aacute;n resueltas por el
                Tribunal de Arbitraje Comercial Internacional de la
                C&aacute;mara de Comercio e Industria de Ucrania de conformidad
                con su Reglamento.
            </p>
            <p className='p3'>
                La ley que rige este acuerdo es la ley sustantiva de Gibraltar.
            </p>
            <p className='p3'>
                El Tribunal de Arbitraje est&aacute; compuesto por un
                &aacute;rbitro &uacute;nico. El lugar de reuni&oacute;n del
                Tribunal de Arbitraje es la ciudad de Kyiv, Ucrania. El idioma
                de los procedimientos de arbitraje es el ingl&eacute;s o el
                ucraniano.
            </p>
            <p className='p3'>
                <strong>Contáctenos</strong>
            </p>
            <p className='p3'>
                Si tiene alguna pregunta sobre estos Términos y Condiciones,
                puede comunicarse con nosotros:
            </p>
            <p className='p3'>
                Por correo electrónico:{' '}
                <span style={{ color: 'blue', textDecoration: 'underline' }}>
                    team@safetravy.com
                </span>
            </p>
            <p className='p3'>
                Por teléfono: <a href='tel:+350 54095364'>+350 54095364</a>
            </p>

            <p className='p2'>&nbsp;</p>
        </>
    ),
}
