import { SettingsNotiificationsWidget } from '@/modules/notifications/widgets'
import { FooterPrivacy } from '../../components/footer-privacy'
import { AccountWidget } from '../../widgets'
import { UserSubscribeWidget } from '@/modules/subscribe/widgets'
import { Subscribe } from '@/modules/subscribe/pages'
import { AccountHeader } from '../../components/account-header'
import styles from './styles.module.css'
import { MobileAccountTabs } from '../../components'
import { ChatBotSettingsWidget } from '@/modules/chat-bot/widgets'
import { useAccountStore } from '@/store'
import { SubscriptionType } from '@/modules/subscribe/typing/enums'
import { useEventsListener } from '@/core/hooks'
import { useState } from 'react'

export const AccountPage = () => {
    const [isShow, setIsShow] = useState(false)

    useEventsListener(
        'account',
        (data: any) => {
            setIsShow(data?.isShow)
        },
        [],
    )
    const { account } = useAccountStore()

    if (!isShow) {
        return null
    }
    return (
        <div
            style={{
                height: '100dvh',
                zIndex: 999,
                top: 0,
                position: 'absolute',
                width: '100dvw',
            }}>
            <div className={styles.layout}>
                <AccountHeader />
                <MobileAccountTabs />
                <div className={styles.content}>
                    <AccountWidget />
                    <SettingsNotiificationsWidget />
                    {account?.subscription?.type === SubscriptionType.Premium ||
                    account?.subscription?.type ===
                        SubscriptionType.Premium_3m ? (
                        <ChatBotSettingsWidget />
                    ) : null}

                    <UserSubscribeWidget />

                    <FooterPrivacy style={{ marginTop: 20 }} />
                </div>
            </div>
            <Subscribe />
        </div>
    )
}
