import logo from '@/assets/images/logo.svg'
import styles from './styles.module.css'
import { GroupButtonsAtom } from './atoms'
import { PrimaryButton } from '../buttons'
import { Icon } from '../icons'
import { useTranslation } from 'react-i18next'
import { SubscriptionType } from '@/modules/subscribe/typing/enums'
import { useAccountStore } from '@/store'

export const IntroStep = ({
    index,
    step,
    backProps,
    primaryProps,
    skipProps,
    isLastStep,
}: any) => {
    const { t } = useTranslation()
    const { account } = useAccountStore()
    const lengthSteps =
        account.subscription.type === SubscriptionType.Basic ||
        account.subscription.type === SubscriptionType.Basic_3m
            ? 8
            : 9
    const handleNext = (e: any) => {
        primaryProps.onClick(e)
    }
    return (
        <div className={styles.container}>
            <Icon
                name='x'
                size={24}
                onClick={skipProps.onClick}
                className={styles.cross}
            />

            <div className={styles.content}>
                <img src={logo} style={{ width: 85, height: 85 }} />

                <div>
                    <p className={styles.title}>{t(`${step.title}`)}</p>
                    <p className={styles.txt_contnent}>
                        {t(`${step.content}`)}
                    </p>
                </div>
            </div>

            <div className={styles.actions}>
                <GroupButtonsAtom
                    labelBack={'Back'}
                    labelNext={isLastStep ? 'Done' : 'Next'}
                    isFirst={index === 0}
                    goBack={backProps.onClick}
                    next={handleNext}
                    isLast={lengthSteps === index + 1}
                />

                <p>
                    {index + 1}/{lengthSteps}
                </p>

                <PrimaryButton
                    className={styles.skip_btn}
                    label={lengthSteps === index + 1 ? 'Done' : 'Quit'}
                    onClick={skipProps.onClick}
                    labelStyle={{ fontSize: 14 }}
                />
            </div>
        </div>
    )
}
