export enum TypeNotification {
    Email = 'email',
    Push = 'push',
}
export enum TypeCategoryNotifications {
    GeopoliticalConflicts = 'geop',
    InternalSocialTensions = 'ist',
    PotentialInsecurity = 'pi',
    ClimateEnvironmental = 'clr',
    MedicalRisk = 'mdr',
}
