import { useNavigate } from 'react-router-dom'
import styles from './styles.module.css'
import { GoBack } from '@/core/components'
import fullLogo from '@/assets/images/full-logo.svg'
import { termsTranslates } from '../../config/terms.translates.config'
import Linkify from 'react-linkify'
import { useTranslation } from 'react-i18next'

export const TermsConditionsPage = () => {
    const lang = localStorage.getItem('lang')
    const { t } = useTranslation()

    const navigate = useNavigate()
    const goMainPage = () => {
        navigate('/')
    }

    const goBack = () => {
        navigate(-1)
    }
    return (
        <div className={styles.layout}>
            <div className={styles.container} id='account-header'>
                <div className={styles.left_container} id='back-button'>
                    <GoBack onClick={() => goBack()} />

                    <img
                        src={fullLogo}
                        className={styles.logo}
                        onClick={goMainPage}
                    />
                    <p className={styles.title}>{t('terms')}</p>
                </div>
            </div>
            <div className={styles.content}>
                <div className={styles.terms}>
                    <Linkify>{termsTranslates[lang]}</Linkify>
                </div>
            </div>
        </div>
    )
}
