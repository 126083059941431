import { Icon } from '@/core/components'
import styles from './styles.module.css'
import safeGuido from '@/assets/images/travy.svg'

interface IProps {
    closeChat: () => void
}
export const HeaderChatAtom = ({ closeChat }: IProps) => {
    return (
        <div className={styles.container}>
            <img src={safeGuido} className={styles.logo} />
            <Icon name='x' size={24} color='#717375' onClick={closeChat} />
        </div>
    )
}
