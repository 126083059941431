import conflicts_illustration from '@/assets/images/conflicts_illustration.png'
import social_illustration from '@/assets/images/social_illustration.png'
import health_illustration from '@/assets/images/health_illustration.png'
import climate_illustration from '@/assets/images/climate_illustration.png'
import requirements_illustration from '@/assets/images/entry_illustration.png'
import legenda_illustration from '@/assets/images/legenda_illustration.png'
import map_illustration from '@/assets/images/map_illustration.png'
import chat_bot_illustration from '@/assets/images/chat_bot_illustration.png'
import profile_illustration from '@/assets/images/profile_illustration.png'
export interface IMobileStep {
    title: string
    illustarionPath: string
    content: string
}

export const mobileStepsConfig: IMobileStep[] = [
    {
        title: 'conflicts',
        illustarionPath: conflicts_illustration,
        content: 'conflictsInstruction',
    },
    {
        title: 'legend',
        illustarionPath: legenda_illustration,
        content: 'legendInstruction',
    },
    {
        title: 'map',
        illustarionPath: map_illustration,
        content: 'mapInstructions',
    },
    {
        title: 'social',
        illustarionPath: social_illustration,
        content: 'socialIstruction',
    },
    {
        title: 'medical',
        illustarionPath: health_illustration,
        content: 'healthInstruction',
    },
    {
        title: 'climate',
        illustarionPath: climate_illustration,
        content: 'climateInstruction',
    },
    {
        title: 'requirements',
        illustarionPath: requirements_illustration,
        content: 'requirementsInstruction',
    },
    {
        title: 'chatBot',
        illustarionPath: chat_bot_illustration,
        content: 'chatBotInstruction',
    },
    {
        title: 'personalAccount',
        illustarionPath: profile_illustration,
        content: 'accountInstruction',
    },
]
