import { Icon } from '@/core/components'
import styles from './styles.module.css'
import { legendRiskConfig } from './config'
import { LegendItem } from './atoms/legend-item'
import { useState } from 'react'
import { useResizeWindow } from '@/core/hooks'
import _ from 'lodash'
import { MobileLegendRisk } from './mobile-legend-risk'
import { useCategoryRiskStore, useLanguageStore } from '@/store'
import { CategoryRisks, Languages } from '@/core/typing'
import { TabletLegendRisk } from './tablet-legend-risk'
import { useTranslation } from 'react-i18next'

export const LegendRisk = () => {
    const { t } = useTranslation()
    const { lang } = useLanguageStore()

    const { width: widthWindow } = useResizeWindow()
    const { active } = useCategoryRiskStore()
    const legend = localStorage.getItem('LEGEND')
    const [showLegend, setHowLegend] = useState(JSON.parse(legend))
    const toggleLegend = () => {
        setHowLegend(() => !showLegend)
        localStorage.setItem('LEGEND', JSON.stringify(!showLegend))
    }

    const positionClassLegend = {
        [Languages.DE]: styles.position_de,
        [Languages.EN]: styles.position_en,
        [Languages.ES]: styles.position_en,
        [Languages.FR]: styles.position_fr,
    }
    if (active?.category === CategoryRisks.Requirements) {
        return null
    }
    return (
        <>
            <div className={styles.legend_btn}>
                <Icon
                    className={styles.icon_legend}
                    onClick={toggleLegend}
                    name={showLegend ? 'x' : 'legend'}
                    size={24}
                    color='#0F0E0E'
                />
            </div>

            {widthWindow <= 767 ? (
                <MobileLegendRisk
                    showLegend={showLegend}
                    toggleLegend={toggleLegend}>
                    {legendRiskConfig.map(it => {
                        return (
                            <LegendItem
                                label={t(`${it.label}`)}
                                bgColor={it.color}
                            />
                        )
                    })}
                </MobileLegendRisk>
            ) : null}

            <TabletLegendRisk
                showLegend={showLegend}
                toggleLegend={toggleLegend}
            />

            <div
                id='legend'
                className={`${styles.legend} ${positionClassLegend[lang]} ${showLegend ? styles.slide_in : styles.slide_out}`}>
                {legendRiskConfig.map(it => {
                    return (
                        <LegendItem
                            label={t(`${it.label}`)}
                            bgColor={it.color}
                        />
                    )
                })}
            </div>
        </>
    )
}
