import { LegendItem } from './atoms/legend-item'
import { legendRiskConfig } from './config'
import styles from './styles.module.css'

interface IProps {
    showLegend: boolean
    toggleLegend: () => void
}
export const TabletLegendRisk = ({ showLegend }: IProps) => {
    return (
        <div
            id='tablet-legend'
            className={`${showLegend ? styles.tabled_legend : styles.hide}
            `}>
            <p className={styles.tablet_label}>Legend</p>
            <>
                {legendRiskConfig.map(it => {
                    return <LegendItem label={it.label} bgColor={it.color} />
                })}
            </>
        </div>
    )
}
