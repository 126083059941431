import { useEffect, useMemo, useState } from 'react'
import { Icon } from '../icons'
import { MenuItem } from './atoms'
import indicator from '@/assets/images/indicator.svg'
import styles from './styles.module.css'
import { IMenu } from './typing'
import { CategoryRisks, Languages } from '@/core/typing'
import _ from 'lodash'
import { appEvents } from '@/core/events'
import { useAccountStore, useLanguageStore } from '@/store'
import { useResizeWindow } from '@/core/hooks'
import { useTranslation } from 'react-i18next'

interface IProps {
    indexMenu: number
    item: IMenu
    onClickMenu: (val: { category: string; subcategory?: string }) => void
    activeCategory: { category: string; subcategory?: string }
    id?: string
    close: () => void
}
export const Menu = ({
    item,
    onClickMenu,
    activeCategory,
    indexMenu,
    id,
    close,
}: IProps) => {
    const { t } = useTranslation()
    const { lang } = useLanguageStore()
    const isOpenBar: boolean = JSON.parse(localStorage.getItem('BAR'))
    const { account } = useAccountStore()

    const [prevCategory, setPrevCategory] = useState<CategoryRisks>()

    const [open, setOpen] = useState(false)

    const { width: widthWindow } = useResizeWindow()
    const isActiveCategory = activeCategory?.category === item.key

    const manageOpenMenu = () => {
        if (!isOpenBar) {
            setOpen(false)
        } else if (
            activeCategory?.subcategory &&
            isActiveCategory &&
            isOpenBar
        ) {
            setOpen(true)
        }
    }

    const manageCloseMenu = () => {
        if (
            (prevCategory !== activeCategory?.category &&
                !activeCategory?.subcategory) ||
            !account?.subscription
        ) {
            setOpen(false)
        }
    }

    useEffect(() => {
        manageOpenMenu()
        manageCloseMenu()
    }, [activeCategory, isOpenBar, prevCategory, account?.subscription])

    const toggleOpen = () => {
        if (!account || !account?.subscription) {
            appEvents.emit('subsribe', {
                isShow: true,
                method: !account ? 'register' : 'buy',
            })
            close()
        } else {
            onClickMenu({ category: item.key })
            setPrevCategory(item.key as CategoryRisks)
            setOpen(prev => !prev)
        }
    }

    const onClickItem = (val: string) => {
        if (!account || !account?.subscription) {
            appEvents.emit('subsribe', {
                isShow: true,
                method: !account ? 'register' : 'buy',
            })
            close()
        } else {
            onClickMenu({ category: item.key, subcategory: val })
        }
    }

    const heightTranslatesWEb = lang !== Languages.EN ? 180 : 165

    const heightTranslatesMob = lang !== Languages.EN ? 145 : 130

    const heightDuobleMenu = widthWindow < 768 ? 100 : 120
    const heightList = (ind: number) => {
        if (!open) {
            return 0
        } else if ((open && ind == 2) || (open && ind == 3)) {
            return heightDuobleMenu
        }
        return widthWindow < 768 ? heightTranslatesMob : heightTranslatesWEb
    }

    const list = useMemo(() => {
        if (!item?.items || (!isOpenBar && !activeCategory?.subcategory))
            return null
        return (
            <div
                style={{ height: heightList(indexMenu) }}
                className={
                    !open
                        ? `${styles.list_items} ${styles.collapsed}`
                        : `${styles.list_items}`
                }>
                {item?.items.map((it, index) => (
                    <MenuItem
                        isActive={activeCategory?.subcategory === it.key}
                        key={it.key}
                        index={index}
                        item={it}
                        onClick={() => onClickItem(it.key)}
                    />
                ))}
            </div>
        )
    }, [
        isOpenBar,
        open,
        activeCategory,
        prevCategory,
        indexMenu,
        account?.subscription,
    ])

    const activeIndicator = () => {
        if (!isActiveCategory) return null
        return <img src={indicator} className={styles.indicator} />
    }

    const arrowMenu = () => {
        if (!item?.items) return null
        return (
            <div
                className={`${styles.arrow} ${
                    isActiveCategory && open ? styles.up : styles.down
                } ${isOpenBar ? styles.show : ''}`}>
                <Icon
                    name='arrow-up'
                    size={20}
                    color={isActiveCategory ? '#0F0E0E' : '#717375'}
                />
            </div>
        )
    }

    return (
        <div className={`${styles.collapsible_list}`}>
            <div
                id={id}
                onClick={toggleOpen}
                className={styles.collapseble_item}>
                <div className={styles.menu_label_container}>
                    <>{activeIndicator()}</>
                    <Icon
                        onClick={_.noop}
                        name={item.iconName}
                        size={24}
                        color={isActiveCategory ? '#0F0E0E' : '#717375'}
                    />

                    <p
                        style={{
                            color: isActiveCategory ? '#0F0E0E' : '#717375',
                        }}
                        className={
                            !isOpenBar ? styles.collapsed : styles.label_menu
                        }>
                        {t(`${item?.label}`)}
                    </p>
                </div>
                <>{arrowMenu()}</>
            </div>
            <>{list}</>
        </div>
    )
}
