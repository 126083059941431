import { Icon } from '@/core/components'
import styles from './styles.module.css'
import { useTranslation } from 'react-i18next'

interface IProps {
    isOpen: boolean
}
export const ContactUs = ({ isOpen }: IProps) => {
    const { t } = useTranslation()

    const openPhone = () => {
        window.location.href = `tel:+350 54095364`
    }

    const sendEmail = () => {
        window.location.href = `mailto:team@safetravy.com`
    }
    return (
        <div className={!isOpen ? styles.column_icon : ''}>
            <p className={!isOpen ? styles.hidden : styles.contact_us_title}>
                {t('contactUsTitle')}
            </p>

            <Icon
                onClick={sendEmail}
                name='mail'
                color='#717375'
                size={24}
                className={isOpen ? styles.hidden : styles.contact_us_item_icon}
            />

            <p
                onClick={sendEmail}
                className={!isOpen ? styles.hidden : styles.contact_us_item}>
                team@safetravy.com
            </p>
            <Icon
                onClick={openPhone}
                name='phone'
                color='#717375'
                size={24}
                className={isOpen ? styles.hidden : ''}
            />
            <p
                onClick={openPhone}
                className={!isOpen ? styles.hidden : styles.contact_us_item}>
                +350 54095364
            </p>
        </div>
    )
}
