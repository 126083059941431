import { SelectCountry } from '@/core/widgets'
import styles from './styles.module.css'
import { Icon, PrimaryButton } from '@/core/components'
import { useAccountStore } from '@/store'
import { SubscriptionType } from '@/modules/subscribe/typing/enums'
import { validateRequirements } from '../../validators'
import { useForm } from '@/core/hooks'
import { appEvents } from '@/core/events'
import * as Sentry from '@sentry/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface ISearchRequirements {
    from: string
    to: string
    citizenship?: string
}

interface IProps {
    clasNameContainer?: string
}
export const SearchRequrementsWidget = ({
    clasNameContainer = styles.container,
}: IProps) => {
    const { t } = useTranslation()
    const { account } = useAccountStore()
    const subscribeType = account?.subscription?.type
    const existSubscribeActive = !account?.subscription
    const [counriesName, setCountriesName] = useState<{
        fromName: string
        toName: string
    }>()

    const { setField, errors, values, onSubmit } = useForm<ISearchRequirements>(
        {},
        validateRequirements,
    )
    const onChange = (key: keyof ISearchRequirements, val: string) => {
        setField(key, val)
    }

    const submit = async () => {
        try {
            appEvents.emit('requrements', {
                isShow: true,
                data: {
                    from: { code: values.from, name: counriesName?.fromName },
                    to: { code: values.to, name: counriesName?.toName },
                    citizenship: values?.citizenship,
                },
            })
        } catch (error) {
            Sentry.captureException(error)
        }
    }
    return (
        <div className={clasNameContainer}>
            <div className={styles.form}>
                <SelectCountry
                    valueHidden={!values?.from}
                    onSelectCountry={val => {
                        onChange('from', val?.countryCode)
                        setCountriesName({
                            ...counriesName,
                            fromName: val?.locationName,
                        })
                    }}
                    label={t('fromCountry')}
                    error={errors.from}
                    chooseCountries={[values?.to]}
                />

                <SelectCountry
                    valueHidden={!values?.to}
                    onSelectCountry={val => {
                        onChange('to', val?.countryCode)
                        setCountriesName({
                            ...counriesName,
                            toName: val?.locationName,
                        })
                    }}
                    label={t('toCountry')}
                    error={errors.to}
                    chooseCountries={[values?.from]}
                />

                {subscribeType === SubscriptionType.Premium ||
                subscribeType === SubscriptionType.Premium_3m ? (
                    <SelectCountry
                        onSelectCountry={val => {
                            onChange('citizenship', val?.countryCode)
                        }}
                        label={t('citіzenship')}
                    />
                ) : null}

                <PrimaryButton
                    disabled={existSubscribeActive}
                    label={t('search')}
                    leftIcon={<Icon name='search' size={24} color='#0F0E0E' />}
                    onClick={() => onSubmit(submit)}
                />
            </div>
        </div>
    )
}
