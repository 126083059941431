import { IMenu } from '@/core/components/menu/typing'
import { CategoryRisks, SubcategoryRisks } from '@/core/typing'

export const categoriesIconConfig: any = {
    [CategoryRisks.Owerview]: 'overview',
    [CategoryRisks.Conflicts]: 'boom',
    [SubcategoryRisks.Geopolitical]: 'boom',
    [SubcategoryRisks.InternalSocialTensions]: 'boom',
    [SubcategoryRisks.PotentialDanger]: 'boom',

    [CategoryRisks.Social]: 'chart',
    [SubcategoryRisks.StandardOfLiving]: 'chart',
    [SubcategoryRisks.WaterSupplies]: 'chart',
    [SubcategoryRisks.HumanCapitalDevelopment]: 'chart',

    [CategoryRisks.Medical]: 'ambulance',
    [SubcategoryRisks.MedicalSituation]: 'ambulance',
    [SubcategoryRisks.PandemicsAndEpidemics]: 'ambulance',

    [CategoryRisks.Climate]: 'lines',
    [SubcategoryRisks.Weather]: 'lines',
    [SubcategoryRisks.SeaLevelRise]: 'lines',

    [CategoryRisks.Requirements]: 'file',
}
export const risksConfig: IMenu[] = [
    {
        label: 'conflicts',
        key: CategoryRisks.Conflicts,
        iconName: 'boom',
        items: [
            {
                label: 'geoConflicts',
                key: SubcategoryRisks.Geopolitical,
            },
            {
                label: 'internalConflicts',
                key: SubcategoryRisks.InternalSocialTensions,
            },
            {
                label: 'dangerPotential',
                key: SubcategoryRisks.PotentialDanger,
            },
        ],
    },
    {
        label: 'social',
        key: CategoryRisks.Social,
        iconName: 'chart',
        items: [
            {
                label: 'standardLiving',
                key: SubcategoryRisks.StandardOfLiving,
            },
            {
                label: 'foodDrinking',
                key: SubcategoryRisks.WaterSupplies,
            },
            {
                label: 'humanCapitalDev',
                key: SubcategoryRisks.HumanCapitalDevelopment,
            },
        ],
    },
    {
        label: 'medical',
        key: CategoryRisks.Medical,
        iconName: 'ambulance',
        items: [
            {
                label: 'medicalSituation',
                key: SubcategoryRisks.MedicalSituation,
            },
            {
                label: 'pandemics',
                key: SubcategoryRisks.PandemicsAndEpidemics,
            },
        ],
    },
    {
        label: 'climate',
        key: CategoryRisks.Climate,
        iconName: 'lines',
        items: [
            { label: 'weather', key: SubcategoryRisks.Weather },
            {
                label: 'seaLevel',
                key: SubcategoryRisks.SeaLevelRise,
            },
        ],
    },
    {
        label: 'requirements',
        key: CategoryRisks.Requirements,
        iconName: 'file',
    },
]

export const menuLabels: any = {
    [SubcategoryRisks.SeaLevelRise]: 'seaLevel',
    [SubcategoryRisks.Geopolitical]: 'geoConflicts',
    [SubcategoryRisks.InternalSocialTensions]: 'internalConflicts',
    [SubcategoryRisks.PotentialDanger]: 'dangerPotential',
    [SubcategoryRisks.StandardOfLiving]: 'standardLiving',
    [SubcategoryRisks.WaterSupplies]: 'foodDrinking',
    [SubcategoryRisks.HumanCapitalDevelopment]: 'humanCapitalDev',
    [SubcategoryRisks.MedicalSituation]: 'medicalSituation',
    [SubcategoryRisks.PandemicsAndEpidemics]: 'pandemics',
    [SubcategoryRisks.Weather]: 'weather',
    [CategoryRisks.Requirements]: 'requirements',
    [CategoryRisks.Climate]: 'climate',
    [CategoryRisks.Conflicts]: 'conflicts',
    [CategoryRisks.Social]: 'social',
    [CategoryRisks.Medical]: 'medical',
    [CategoryRisks.Owerview]: 'overview',
}
