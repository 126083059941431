import { providerGoogle, providerFacebook, auth } from '@/config/firebase'
import { signInWithPopup } from 'firebase/auth'
import { socialAuthReq } from '../api'
import { useSession } from '@/core/hooks'
import * as Sentry from '@sentry/react'

export const useSocialAuth = () => {
    const { saveSession } = useSession()
    const signInByGoogle = async () => {
        try {
            const resp: any = await signInWithPopup(auth, providerGoogle)
            const { data } = await socialAuthReq({
                idToken: resp._tokenResponse.idToken,
                deviceName: 'pc',
            })
            saveSession(data)
        } catch (error) {
            Sentry.captureException(error)
            //
        }
    }

    const signInByFacebook = async () => {
        try {
            const resp: any = await signInWithPopup(auth, providerFacebook)
            const { data } = await socialAuthReq({
                idToken: resp.oauthAccessToken,
                deviceName: 'pc',
            })
            saveSession(data)
        } catch (error: any) {
            Sentry.captureException(error)
        }
    }
    return { signInByGoogle, signInByFacebook }
}
