export enum SubscriptionType {
    Basic = 'b',
    Premium = 'p',
    Basic_3m = 'q',
    Premium_3m = 'v',
}

export enum SubscriptionStatus {
    Active = 'a',
    Trial = 't',
    Paused = 'p',
    Canceled = 'c',
    Unpaid = 'u',
}
