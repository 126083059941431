import { useState } from 'react'
import { TabBarAtom } from './atoms'
import './styles.css'
import { AccountTabKey } from '../../typing/enums'
import { AccountWidget } from '../../widgets'
import { SettingsNotiificationsWidget } from '@/modules/notifications/widgets'
import { UserSubscribeWidget } from '@/modules/subscribe/widgets'
import { ChatBotSettingsWidget } from '@/modules/chat-bot/widgets'

export const MobileAccountTabs = () => {
    const [tab, setTab] = useState<AccountTabKey>(AccountTabKey.Profile)

    const onChangeTab = (val: AccountTabKey) => {
        setTab(val)
    }

    return (
        <div className={'container_tabs'}>
            <TabBarAtom onClickTab={onChangeTab} activeTab={tab} />
            {tab === AccountTabKey.Profile ? (
                <div className={'content_tab'}>
                    <AccountWidget />
                </div>
            ) : null}

            {tab === AccountTabKey.Notifications ? (
                <div className={'content_tab'}>
                    <SettingsNotiificationsWidget />
                </div>
            ) : null}

            {tab === AccountTabKey.Subscribe ? (
                <div className={'content_tab'}>
                    <UserSubscribeWidget />
                </div>
            ) : null}

            {tab === AccountTabKey.ChatBot ? (
                <div className={'content_tab'}>
                    <ChatBotSettingsWidget />
                </div>
            ) : null}
        </div>
    )
}
