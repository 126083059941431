import logo from '@/assets/images/logo.svg'
import styles from './styles.module.css'
import { useCallback } from 'react'
import { appEvents } from '@/core/events'

export const GuidoBot = () => {
    const openGuidoBot = useCallback(() => {
        appEvents.emit('guidoBot', { isShow: true })
    }, [])

    return (
        <div className={styles.container} onClick={openGuidoBot} id={'travy'}>
            <img src={logo} className={styles.guido_logo} />
        </div>
    )
}
