import { useAccountStore } from '@/store'
import styles from './styles.module.css'
import { useRisksCategory, useSession } from '@/core/hooks'
import { useNavigate } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import { AccountGroupWidget, SearchCity, SearchCountry } from '@/core/widgets'
import { AuthStep } from '@/modules/auth/typing'
import { PrimaryButton } from '../../buttons'
import fullLogo from '@/assets/images/full-logo.svg'
import { Icon } from '../../icons'
import { categoriesIconConfig, menuLabels } from '@/config/risks.config'
import { CategoryRisks, SubcategoryRisks } from '@/core/typing'
import { useTranslation } from 'react-i18next'
import { ChooseLanguageWidget } from '@/core/widgets/chose-language'

export const MobileHeader = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { token } = useSession()
    const { account } = useAccountStore()
    const [showSearch, setShowSearch] = useState(false)
    const { active, getActiveCategory, setCategoryRisks } = useRisksCategory()

    useEffect(() => {
        getActiveCategory()
    }, [])

    useEffect(() => {
        setShowSearch(false)
    }, [active])
    const toggleShowSearch = () => {
        setShowSearch(!showSearch)
    }
    const getLabel = () => {
        if (!active) {
            return menuLabels[CategoryRisks.Owerview]
        } else if (active?.category && active?.subcategory) {
            return menuLabels[active?.subcategory]
        } else {
            return menuLabels[active?.category]
        }
    }

    const getIconCategory = () => {
        if (!active) {
            return categoriesIconConfig[CategoryRisks.Owerview]
        } else if (active?.category && active?.subcategory) {
            return categoriesIconConfig[active?.subcategory]
        } else {
            return categoriesIconConfig[active?.category]
        }
    }

    const headerGroup = useMemo(() => {
        if (token) {
            return <AccountGroupWidget />
        }
        return (
            <div className={styles.language_row}>
                <ChooseLanguageWidget hideFlag />

                <PrimaryButton
                    className={styles.sign_up}
                    label='SignUp'
                    onClick={() =>
                        navigate('auth', {
                            state: { step: AuthStep.Register },
                        })
                    }
                />
            </div>
        )
    }, [token, open, account])

    const searchCountry = useMemo(() => {
        if (!account || (account && !account?.subscription)) {
            return null
        } else if (active?.subcategory === SubcategoryRisks.Weather) {
            return (
                <div className={styles.search_field}>
                    <SearchCity />
                </div>
            )
        }
        return (
            <div className={styles.search_field}>
                <SearchCountry />
            </div>
        )
    }, [account, active])

    return (
        <div className={styles.container}>
            <div className={styles.header_row}>
                <img
                    className={styles.logo_guido}
                    src={fullLogo}
                    onClick={() => {
                        setCategoryRisks({
                            category: CategoryRisks.Owerview,
                            subcategory: null,
                        })
                        setShowSearch(false)
                    }}
                />

                {headerGroup}
            </div>

            <div className={styles.action_header_container}>
                <div className={styles.category_row}>
                    <Icon name={getIconCategory()} size={24} color='#0F0E0E' />
                    <p className={styles.category_label}>
                        {t(`${getLabel()}`)}
                    </p>
                </div>
                {token &&
                account &&
                active?.category !== CategoryRisks.Requirements &&
                active?.subcategory !== SubcategoryRisks.SeaLevelRise ? (
                    <Icon
                        onClick={toggleShowSearch}
                        name={showSearch ? 'x' : 'search'}
                        size={24}
                        color='#0F0E0E'
                    />
                ) : null}
            </div>

            <>{showSearch ? searchCountry : null}</>
        </div>
    )
}
