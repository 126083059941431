import { TypeCategoryNotifications } from '../typing'

export const iconSategoryConfig: any = {
    [TypeCategoryNotifications.GeopoliticalConflicts]: 'boom',
    [TypeCategoryNotifications.MedicalRisk]: 'thermometer',
    [TypeCategoryNotifications.InternalSocialTensions]: 'warning',
    [TypeCategoryNotifications.ClimateEnvironmental]: 'waves',
    [TypeCategoryNotifications.PotentialInsecurity]: 'shield',
}

export const colorRiskSubCategory: any = {
    [TypeCategoryNotifications.GeopoliticalConflicts]: '#FF9900',
    [TypeCategoryNotifications.ClimateEnvironmental]: '#2975B7',
    [TypeCategoryNotifications.MedicalRisk]: '#FF37BB',
    [TypeCategoryNotifications.InternalSocialTensions]: '#2975B7',
    [TypeCategoryNotifications.PotentialInsecurity]: '#FF37BB',
}
