/* eslint-disable no-console */
import { MapContainer, TileLayer } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import './styles.css'
import _ from 'lodash'
import { ZoomControlAtom } from './atoms'
import { ICategoryGroup, useSearchCityStore } from '@/store'
import { WeatherPupupWidget } from './widgets'
import { useEffect, useState } from 'react'
import { latLngBounds } from 'leaflet'
import * as Sentry from '@sentry/react'

const openWeatherMapApiKey = import.meta.env.VITE_WEATHER_MAP_API_KEY

const WORLDS_BOUNDS = latLngBounds([-70.0, -185], [90, 185])
interface IProps {
    category: ICategoryGroup
}
export const WeatherMap = ({ category }: IProps) => {
    const { city } = useSearchCityStore()

    const [cityCoordinates, setCoordinates] = useState<{
        lat: number
        lng: number
    }>()

    const getCapitalCoordinates = async () => {
        try {
            setCoordinates({ lat: +city?.lat, lng: +city?.lng })
        } catch (error) {
            Sentry.captureException(error)
            console.error('Error fetching capital coordinates:', error)
        }
    }
    useEffect(() => {
        if (!city) return
        getCapitalCoordinates()
    }, [city])

    const openWeatherMapLayer = 'temp_new'

    // const cloudlayer = 'clouds_new'

    return (
        <MapContainer
            inertia
            center={[50, 25]}
            zoom={3}
            maxZoom={9}
            minZoom={3}
            scrollWheelZoom={true}
            dragging
            maxBoundsViscosity={1.0}
            maxBounds={WORLDS_BOUNDS}
            style={{ height: '100dvh', width: '100dvw', zIndex: 9 }}
            zoomControl={false}>
            <TileLayer
                attribution='&copy; <a href="https://carto.com/">CartoDB</a>'
                url='https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png'
            />

            {/* layer */}
            <TileLayer
                attribution='&copy; <a href="https://openweathermap.org/">OpenWeatherMap</a>'
                url={`https://tile.openweathermap.org/map/${openWeatherMapLayer}/{z}/{x}/{y}.png?appid=${openWeatherMapApiKey}`}
            />

            <WeatherPupupWidget
                category={category}
                cityCoordinates={cityCoordinates}
            />
            <ZoomControlAtom />
        </MapContainer>
    )
}
